import { Collection } from "../lib/data/models";
import { Divider } from "./Divider";

export const ModalSessionSubheader = ({ session }: { session: Collection }) => {
  return (
    <div className="px-2.5 pt-3 sm:px-5">
      <div className="heading-3 leading-none">{new Date(session.startDate).toLocaleString()}</div>
      <div className="pb-2 text-sm text-grey-dark">{session.title}</div>
      <Divider />
    </div>
  );
};
