import { ReactNode } from "react";
import { IconButton } from "./Button";
import { VariantProps, cva } from "class-variance-authority";
import { classNames } from "../lib/utils/classNames";
import { IoMdClose } from "react-icons/io";

const bannerVariants = cva("border-t-4", {
  variants: {
    bgColor: {
      green: ["bg-green-lighter", "border-t-green"],
      red: ["bg-red-300", "border-t-red-500"],
      yellow: ["bg-yellow-200", "border-t-yellow-500"],
    },
  },
});
interface BannerProps extends VariantProps<typeof bannerVariants> {
  onDismiss?: () => void;
  children: ReactNode;
}

export const Banner = ({ onDismiss, children, bgColor = "green" }: BannerProps) => {
  return (
    <div
      className={classNames(
        "space-between z-50 flex w-full px-4 py-2 text-center",
        onDismiss ? "justify-between" : "justify-center",
        bannerVariants({
          bgColor: bgColor,
        }),
      )}
    >
      {children}
      {onDismiss && (
        <IconButton intent="iconOnly" label="dismiss" onClick={() => onDismiss()}>
          <IoMdClose />
        </IconButton>
      )}
    </div>
  );
};
