import { Link, useNavigate, useParams } from "react-router-dom";
import { PageContainer } from "../../components/PageContainer";
import { usePlayersByGame } from "../../lib/data/players";
import { GameEventType, PlayerWithDetails } from "../../lib/data/models";
import { useEffect, useMemo, useState } from "react";
import {
  GameStatsBySport,
  useDeleteStat,
  useGameEventsRefresher,
  useGameScore,
} from "../../lib/data/stats";
import { GameScore } from "../games/GameScore";
import { PlayerList } from "../stats/PlayerListV2";
import { StatButtonList } from "../stats/StatButtonList";
import { NotFoundPage } from "./NotFound";
import { useGame } from "../../lib/data/game";
import { GameEllipsis } from "./GamePage";
import { FaChevronLeft } from "react-icons/fa";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../components/ui/resizable";
import { StatCard } from "../stats/StatFeed";
import { LoadingSpinner } from "../../components/Spinner";
import { useMe } from "../../lib/data/user";
import { sortEventByCreatedAt } from "../../lib/utils/sort";
import { Button } from "../../components/Button";
import { useGamePermissions } from "../../lib/permissions";

export function GameStatKeeperPage() {
  const { id } = useParams();
  if (!id) return <NotFoundPage returnRoute="/dashboard" />;
  return (
    <PageContainer stickyHeader={false}>
      <PageContent gameId={id} />
    </PageContainer>
  );
}

function PageContent({ gameId }: { gameId: string }) {
  const [selectedPlayerId, setSelectedPlayer] = useState<string>("");
  const { data: game } = useGame(gameId);
  const { data: me } = useMe();
  const permissions = useGamePermissions(gameId);
  const { status, data, fetchNextPage, hasNextPage, refetch } = useGameEventsRefresher(gameId);
  const { data: teams = { homeTeam: [], awayTeam: [] } } = usePlayersByGame(gameId);
  const { data: score = { homeTeam: 0, awayTeam: 0 } } = useGameScore(gameId, true);
  const { mutateAsync: deleteStat } = useDeleteStat(gameId);
  const navigate = useNavigate();

  useEffect(() => {
    if (!permissions.isCurrentUserGameHost && permissions.isSuccess) {
      navigate(`/game/${gameId}`);
    }
  }, [gameId, navigate, permissions.isCurrentUserGameHost, permissions.isSuccess]);

  const onSelect = (playerId: string) => setSelectedPlayer(playerId);

  const makeTeamList = (team: PlayerWithDetails[], align: "left" | "right") => (
    <PlayerList
      players={team}
      selectedPlayerId={selectedPlayerId}
      onSelect={onSelect}
      align={align}
      clickableAvatar={false}
    />
  );
  const homeTeamPlayersList = makeTeamList(teams.homeTeam, "left");
  const awayTeamPlayersList = makeTeamList(teams.awayTeam, "right");
  const disableButtons = selectedPlayerId === "";

  const currentPlayer: PlayerWithDetails | undefined = teams.awayTeam
    .concat(teams.homeTeam)
    .find(p => p.id === selectedPlayerId);

  const stats: GameEventType[] = GameStatsBySport.basketball;
  const final = game?.gameState === "complete";

  const statSection = (
    <div className="mx-auto flex max-w-screen-lg flex-row items-stretch justify-between px-2 pb-2 pt-1">
      {homeTeamPlayersList}
      <StatButtonList
        selectedPlayerId={selectedPlayerId}
        gameId={gameId}
        stats={stats}
        disabled={disableButtons}
        player={currentPlayer}
        refreshFeed={refetch}
      />
      {awayTeamPlayersList}
    </div>
  );

  const memoGameScore = useMemo(() => {
    return <GameScore score={score} bg="green" size="md" final={final} />;
  }, [score, final]);

  const memoStatFeed = useMemo(() => {
    const events = data?.pages.flat() ?? [];
    const homeTeamPlayers = teams.homeTeam.map(p => p.id);
    const awayTeamPlayers = teams.awayTeam.map(p => p.id);
    const allPlayers = teams.homeTeam.concat(teams.awayTeam);
    const playerInTeam = (playerId: string) => allPlayers.find(p => p.id === playerId);
    const isInHomeTeam = (playerId: string) => homeTeamPlayers.includes(playerId);
    const isInAwayTeam = (playerId: string) => awayTeamPlayers.includes(playerId);
    return (
      <div className="flex h-full flex-col items-center gap-2 px-2 py-1 [&:has(div)]:h-auto">
        {events.length > 0 ? (
          events
            .sort(sortEventByCreatedAt("desc"))
            .map(event => (
              <StatCard
                statKeepingMode
                className="animate-borderEntryEmphasis"
                key={event.id}
                player={playerInTeam(event.playerId)}
                event={event}
                isCreator={me?.id === event.loggerUserId}
                teamText={
                  isInHomeTeam(event.playerId)
                    ? "Team 1"
                    : isInAwayTeam(event.playerId)
                      ? "Team 2"
                      : "N/A"
                }
                deleteStat={deleteStat}
              />
            ))
        ) : (
          <span className="flex h-full items-center justify-center p-6">
            <span className="font-semibold">No Feed Yet</span>
          </span>
        )}
        {status === "success" && hasNextPage && (
          <Button intent="light" label="see more" onClick={async () => await fetchNextPage()} />
        )}
      </div>
    );
  }, [
    data,
    status,
    deleteStat,
    fetchNextPage,
    hasNextPage,
    me?.id,
    teams.awayTeam,
    teams.homeTeam,
  ]);

  return (
    <div className="flex h-full flex-col">
      <div className="flex w-full flex-row justify-center bg-yellow">
        <div className="flex max-w-screen-lg flex-1 flex-row items-center justify-between px-2.5 py-1">
          <Link to={`/game/${gameId}`}>
            <FaChevronLeft aria-label="Back to game" size="24" className="fill-black" />
          </Link>
          <span className="header-banner font-black text-black">{game?.title}</span>
          <GameEllipsis gameId={gameId} hideOptions={["deleteGame"]} />
        </div>
      </div>
      <div className="flex-0 z-5 shadow-md">{memoGameScore}</div>
      <ResizablePanelGroup
        direction="vertical"
        className="flex min-h-screen flex-col bg-yellow-lighter"
      >
        <ResizablePanel
          className="!overflow-y-auto bg-iconPattern bg-auto bg-center bg-repeat backdrop-opacity-50"
          minSize={5}
          defaultSize={25}
        >
          {status === "pending" ? <LoadingSpinner /> : memoStatFeed}
        </ResizablePanel>
        <ResizableHandle className="rounded-t-xl bg-white py-3 shadow-above" withHandle />
        <ResizablePanel className="bg-white pb-12" minSize={15} defaultSize={75}>
          {statSection}
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
}
