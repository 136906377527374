import { cva } from "class-variance-authority";

// UUIDs are constructed in a sequence of digits equal to 128 bits.
// The ID is in hexadecimal digits, meaning it uses the
// numbers 0 through 9 and letters A through F.
export const colorMap = cva("", {
  variants: {
    key: {
      a: "bg-green",
      b: "bg-yellow",
      c: "bg-purple-600",
      d: "bg-indigo-600",
      e: "bg-fuchsia-600",
      f: "bg-rose-700",
      "0": "bg-orange-500",
      "1": "bg-red-500",
      "2": "bg-blue-700",
      "3": "bg-amber-700",
      "4": "bg-rose-700",
      "5": "bg-violet-600",
      "6": "bg-green",
      "7": "bg-yellow-600",
      "8": "bg-pink-600",
      "9": "bg-brown",
    },
  },
});

type UUIDCharacters =
  | "a"
  | "b"
  | "c"
  | "d"
  | "e"
  | "f"
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9";

export const getLetterFromId = (uuid: string): UUIDCharacters => {
  return uuid[0] as UUIDCharacters;
};
