import { ReactNode } from "react";

export function TitledSection({ title, children }: { title: string; children: ReactNode }) {
  return (
    <div>
      <h3 className="mb-2 font-Altivo text-3xl font-black uppercase">{title}</h3>
      {children}
    </div>
  );
}
