import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/Button";
import { classNames } from "../../lib/utils/classNames";
import { LoadingSpinner } from "../../components/Spinner";
import { useCreatePlayer } from "../../lib/data/players";

export function CreateSessionPlayerForm({
  sessionId: collectionId,
  onComplete,
  onCancel,
}: {
  sessionId: string;
  onComplete: () => void;
  onCancel: () => void;
}) {
  const { mutateAsync: createPlayer } = useCreatePlayer({
    // TODO: Send back the player id and highlight/autoscroll to the player in the list view.
    onSuccess: onComplete,
  });
  const [isLoading, setLoading] = useState<boolean>(false);

  const formSchema = z.object({
    firstName: z.string().min(2),
    lastName: z.string().min(2),
    nickname: z.string().optional(),
    email: z.string().email().optional(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
  });

  const onSubmit = async () => {
    setLoading(true);
    try {
      await createPlayer({ ...form.getValues(), collectionId });
    } catch (e) {
      console.error(`error occurred while creating player`, e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form {...form}>
      <form
        autoComplete="off"
        onSubmit={form.handleSubmit(onSubmit)}
        className={classNames(
          isLoading ? "opacity-50" : "",
          "flex flex-1 flex-col justify-between pt-2",
        )}
      >
        <div className="flex flex-col gap-3 px-2.5 pb-8 sm:px-5">
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="First Name" autoFocus {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="Last Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input type="email" placeholder="Email Address (optional)" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex w-full flex-row justify-between gap-4 border-t px-2.5 py-5 shadow-above sm:px-5">
          <Button
            className="w-full md:max-w-full"
            intent="outline"
            type="button"
            disabled={isLoading}
            onClick={onCancel}
            label="cancel"
          />
          {isLoading && <LoadingSpinner className="h-[50px] w-[50px]" />}
          <Button
            className="w-full md:max-w-full"
            intent="green"
            type="submit"
            disabled={isLoading}
            onClick={onSubmit}
            onSubmit={onSubmit}
            label="add new player"
          />
        </div>
      </form>
    </Form>
  );
}
