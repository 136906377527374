import { ReactNode } from "react";
import { classNames } from "../lib/utils/classNames";

export function ContentContainer({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <div className={classNames("mx-auto max-w-screen-lg px-2.5 py-8", className)}>{children}</div>
  );
}
