import { Environment, env } from "./env";
type Feature = "show_new_domain";
type EnvironmentMapping = Record<Environment, boolean>;

const mapping: Record<Feature, EnvironmentMapping> = {
  show_new_domain: {
    development: true,
    testing: false,
    production: false,
  },
};

export function isFeatureEnabled(feature: Feature) {
  return mapping[feature][env.REACT_APP_ENVIRONMENT];
}
