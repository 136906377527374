import { Game } from "../../lib/data/models";
import { classNames } from "../../lib/utils/classNames";
import { GameCard as GameCardV2 } from "./GameCardV2";

interface GameListProps {
  games: Game[];
  className?: string;
  expanded?: boolean;
}

export function GameList({ expanded = true, games, className }: GameListProps) {
  return (
    <div
      className={classNames(
        "flex w-full flex-col gap-4 md:grid md:grid-cols-2 [&:not(:last-child)]:mt-3",
        className,
      )}
    >
      {games
        .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
        .map(game => (
          <GameCardV2 expanded={expanded} key={game.id} gameId={game.id} />
        ))}
    </div>
  );
}
