import { useParams } from "react-router-dom";
import { NotFoundPage } from "./NotFound";
import { PageContainer } from "../../components/PageContainer";
import { ContentContainer } from "../../components/ContentContainer";
import { useSessionsForUser } from "../../lib/data/session";
import { SessionsList } from "../sessions/SessionsList";
import { FolderTabs, TabsConfig } from "../../components/ui/folder-tabs";

export function UserPage() {
  const { id } = useParams();
  if (!id) return <NotFoundPage />;
  return (
    <PageContainer>
      <ContentContainer>
        <UserPageContent userId={id} />
      </ContentContainer>
    </PageContainer>
  );
}

export function UserPageContent({ userId }: { userId: string }) {
  const { data: sessions = [] } = useSessionsForUser(userId);
  // const { data: games = [] } = useGamesForUser(userId);

  const tabsConfig: TabsConfig = {
    ariaLabel: "session tab list",
    defaultValue: "MY SESSIONS",
    tabs: [
      {
        value: "MY SESSIONS",
        content: <SessionsList sessions={sessions} />,
      },
      // {
      //   value: "MY GAMES",
      //   content: <GameList games={games} />,
      // },
    ],
    bgColor: "white",
  };
  return (
    <div>
      <FolderTabs className="bg-grey-light" tabsConfig={tabsConfig} />
    </div>
  );
}
