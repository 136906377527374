import { useState } from "react";
import { ContentContainer } from "../../components/ContentContainer";
import { LoadingSpinner } from "../../components/Spinner";
import { SDTable, SDTableData, SDTableHeaderData } from "../../components/table/SDTable";
import { SDTableContainer, SDTableContainerHeader } from "../../components/table/SDTableContainer";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
  DialogTitle,
} from "../../components/ui/dialog";
import { useGame } from "../../lib/data/game";
import { useAddTeamPlayer, usePlayersByGame, useRemoveTeamPlayer } from "../../lib/data/players";
import { useGameStats } from "../../lib/data/stats";
import { FaPencil } from "react-icons/fa6";
import { EditTeamPlayers } from "../sessions/EditTeamPlayers";
import { PlayerWithDetails } from "../../lib/data/models";
import { useGamePermissions } from "../../lib/permissions";
import { configureGameTableProps } from "../../components/table/SDTableHelpers";

interface GameStatsTableProps {
  className?: string;
  gameId: string;
}
export function GameStatsTable({ gameId, className }: GameStatsTableProps) {
  const { isCurrentUserGameHost } = useGamePermissions(gameId);
  const { data: game } = useGame(gameId);
  const { data: gameStats } = useGameStats(gameId, {
    enabled: !!game,
    refetchOnMount: true,
    refetchInterval: game?.gameState === "active" ? 5000 : false,
  });
  const { data: teams = { homeTeam: [], awayTeam: [] } } = usePlayersByGame(gameId);
  if (!gameStats || !game) return <LoadingSpinner />;
  const homeTeamTableData = configureGameTableProps(teams.homeTeam, gameStats);
  const awayTeamTableData = configureGameTableProps(teams.awayTeam, gameStats);

  const canEdit = ["pending", "active"].includes(game.gameState) && isCurrentUserGameHost;
  return (
    <ContentContainer className={className}>
      <TeamStatsTable
        canEdit={canEdit}
        teamTitle="Team 1"
        teamId={game.homeTeamId}
        gameId={gameId}
        tableData={homeTeamTableData}
        players={teams.homeTeam}
      />
      <TeamStatsTable
        canEdit={canEdit}
        teamTitle="Team 2"
        teamId={game.awayTeamId}
        gameId={gameId}
        tableData={awayTeamTableData}
        players={teams.awayTeam}
      />
    </ContentContainer>
  );
}

function TeamStatsTable({
  canEdit,
  teamId,
  gameId,
  players,
  teamTitle,
  tableData,
}: {
  canEdit: boolean;
  teamTitle: string;
  teamId: string;
  gameId: string;
  players: PlayerWithDetails[];
  tableData: [SDTableHeaderData, SDTableData];
}) {
  const [open, setOpen] = useState<boolean>(false);
  const { mutateAsync: addTeamPlayer } = useAddTeamPlayer(gameId);
  const { mutateAsync: removeTeamPlayer } = useRemoveTeamPlayer(gameId);
  const add = async (playerId: string) => {
    return await addTeamPlayer({ teamId, playerId });
  };
  const remove = async (playerId: string) => {
    return await removeTeamPlayer({ teamId, playerId });
  };
  const modal = (
    <DialogContent className="bg-white sm:max-w-xl">
      <DialogHeader>
        <DialogTitle>Team Players</DialogTitle>
      </DialogHeader>
      <EditTeamPlayers
        gameId={gameId}
        title={teamTitle}
        currentPlayers={players}
        addPlayer={add}
        removePlayer={remove}
      />
    </DialogContent>
  );

  const header = (
    <span className="flex flex-row gap-3">
      {teamTitle}{" "}
      {canEdit && (
        <DialogTrigger>
          <FaPencil size="17" />
        </DialogTrigger>
      )}
    </span>
  );
  return (
    <SDTableContainer>
      <Dialog open={open} onOpenChange={setOpen}>
        {modal}
        <SDTableContainerHeader title={header} detail={`${players.length} players`} />
        <SDTable headers={tableData[0]} data={tableData[1]}></SDTable>
      </Dialog>
    </SDTableContainer>
  );
}
