import { GameState } from "../../lib/data/models";
import { Link } from "react-router-dom";
import { GameContent } from "./GameContent";
import { classNames as cn } from "../../lib/utils/classNames";
import { useGame } from "../../lib/data/game";
import { LoadingSpinner } from "../../components/Spinner";
import { GameEllipsis } from "../pages/GamePage";
import { FaChevronRight } from "react-icons/fa";
import { GameBadges } from "../../components/Badges";

export function GameCard({ gameId, expanded = false }: { gameId: string; expanded?: boolean }) {
  const bottomBannerColorMap: { [key in GameState]: string } = {
    pending: "bg-yellow-700",
    active: "bg-green-emerald",
    complete: "bg-grey-dark",
    cancelled: "bg-red-600",
  };
  const { data: game } = useGame(gameId);
  if (!game) return <LoadingSpinner />;

  return (
    <div className="mb-auto rounded-md border shadow-md">
      <div className="flex flex-col items-center px-2.5 py-4 pb-0">
        <div className="mb-0.5 flex w-full items-center justify-between">
          <span className="flex items-center gap-2">
            <span className="heading-3">{game.title}</span>
            {game.gameState === "active" && (
              <div className="h-2 w-2 animate-pulse rounded-full bg-emerald-500"></div>
            )}
          </span>
          <GameEllipsis gameId={gameId} hideOptions={["showStats"]} className="fill-black" />
        </div>
        <GameContent expanded={expanded} game={game} />
      </div>
      <GameBadges gameId={gameId} />
      <Link to={`/game/${game.id}`}>
        <div
          className={cn(
            "flex w-full cursor-pointer justify-between rounded-b-md px-2 py-1 text-sm text-white",
            bottomBannerColorMap[game.gameState],
          )}
        >
          <span>{game.gameState}</span>
          <span className="flex flex-row items-center justify-end gap-2 text-sm">
            View / Edit
            <FaChevronRight className="float-right" size="15" />
          </span>
        </div>
      </Link>
    </div>
  );
}
