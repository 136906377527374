import { useUser } from "@clerk/clerk-react";
import { ContentContainer } from "./ContentContainer";
import { TriangleTransition } from "./svgr";

export function WelcomeHero() {
  const { user } = useUser();
  const name = user?.firstName;
  return (
    <>
      <div className="border-b-1 border-green bg-green">
        <ContentContainer className="pb-3 text-white-custom">
          <div className="flex flex-col gap-1">
            <div className="font-Altivo text-4xl font-black">
              <span>Welcome back,</span>
              <br />
              {name && <span className="capitalize">{name}</span>}
            </div>
            <div className="flex flex-col">
              <div>Game. Stats. Victory.</div>
              <div className="text-yellow">Start a game up!</div>
            </div>
          </div>
        </ContentContainer>
      </div>
      <TriangleTransition width={"100%"} className="fill-green" />
    </>
  );
}
