import { ReactNode } from "react";

export const SDTableContainer = ({ children }: { children: ReactNode }) => {
  return <div className="pb-9">{children}</div>;
};

export const SDTableContainerHeader = ({
  title,
  detail,
}: {
  title: ReactNode;
  detail: ReactNode;
}) => {
  return (
    <div className="flex items-center justify-between pb-2 text-xl">
      <span className="font-Altivo font-black uppercase">{title}</span>
      <span className="sans text-base">{detail}</span>
    </div>
  );
};
