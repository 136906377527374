import type { SVGProps } from "react";
const SvgThree = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 50 50" {...props}>
    <path
      d="M22.1 34.76h1.93v6.74h-4.86l-5.33-5.33v-5.75h6.74v2.83l1.51 1.51ZM19.13 8.5h4.9v6.79H22.1l-1.41 1.41v2.12h-6.7v-5.19l5.14-5.14Zm.42 13.58h4.48v5.05h-4.48v-5.05Zm16.41-8.44v7.83l-2.73 2.69 2.92 2.92v9.1l-5.38 5.33h-4.86v-6.74h1.93l1.51-1.51v-4.72l-1.42-1.41H25.9v-5.05h1.93l1.41-1.41v-3.96l-1.41-1.41H25.9V8.51h4.9l5.14 5.14Z"
      style={{
        stroke: "#000",
        strokeWidth: ".94px",
      }}
    />
  </svg>
);
export default SvgThree;
