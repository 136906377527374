/* eslint-disable no-restricted-globals */

import { Fragment, useEffect, useRef, useState } from "react";
import { BaseUser } from "../../lib/data/models";
import { classNames as cn } from "../../lib/utils/classNames";
import { BasketballStats, StatColors, heatMapColor } from "./sport-configs/basketball";
import { zeroGuardDivision } from "../../lib/utils/format";
import { PlayerAvatar } from "../avatar/PlayerAvatar";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { usePrevious } from "../../lib/utils/usePrevious";
import _ from "lodash";

export interface SDTableColumn {
  id: string;
  name: string;
  classNames?: string;
}

export interface SDTableHeaderData {
  groupId: string;
  columns: SDTableColumn[];
}

export interface SDTableData {
  rows: SDPerformer[];
}

export interface SDData {
  name: BasketballStats;
  label: string;
  value: string | number;
}

export interface SDPerformer {
  id: string;
  user: BaseUser;
  stats: SDData[];
}

export type SDTableTopStats = Partial<Record<BasketballStats, number>>;

export const establishTopStats = (data: SDTableData): SDTableTopStats => {
  const topStats: SDTableTopStats = {};

  data.rows.forEach(performer => {
    performer.stats.forEach(stat => {
      if (typeof stat.value === "number") {
        topStats[stat.name] = Math.max(topStats[stat.name] ?? 0, stat.value);
      }
    });
  });
  return topStats;
};

export const heatColor = (topStats: SDTableTopStats, stat: SDData, colorMap = heatMapColor) => {
  if (typeof stat.value !== "number") return "bg-white";
  const opacity = zeroGuardDivision(Math.abs(stat.value), topStats[stat.name] ?? 0).toFixed(2);
  return `rgba(${stat.value < 0 ? colorMap[stat.name].reverseValue : colorMap[stat.name].value},${opacity})`;
};

export const heatColorName = (stat: SDData, colorMap = heatMapColor) => {
  return colorMap[stat.name].color;
};

export const changeColorHighlight = (
  prev: string | number | undefined,
  curr: string | number,
  stat: SDData,
  heatMap = heatMapColor,
): StatColors => {
  if (prev === undefined) {
    return "transparent";
  } else if (prev < curr) {
    return heatMap[stat.name].color;
  } else if (prev > curr) {
    return heatMap[stat.name].reverseColor;
  } else {
    return "transparent";
  }
};

export function SDTable({
  stickyHeader = true,
  headers,
  data,
}: {
  stickyHeader?: boolean;
  headers: SDTableHeaderData;
  data: SDTableData;
}) {
  const [currScrollY, setYScroll] = useState(0);
  const [yHeaderScrollPos, setYHeaderScrollPos] = useState(0);
  const floatingHeaderRef = useRef<HTMLTableSectionElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const [sortConfig, setSortConfig] = useState<{ asc: boolean; columnId: string | undefined }>({
    asc: false,
    columnId: headers.columns[0]?.id,
  });
  const topStats = establishTopStats(data);
  let rows = _.sortBy(data.rows, r => r.stats.find(s => s.name === sortConfig.columnId)?.value);
  if (!sortConfig.asc) {
    rows = rows.reverse();
  }

  const adjustHeaderOnYScroll = () => {
    setYScroll(scrollY);
    const tableHeader = tableRef.current;
    if (tableHeader?.parentElement && floatingHeaderRef.current) {
      setYHeaderScrollPos(
        floatingHeaderRef.current.offsetTop - tableHeader.parentElement.offsetTop,
      );
    }
  };

  const adjustHeaderOnXScroll = () => {
    if (floatingHeaderRef.current && tableRef.current?.parentElement) {
      floatingHeaderRef.current.scrollLeft = tableRef.current.parentElement.scrollLeft;
    }
  };

  useEffect(() => {
    const currTable = tableRef.current;
    window.addEventListener("scroll", adjustHeaderOnYScroll);
    const observer = new ResizeObserver(() => {
      if (floatingHeaderRef.current && tableRef.current) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        floatingHeaderRef.current.querySelector("tr")?.childNodes.forEach((col: any, i: number) => {
          col.style.width = getComputedStyle(
            tableRef.current?.firstElementChild?.firstElementChild?.children[
              i
            ] as HTMLTableCellElement,
          ).width;
        });
      }
    });
    if (currTable) {
      observer.observe(currTable);
    }
    if (currTable?.parentElement) {
      currTable.parentElement.addEventListener("scroll", adjustHeaderOnXScroll);
    }

    return () => {
      window.removeEventListener("scroll", adjustHeaderOnYScroll);
      if (currTable) {
        observer.unobserve(currTable);
      }
      if (currTable?.parentElement) {
        currTable.parentElement.removeEventListener("scroll", adjustHeaderOnXScroll);
      }
    };
  }, []);

  return (
    <div className="isolate grid grid-cols-[auto] grid-rows-[auto_1fr]">
      <div
        ref={floatingHeaderRef}
        className="pointer-events-none sticky top-[var(--header-height-default)] isolate z-20 col-start-1 row-start-2 max-h-[30px] overflow-hidden md:top-[var(--header-height-md)]"
      >
        <table className="table-fixed overflow-x-hidden bg-white">
          <thead
            aria-hidden
            className={cn(
              stickyHeader ? "sticky top-0" : "",
              "left-0 z-20 w-full text-sm shadow-sm",
              "top-[var(--header-height-default)] md:top-[var(--header-height-md)]",
            )}
          >
            <tr key={headers.groupId}>
              {headers.columns.map(column => {
                return (
                  <th
                    className={cn(
                      "table-cell cursor-pointer whitespace-pre border-b border-solid border-b-gray-300 bg-white pt-2 font-sans text-sm font-medium",
                      column.classNames,
                    )}
                    key={column.id}
                  >
                    <button
                      className="w-full"
                      onClick={() => {
                        // setTimeout to jump to the right scrollY after rows have reshifted on sort
                        setTimeout(() => scrollTo(0, currScrollY), 0);
                        setSortConfig({
                          // clicking the sort buttom again should toggle
                          asc: sortConfig.columnId === column.id ? !sortConfig.asc : false,
                          columnId: column.id,
                        });
                      }}
                    >
                      {column.name}
                      {sortConfig.columnId === column.id &&
                        (sortConfig.asc ? (
                          <TiArrowSortedUp className="inline-flex h-2.5 w-2.5" />
                        ) : (
                          <TiArrowSortedDown className="inline-flex h-2.5 w-2.5" />
                        ))}
                    </button>
                  </th>
                );
              })}
            </tr>
          </thead>
        </table>
      </div>
      <div className={cn("isolate col-start-1 row-start-2 w-full overflow-x-auto")}>
        <table
          ref={tableRef}
          className={cn("relative w-full table-fixed border-separate border-spacing-0 text-sm")}
        >
          <thead
            className={cn("z-10 w-full shadow-sm", stickyHeader ? "sticky top-0 opacity-0" : "")}
            style={{ top: yHeaderScrollPos + "px" }}
          >
            <tr key={headers.groupId}>
              {headers.columns.map(column => {
                return (
                  <th
                    className={cn(
                      "cursor-pointer whitespace-pre border-b border-solid border-b-gray-300 bg-white pt-2 font-sans font-medium",
                      column.classNames,
                      "bg-blue-50",
                    )}
                    key={column.id}
                  >
                    <button
                      className="w-full"
                      onClick={() => {
                        // setTimeout to jump to the right scrollY after rows have reshifted on sort
                        setTimeout(() => scrollTo(0, currScrollY), 0);
                        setSortConfig({
                          // clicking the sort buttom again should toggle
                          asc: sortConfig.columnId === column.id ? !sortConfig.asc : false,
                          columnId: column.id,
                        });
                      }}
                    >
                      {column.name}
                      {sortConfig.columnId === column.id &&
                        (sortConfig.asc ? (
                          <TiArrowSortedUp className="inline-flex h-2.5 w-2.5" />
                        ) : (
                          <TiArrowSortedDown className="inline-flex h-2.5 w-2.5" />
                        ))}
                    </button>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className={cn("w-full pt-1")}>
            {rows.map(row => (
              <Fragment key={row.id}>
                <tr className="min-w-full pt-1">
                  <th colSpan={headers.columns.length} className="w-max">
                    <div className={cn("sticky left-0 flex w-max items-center gap-1.5 pt-1")}>
                      <PlayerAvatar
                        playerId={row.id}
                        user={row.user}
                        className="text-sm font-normal"
                      />
                      <span className="font-sans font-medium">
                        {`${row.user.firstName} ${row.user.lastName}`}
                      </span>
                    </div>
                  </th>
                </tr>
                <tr className="border-b border-solid border-b-gray-300" key={row.id}>
                  {row.stats.map(stat => {
                    return (
                      <SDTd
                        key={`${stat.name}${row.id}`}
                        stat={stat}
                        bgColor={heatColor(topStats, stat)}
                      />
                    );
                  })}
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export const SDTd = ({
  stat,
  bgColor,
  rowId,
}: {
  stat: SDData;
  bgColor: string;
  rowId?: string;
}) => {
  const prevValue = usePrevious(stat.value);

  const animationMap = (color: StatColors) => {
    switch (color) {
      case "red":
        return "animate-underlineRedEmphasis";
      case "green":
        return "animate-underlineGreenEmphasis";
      default:
        return "";
    }
  };

  return (
    <td
      key={`${rowId}${stat.value}`}
      style={{ backgroundColor: `${bgColor}` }}
      className={cn(
        "border border-b border-t-0 border-solid border-x-white border-b-gray-300 text-center",
        animationMap(changeColorHighlight(prevValue, stat.value, stat)),
      )}
    >
      {stat.label}
    </td>
  );
};
