import type { SVGProps } from "react";
const SvgSt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="st_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 50 50"
    {...props}
  >
    <defs>
      <style>{".st_svg__cls-1{fill:#212121;stroke-width:0}"}</style>
    </defs>
    <path
      d="M45.93 16.34a2.01 2.01 0 0 0-1.43-1.21 2.01 2.01 0 0 0-1.8.5 7.52 7.52 0 0 1-5.2 2.06c-3.23 0-6.11-2.05-7.18-5.09-.31-.9-1.22-1.45-2.16-1.32-.09.01-.17.01-.27.01-1.54 0-2.8-1.26-2.8-2.8 0-1.67.18-2.11.51-2.33.74-.49 1.07-1.41.8-2.26a1.986 1.986 0 0 0-1.93-1.41C12.71 2.64 3.5 12.3 3.5 24.5c0 4.19.21 8.59 2.75 12.32 1.29 1.91 3.56 3.51 5.38 4.8.33.23.64.46.94.67 3.78 2.75 8.25 4.21 12.94 4.21 3.94 0 7.8-1.05 11.17-3.04.8-.47 1.63-1.16 2.37-1.77l.52-.43c.18-.15.34-.27.5-.39.52-.4 1-.78 1.61-1.44 3.77-4.07 5.84-9.38 5.84-14.93 0-2.82-.53-5.57-1.57-8.16Zm-19.66-.68c.08.77-.31 1.43-.72 1.59l-.75.3c-1.2.48-2.45.02-3.25-1.2l-1.34-2.03a.9.9 0 0 1-.11-.5c.11-1.2.77-2.21 1.62-2.53a6.83 6.83 0 0 0 4.5 3.78l.06.59Zm-2.95 26.45c0-.23.36-.96 2.06-1.63.55-.22 1-.41 1.38-.58.59-.26.92-.4 1.21-.4.37 0 .66.24 1.32.79.32.27.37.51.38.69.02.35-.13.74-.33 1.11-1.25.27-2.53.42-3.83.42-.73 0-1.46-.06-2.17-.14.03-.12.05-.1 0-.25Zm15.4-5.4c-.38.42-.68.65-1.13 1-.17.13-.35.27-.55.44l-.56.46c-.59.49-1.33 1.1-1.85 1.41-.96.57-1.97 1.03-3.01 1.41.02-.18.04-.36.03-.54-.04-.83-.42-1.57-1.1-2.13-1.94-1.61-2.56-1.57-4.6-.68-.36.16-.79.34-1.31.55-2.83 1.12-3.29 2.64-3.32 3.39-2.3-.54-4.47-1.54-6.41-2.95-.31-.22-.64-.46-.98-.7-1.56-1.1-3.49-2.48-4.38-3.79-1.9-2.8-2.06-6.37-2.06-10.08 0-8.94 5.68-16 13.66-17.64-.06.6-.06 1.17-.06 1.64 0 .3.03.59.06.88-1.64.54-2.88 2.23-3.06 4.27-.06.64.1 1.27.43 1.78l1.34 2.03c.97 1.46 2.46 2.27 4 2.27.55 0 1.12-.1 1.66-.32l.75-.3c.9-.36 1.56-1.19 1.84-2.19 2.14 2.94 5.6 4.77 9.37 4.77 1.95 0 3.84-.48 5.51-1.38.33 1.36.49 2.76.49 4.19 0 4.54-1.7 8.88-4.78 12.22Z"
      className="st_svg__cls-1"
    />
    <path
      d="m39.54 23.8-1.76-.42c-1.73-.41-3.68.19-4.95 1.54l-.47.5c-.93.99-.97 2.43-.1 3.35l.69.72c.54.56 1.3.87 2.12.87h.24l1.42-.1c1.11-.08 2.21-.52 3.08-1.25 1.33-1.11 1.74-2.85 1.02-4.24-.25-.49-.71-.84-1.27-.97Zm-2.96 4.47-1.42.1c-.3.02-.59-.07-.76-.25l-.69-.72c-.13-.14-.09-.4.1-.6l.47-.5c.62-.66 1.53-1.04 2.39-1.04.22 0 .43.02.64.07l1.72.37c.29.55.07 1.28-.53 1.78-.55.46-1.24.74-1.94.79ZM32.3 32.9c-.44 0-.8.36-.8.8s.36.8.8.8.8-.36.8-.8-.36-.8-.8-.8M24.3 23.3c-.44 0-.8.36-.8.8s.36.8.8.8.8-.36.8-.8-.36-.8-.8-.8m0 .8h.4zM12.7 18.9c.44 0 .8-.36.8-.8s-.36-.8-.8-.8-.8.36-.8.8.36.8.8.8m.4-.8h-.4zM18.91 25.35l-1.83-.93a.5.5 0 0 0-.17-.06c-1.4-.38-2.89-.25-4.2.35-1.89.87-2.93 2.88-2.54 4.88.12.62.48 1.15 1.01 1.5.15.1.33.14.51.14l2.4-.09c1.11-.06 2.11.49 2.76 1.45.61.9 1.47 2.16 2.65 2.16.34 0 .71-.11 1.11-.36.06-.04.11-.08.16-.13.97-1 1.28-2.7.85-4.68-.38-1.74-1.39-3.74-2.71-4.24Zm.69 7.62c-.21.08-.47-.12-1.33-1.38-.93-1.37-2.44-2.18-4.06-2.18h-.19l-2.06.08a.5.5 0 0 1-.09-.22c-.24-1.22.41-2.44 1.56-2.97.92-.42 1.96-.52 2.94-.27l1.82.92c.56.21 1.37 1.43 1.72 3.02.28 1.29.16 2.42-.32 3.01Z"
      className="st_svg__cls-1"
    />
  </svg>
);
export default SvgSt;
