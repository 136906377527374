import { ComponentProps } from "react";
import { classNames } from "../../lib/utils/classNames";
import { fullName, initials } from "../../lib/utils/format";
import { Nameable } from "../../lib/utils/types";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { colorMap, getLetterFromId } from "../../lib/utils/colors";

export type UserAvatarAlignment = "left" | "right";

export function UserAvatar({
  user,
  showFullName = false,
  truncate = true,
  stackName = false,
  align = "left",
  highlight,
  ...props
}: ComponentProps<typeof Avatar> & {
  user: Nameable & { id: string; imageUrl: string };
  showFullName?: boolean;
  stackName?: boolean;
  truncate?: boolean;
  align?: UserAvatarAlignment;
  highlight?: boolean;
}) {
  return (
    <span
      className={classNames(
        showFullName ? "inline-flex min-w-0 items-center gap-2 px-0.5" : "",
        highlight ? "rounded-lg bg-yellow-light" : "",
        align === "left" ? "rounded-l-2xl" : "rounded-r-2xl",
      )}
    >
      <Avatar
        title={fullName(user)}
        className={classNames(
          highlight ? "shadow-whiteCircle outline outline-[3px] outline-green" : "",
          "rounded-full outline-offset-2",
        )}
        {...props}
      >
        <AvatarImage src={user.imageUrl} />
        <AvatarFallback
          className={classNames(
            "border border-black text-white",
            colorMap({ key: getLetterFromId(user.id) }),
          )}
        >
          {initials(fullName(user))}
        </AvatarFallback>
      </Avatar>
      {showFullName &&
        (stackName ? (
          <div
            className={classNames(
              "w-full overflow-hidden",
              align === "left" ? "text-left" : "text-right",
            )}
          >
            <span className="text-md block truncate leading-tight dark:text-white">
              {user.firstName}
            </span>
            <span className="text-md block truncate leading-tight dark:text-white">
              {user.lastName}
            </span>
          </div>
        ) : (
          <span className={classNames(truncate && "truncate", "w-full")}>{fullName(user)}</span>
        ))}
    </span>
  );
}
