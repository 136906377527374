import { UseQueryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../api";
import { Collection, CollectionPlayer, Link, PlayerWithDetails } from "./models";
import { queryKeys } from "./queryKeys";
import { MutationConfig, MutationResult, QueryConfig } from "../utils/types";

export function useSession(sessionId: string) {
  const { run } = useApi();
  async function getSession() {
    return await run<Collection>({
      method: "GET",
      path: `/collections/${sessionId}`,
    });
  }
  const querySession: UseQueryOptions<Collection> = {
    queryKey: queryKeys.session.detail(sessionId),
    queryFn: getSession,
    staleTime: 20000,
  };
  return useQuery(querySession);
}

export function useSessions(
  params: {
    startDate?: string;
    endDate?: string;
  } = {},
) {
  const { run } = useApi();
  async function getSessions() {
    return await run<Collection[]>({
      method: "GET",
      path: "/collections",
      params,
    });
  }
  const querySessions: UseQueryOptions<Collection[]> = {
    queryKey: queryKeys.session.all,
    queryFn: getSessions,
  };
  return useQuery(querySessions);
}

export function useSessionsForUser(userId: string) {
  const { run } = useApi();
  async function getSessionsForUser() {
    return await run<Collection[]>({
      method: "GET",
      path: `/users/${userId}/collections`,
    });
  }
  const querySessions: UseQueryOptions<Collection[]> = {
    queryKey: queryKeys.session.forUser(userId),
    queryFn: getSessionsForUser,
    staleTime: 60000,
  };
  return useQuery(querySessions);
}

export function useSessionsForPlayer(playerId: string, config?: QueryConfig<Collection[]>) {
  const { run } = useApi();
  async function getSessionsForPlayer() {
    return await run<Collection[]>({
      method: "GET",
      path: `/players/${playerId}/collections`,
    });
  }
  const querySessions: UseQueryOptions<Collection[]> = {
    queryKey: queryKeys.session.forPlayer(playerId),
    queryFn: getSessionsForPlayer,
    staleTime: 60000,
    ...config,
  };
  return useQuery(querySessions);
}

export function useCreateSession(
  config?: MutationConfig<Collection, typeof createSession>,
): MutationResult<Collection, typeof createSession> {
  const { run } = useApi();
  async function createSession(payload: { title?: string; startDate: string }) {
    return await run<Collection>({
      method: "POST",
      path: "/collections",
      body: payload,
    });
  }
  return useMutation({
    ...config,
    mutationFn: createSession,
  });
}

export function useEditSession(
  sessionId: string,
  config?: MutationConfig<Collection, typeof editSession>,
): MutationResult<Collection, typeof editSession> {
  const { run } = useApi();
  const queryClient = useQueryClient();
  async function editSession(payload: {
    title?: string;
    description?: string;
    startDate?: string;
  }) {
    return await run<Collection>({
      method: "PATCH",
      path: `/collections/${sessionId}`,
      body: payload,
    });
  }
  return useMutation({
    ...config,
    mutationFn: editSession,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.session.detail(sessionId),
      });
    },
  });
}

export function useHostsBySession(sessionId: string) {
  const { run } = useApi();
  async function getHostsForSession() {
    return await run<PlayerWithDetails[]>({
      method: "GET",
      path: `/collections/${sessionId}/hosts`,
    });
  }
  const querySessions: UseQueryOptions<PlayerWithDetails[]> = {
    queryKey: queryKeys.session.hosts(sessionId),
    queryFn: getHostsForSession,
  };
  return useQuery(querySessions);
}

export function useEditSessionHosts(
  sessionId: string,
  config?: MutationConfig<Collection, typeof editSessionHosts>,
): MutationResult<Collection, typeof editSessionHosts> {
  const { run } = useApi();
  const queryClient = useQueryClient();
  async function editSessionHosts(hosts: string[]) {
    return await run<Collection>({
      method: "PATCH",
      path: `/collections/${sessionId}`,
      body: {
        hosts,
      },
    });
  }
  return useMutation({
    ...config,
    mutationFn: editSessionHosts,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.session.detail(sessionId),
      });
    },
  });
}

export function useLinksBySession(sessionId: string) {
  const { run } = useApi();
  async function getLinksForSession() {
    return await run<Link[]>({
      method: "GET",
      path: `/collections/${sessionId}/links`,
    });
  }
  const querySessions: UseQueryOptions<Link[]> = {
    queryKey: queryKeys.session.links(sessionId),
    queryFn: getLinksForSession,
  };
  return useQuery(querySessions);
}

export function useCreateSessionLink(
  sessionId: string,
  config?: MutationConfig<Collection, typeof createSessionLink>,
): MutationResult<Collection, typeof createSessionLink> {
  const { run } = useApi();
  const queryClient = useQueryClient();
  async function createSessionLink(link: { url: string; title: string }) {
    return await run<Collection>({
      method: "POST",
      path: `/collections/${sessionId}/links`,
      body: link,
    });
  }
  return useMutation({
    ...config,
    mutationFn: createSessionLink,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.session.links(sessionId),
      });
    },
  });
}

export function useEditSessionLink(
  sessionId: string,
  config?: MutationConfig<Link, typeof editSessionLink>,
): MutationResult<Link, typeof editSessionLink> {
  const { run } = useApi();
  const queryClient = useQueryClient();
  async function editSessionLink({
    linkId,
    link,
  }: {
    linkId: string;
    link: { url: string; title: string };
  }) {
    return await run<Link>({
      method: "PATCH",
      path: `/collections/${sessionId}/links/${linkId}`,
      body: {
        ...link,
      },
    });
  }
  return useMutation({
    ...config,
    mutationFn: editSessionLink,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.session.links(sessionId),
      });
    },
  });
}

export function useDeleteSessionLink(
  sessionId: string,
  config?: MutationConfig<Link, typeof deleteSessionLink>,
): MutationResult<Link, typeof deleteSessionLink> {
  const queryClient = useQueryClient();
  const { run } = useApi();
  async function deleteSessionLink(linkId: string) {
    return await run<Link>({
      method: "DELETE",
      path: `/collections/${sessionId}/links/${linkId}`,
    });
  }
  return useMutation({
    ...config,
    mutationFn: deleteSessionLink,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.session.links(sessionId) });
    },
  });
}

export function useAddPlayerToSession(
  sessionId: string,
  config?: MutationConfig<CollectionPlayer, typeof addPlayerToSession>,
): MutationResult<CollectionPlayer, typeof addPlayerToSession> {
  const queryClient = useQueryClient();
  const { run } = useApi();
  async function addPlayerToSession(playerId: string): Promise<CollectionPlayer> {
    return await run<CollectionPlayer>({
      path: `/collections/${sessionId}/players`,
      method: "POST",
      body: { playerId },
    });
  }
  return useMutation({
    ...config,
    mutationFn: addPlayerToSession,
    onSettled: async () =>
      await queryClient.invalidateQueries({
        queryKey: queryKeys.session.players(sessionId),
      }),
  });
}

export function useRemovePlayerFromSession(
  sessionId: string,
  config?: MutationConfig<CollectionPlayer, typeof removePlayerFromSession>,
): MutationResult<CollectionPlayer, typeof removePlayerFromSession> {
  const queryClient = useQueryClient();
  const { run } = useApi();
  async function removePlayerFromSession(playerId: string): Promise<CollectionPlayer> {
    return await run<CollectionPlayer>({
      path: `/collections/${sessionId}/players/${playerId}`,
      method: "DELETE",
    });
  }
  return useMutation({
    ...config,
    mutationFn: removePlayerFromSession,
    onSettled: async () =>
      await queryClient.invalidateQueries({
        queryKey: queryKeys.session.players(sessionId),
      }),
  });
}

export function useReorderGameNames(config?: MutationConfig<string[], typeof reorderGameNames>) {
  const queryClient = useQueryClient();
  const { run } = useApi();
  async function reorderGameNames(sessionId: string) {
    return await run<string[]>({
      path: `/collections/${sessionId}/reorder`,
      method: "POST",
    });
  }
  return useMutation({
    ...config,
    mutationFn: reorderGameNames,
    onSettled: (data, __, variables) => {
      if (data) {
        for (const gameId of data) {
          queryClient.invalidateQueries({ queryKey: queryKeys.games.detail(gameId) });
        }
      }
      queryClient.invalidateQueries({ queryKey: queryKeys.session.games(variables) });
    },
  });
}

export function useDeleteSession(
  config?: MutationConfig<Collection, typeof deleteSession>,
): MutationResult<Collection, typeof deleteSession> {
  const queryClient = useQueryClient();
  const { run } = useApi();
  async function deleteSession(sessionId: string) {
    return await run<Collection>({
      method: "DELETE",
      path: `/collections/${sessionId}`,
    });
  }
  return useMutation({
    ...config,
    mutationFn: deleteSession,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.session.all });
    },
  });
}

export function useSessionScoringData() {
  const contentType = "text/csv";
  const { run } = useApi(contentType);
  async function fn(sessionId: string) {
    return await run<string>({
      method: "GET",
      path: `/collections/${sessionId}/scoring-data`,
    });
  }
  return useMutation({
    mutationFn: fn,
  });
}
