import * as dt from "date-fns";
import { GameEventType } from "../data/models";
import { Nameable } from "./types";

export const initials = (fullName: string) => {
  const initials = fullName.split(" ").map(s => s[0]?.toUpperCase());
  return initials.length <= 2
    ? initials.join("")
    : [initials[0], initials[initials.length - 1]].join("");
};

export const fullName = ({ firstName, lastName }: Nameable): string => {
  return `${firstName} ${lastName}`;
};

export const statLabels: Record<GameEventType, string> = {
  basketball_score_three_pointer: "3PT",
  basketball_assist: "AST",
  basketball_miss: "MISS",
  basketball_rebound: "RB",
  basketball_score_field_goal: "FG",
  basketball_steal: "ST",
  basketball_turnover: "TO",
  basketball_block: "BLK",
  basketball_foul: "FOUL",
  basketball_free_throw_score: "FTM",
  basketball_free_throw_miss: "FTA",
};

export function shortDateTime(dateString: string | Date) {
  return dt.format(new Date(dateString), "M/dd/yy • hh:mm a");
}

export function percentageString(num: number, decimals = 1) {
  const numeric = (num * 100).toFixed(decimals);
  return `${numeric}`;
}

export function percentageNumber(num: number, decimals = 1) {
  if (num === 1) return 100;
  const numeric = (num * 100).toFixed(decimals);
  return Number(numeric);
}

export function zeroGuardDivision(numerator: number, denominator: number) {
  if (denominator === 0) return 0;
  return numerator / denominator;
}
