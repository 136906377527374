import { ReactNode, useEffect } from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Toaster } from "./ui/toaster";
import { useLocation } from "react-router-dom";
import { useAnalytics } from "../lib/analytics";
import { Banner } from "./Banner";
import { isFeatureEnabled } from "../lib/feature-flag";

export function PageContainer({
  children,
  stickyHeader = true,
}: {
  children: ReactNode;
  stickyHeader?: boolean;
}) {
  const location = useLocation();
  const { logPageView } = useAnalytics();
  useEffect(() => {
    logPageView();
    // eslint-disable-next-line
  }, [location]);

  const showMigrationBanner = isFeatureEnabled("show_new_domain");
  return (
    <div className="w-full">
      {showMigrationBanner && (
        <Banner>
          <span className="cursor-default">
            Heads up! We've moved to&nbsp;
            <a href="https://somedubs.com" className="font-bold transition-all hover:text-green">
              somedubs.com
            </a>
            !
          </span>
        </Banner>
      )}
      <Header stickyHeader={stickyHeader} />
      <div className="min-h-screen">{children}</div>
      <Toaster />
      <Footer />
    </div>
  );
}
