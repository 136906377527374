import { AvatarListToStack } from "../../components/avatar/AvatarListToStack";
import { Game } from "../../lib/data/models";
import { useGameScore } from "../../lib/data/stats";
import { GameScore, gameScoreVariants } from "./GameScore";
import { usePlayersByGame } from "../../lib/data/players";
import { LoadingSpinner } from "../../components/Spinner";
import { VariantProps } from "class-variance-authority";
import { classNames } from "../../lib/utils/classNames";

interface GameContentProps extends VariantProps<typeof gameScoreVariants> {
  game: Game;
  headerMode?: boolean;
  expanded?: boolean;
}

export function GameContent({
  game,
  headerMode = false,
  expanded = false,
  size = "sm",
}: GameContentProps) {
  const { data: playersData } = usePlayersByGame(game.id);
  const { data: score = { homeTeam: 0, awayTeam: 0 } } = useGameScore(game.id, true);
  const scoreView = <GameScore score={score} size={size} final={game.gameState === "complete"} />;

  if (!playersData) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {expanded && scoreView}
      <div className={classNames("w-full max-w-sm py-3", headerMode ? "max-w-lg px-2.5 py-4" : "")}>
        <div className="flex flex-row justify-between gap-3">
          <div
            className={classNames(
              "flex min-w-0 flex-col items-start",
              !headerMode && expanded && "flex-1",
            )}
          >
            <div className="text-center font-sans font-medium">Team 1</div>
            <AvatarListToStack
              open={expanded}
              showNumberOfUsers={2}
              users={playersData.homeTeam.map(player => player.user)}
            />
          </div>
          {!expanded && scoreView}
          <div
            className={classNames(
              "flex min-w-0 flex-col items-end",
              !headerMode && expanded && "flex-1",
            )}
          >
            <div className="text-center font-sans font-medium">Team 2</div>
            <AvatarListToStack
              open={expanded}
              showNumberOfUsers={2}
              users={playersData.awayTeam.map(player => player.user)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
