import { ReactNode } from "react";
import { classNames } from "../lib/utils/classNames";

export function SportCard({
  className = "",
  children,
  label,
}: {
  className?: string;
  children: ReactNode;
  label: string;
}) {
  return (
    <div className={classNames("min-w-24justify-center flex flex-col items-center", className)}>
      {children}
      <span className="font-medium">{label}</span>
    </div>
  );
}
