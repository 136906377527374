import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../api";
import { MutationConfig, QueryConfig } from "../utils/types";
import { GameEvent, GameScore, GameEventType, GameStatsByPlayer } from "./models";
import { queryKeys } from "./queryKeys";
import * as dt from "date-fns";
import { useGame } from "./game";
import { useToast } from "../../components/ui/use-toast";
import _ from "lodash";

export type Sport = "basketball"; // if we add more
export const GameStatsBySport: Record<Sport, GameEventType[]> = {
  basketball: [
    "basketball_score_three_pointer",
    "basketball_score_field_goal",
    "basketball_miss",
    "basketball_rebound",
    "basketball_assist",
    "basketball_steal",
    "basketball_turnover",
    "basketball_block",
    "basketball_free_throw_score",
    "basketball_free_throw_miss",
    "basketball_foul",
  ],
};

export const StatLabels: Record<GameEventType, string> = {
  basketball_score_three_pointer: "3PT",
  basketball_assist: "AST",
  basketball_miss: "MISS",
  basketball_rebound: "RB",
  basketball_score_field_goal: "FG",
  basketball_steal: "ST",
  basketball_turnover: "TO",
  basketball_block: "BLK",
  basketball_foul: "FOUL",
  basketball_free_throw_score: "FTM",
  basketball_free_throw_miss: "FTA",
};

const GameEventsPageSize = 20;

export function useGameEvents(gameId: string) {
  const { run } = useApi();
  const getGameEvents = async (skip: number) =>
    await run<GameEvent[]>({
      path: `/games/${gameId}/events`,
      method: "GET",
      params: {
        skip,
        size: GameEventsPageSize,
      },
    });
  const query = {
    queryKey: queryKeys.games.events(gameId),
    queryFn: async (params: { pageParam: { skip: number } }) =>
      await getGameEvents(params.pageParam.skip),
    getNextPageParam: (lastPage: { length: number }, pages: { length: number }[]) =>
      lastPage.length < GameEventsPageSize
        ? undefined
        : {
            skip: pages.flat().length,
          },
    initialPageParam: {
      skip: 0,
    },
  };
  return useInfiniteQuery(query);
}

const isWithinMinutes = (date: string | Date, minutes: number) =>
  dt.isWithinInterval(date, {
    start: dt.sub(new Date(), { minutes }),
    end: new Date(),
  });

export function useGameEventsRefresher(gameId: string) {
  const { data: game } = useGame(gameId);
  const gameEventsQuery = useGameEvents(gameId);
  const { status, data, refetch } = gameEventsQuery;
  // long poll for results
  const events = data?.pages.flat() ?? [];
  const { isSuccess } = useQuery({
    queryKey: [],
    queryFn: async () => await refetch(),
    enabled:
      game?.gameState === "active" &&
      status === "success" &&
      (events.length === 0 || events.some(e => isWithinMinutes(e.createdAt, 5))),
    refetchOnMount: true,
    refetchInterval: 5000,
  });

  return { ...gameEventsQuery, isSuccess };
}

export function useCreateGameEvents(
  gameId: string,
  config?: MutationConfig<GameEvent, typeof createGameEvent>,
) {
  const queryClient = useQueryClient();
  const { run } = useApi();
  async function createGameEvent(payload: { playerId: string; stat: GameEventType }) {
    return await run<GameEvent>({
      method: "POST",
      path: `/games/${gameId}/events`,
      body: payload,
    });
  }

  const mutation = useMutation({
    ...config,
    mutationFn: createGameEvent,
    onSettled: async (_, __, variables) => {
      if (
        variables.stat === "basketball_score_field_goal" ||
        variables.stat === "basketball_score_three_pointer" ||
        variables.stat === "basketball_free_throw_score"
      ) {
        await queryClient.invalidateQueries({
          queryKey: queryKeys.games.score(gameId),
        });
      }
    },
  });
  return mutation;
}

export function useSessionStats(sessionId: string, config?: QueryConfig<GameStatsByPlayer>) {
  const { run } = useApi();
  const fn = async () =>
    await run<GameStatsByPlayer>({
      path: `/collections/${sessionId}/stats`,
      method: "GET",
    });
  const query = {
    ...config,
    queryKey: queryKeys.session.stats(sessionId),
    queryFn: fn,
  };
  return useQuery(query);
}

export function useSessionTitles(sessionId: string) {
  const { data: stats } = useSessionStats(sessionId);
  if (!stats) return {};
  const aggregates = Object.keys(stats).map(p => ({
    playerId: p,
    ...stats[p],
  }));

  const mvp = _.maxBy(aggregates, a => {
    // TODO: extract this out to it's own fn and assign "values" to each stat type
    const positives = _.sum([
      a.basketball_assists,
      a.basketball_blocks,
      a.basketball_points,
      a.basketball_rebounds,
      a.basketball_steals,
    ]);
    const negatives = _.sum([
      a.basketball_free_throws_attempts,
      a.basketball_field_goal_attempts,
      a.basketball_turnovers,
    ]);
    return positives - negatives;
  })?.playerId;
  const mostRebounds = _.maxBy(aggregates, a => a.basketball_rebounds)?.playerId;
  return {
    mostRebounds,
    mvp,
  };
}

export function useGameStats(gameId: string, config?: QueryConfig<GameStatsByPlayer>) {
  const { run } = useApi();
  const fn = async () =>
    await run<GameStatsByPlayer>({
      path: `/games/${gameId}/stats`,
      method: "GET",
    });
  const query = {
    ...config,
    queryKey: queryKeys.games.stats(gameId),
    queryFn: fn,
  };
  return useQuery(query);
}

export function useGameScore(
  gameId: string,
  longPollGame = false,
  config?: QueryConfig<GameScore>,
) {
  const { run } = useApi();
  const { data: game } = useGame(gameId);
  const getScore = async () =>
    await run<GameScore>({
      path: `/games/${gameId}/stats/score`,
      method: "GET",
    });
  const query = {
    ...config,
    queryKey: queryKeys.games.score(gameId),
    queryFn: getScore,
    refetchInterval: game?.gameState === "active" && longPollGame ? 5000 : 0,
  };
  return useQuery(query);
}

export function useDeleteStat(
  gameId: string,
  config?: MutationConfig<GameEvent, typeof deleteStat>,
) {
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const { run } = useApi();
  async function deleteStat(id: string) {
    return await run<GameEvent>({
      method: "DELETE",
      path: `/games/${gameId}/events/${id}`,
    });
  }
  return useMutation({
    ...config,
    mutationFn: deleteStat,
    onSuccess: data => {
      toast({
        title: `Undo ${StatLabels[data.stat]} Success!`,
        description: `Log for ${data.occurrences} ${StatLabels[data.stat]} was removed.`,
      });
      queryClient.invalidateQueries({ queryKey: queryKeys.games.stats(gameId) });
      queryClient.invalidateQueries({ queryKey: queryKeys.games.events(gameId) });
      queryClient.invalidateQueries({ queryKey: queryKeys.games.score(gameId) });
    },
  });
}
