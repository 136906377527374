import { Button } from "./Button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";

interface ConfirmationDialogProps {
  type: "confirm" | "error";
  questionText?: string;
  cancelText?: string;
  confirmText?: string;
  action: () => void;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  isLoading?: boolean;
}
export function ConfirmationDialog({
  open,
  onOpenChange,
  isLoading,
  type,
  action,
  cancelText = "No. Not yet!",
  confirmText = "Yes!",
  questionText = "Are you sure?",
}: ConfirmationDialogProps) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="h-auto bg-white sm:max-w-xl">
        <DialogHeader>
          <DialogTitle>{questionText}</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col justify-center gap-3 px-2.5 pb-8 pt-4 md:flex-row">
          <Button
            className="w-full"
            intent="outline"
            label={cancelText}
            onClick={() => onOpenChange(false)}
          />
          <Button
            className="w-full"
            isLoading={isLoading}
            intent={type === "confirm" ? "green" : "danger"}
            label={confirmText}
            onClick={action}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
