enum Prefix {
  SESSIONS = "sessions",
  GAMES = "games",
  PLAYERS = "players",
  STATS = "stats",
  USERS = "users",
  HOSTS = "hosts",
  LINKS = "links",
}

export const queryKeys = {
  users: {
    me: (userId: string) => [Prefix.USERS, "me", userId],
  },
  session: {
    detail: (sessionId: string) => [Prefix.SESSIONS, sessionId] as const,
    all: [Prefix.SESSIONS] as const,
    games: (sessionId: string) => [Prefix.SESSIONS, sessionId, Prefix.GAMES] as const,
    players: (sessionId: string) => [Prefix.SESSIONS, sessionId, Prefix.PLAYERS],
    stats: (sessionId: string) => [Prefix.SESSIONS, sessionId, Prefix.STATS],
    hosts: (sessionId: string) => [Prefix.SESSIONS, sessionId, Prefix.HOSTS],
    links: (sessionId: string) => [Prefix.SESSIONS, sessionId, Prefix.LINKS],
    forUser: (userId: string) => [Prefix.SESSIONS, "user", userId],
    forPlayer: (playerId: string) => [Prefix.SESSIONS, "player", playerId],
  },
  players: {
    detail: (playerId: string) => [Prefix.PLAYERS, playerId] as const,
    search: (search: string, limit: number) => [Prefix.PLAYERS, "search", search, limit] as const,
  },
  games: {
    detail: (gameId: string) => [Prefix.GAMES, gameId] as const,
    players: (gameId: string) => [Prefix.GAMES, gameId, Prefix.PLAYERS] as const,
    stats: (gameId: string) => [Prefix.GAMES, gameId, Prefix.STATS],
    score: (gameId: string) => [Prefix.GAMES, gameId, "score"],
    events: (gameId: string) => [Prefix.GAMES, gameId, "events"],
    forUser: (userId: string) => [Prefix.GAMES, "user", userId],
  },
};
