import { SVGProps } from "react";

const StretchLegPull = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <defs>
      <style>
        {
          ".cls-1,.cls-2{fill:none;stroke:#52525b;stroke-linecap:round;stroke-width:10px}.cls-1{stroke-linejoin:round}.cls-2{stroke-miterlimit:10}"
        }
      </style>
    </defs>
    <g id="Blobs">
      <path
        id="blob_2"
        d="M264.36 43.78c68.47 16.53 255 136.95 125.14 233.75-129.86 96.81 0 144.03-82.22 176.08-82.22 32.05-220.01-67.47-220.01-176.08 0-108.61 96.81-106.25 80.28-155.84-16.53-49.58 28.33-94.45 96.81-77.92Z"
        data-name="blob 2"
        style={{
          fill: "#d1d5db",
          strokeWidth: 0,
          opacity: 0.43,
        }}
      />
    </g>
    <g id="Figures">
      <g id="Stretch_2" data-name="Stretch 2">
        <path
          d="M149.5 415h209M262.5 411l3-71-17-38M284.5 411l6-74-7-96 11-53-4-32"
          className="cls-2"
        />
        <path d="m234.5 206-10 37-25 32-4-7" className="cls-1" />
        <path d="m256.5 268-14.99 16.47L195.5 335l-41-96h12" className="cls-1" />
        <path d="m258.5 150-15 5-49 41-16 57h11l20-46 50-33" className="cls-1" />
        <circle cx={284} cy={112.5} r={27.5} className="cls-2" />
      </g>
    </g>
  </svg>
);
export default StretchLegPull;
