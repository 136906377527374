import type { SVGProps } from "react";
const SvgBlob2 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 2" viewBox="0 0 75 75" {...props}>
    <path
      d="M25.13 3.63C15.09 1.14 6.82 14.54 6.82 32.98s3.62 27.3 15.91 35.77 41.74 2.22 46.73-17.34c3.58-14.03-8.72-29.63-21.91-28.79-10.36.66-12.38-16.51-22.42-19Z"
      style={{
        strokeWidth: 0,
      }}
    />
  </svg>
);
export default SvgBlob2;
