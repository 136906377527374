import { StatAggregate } from "../../../lib/data/models";
import { percentageNumber, zeroGuardDivision } from "../../../lib/utils/format";
import { SDData, SDTableColumn } from "../SDTable";

export const basketballGameStatColumns: SDTableColumn[] = [
  // NOTE: Only pass Tailwind classes
  {
    id: "field_goal_percentage",
    name: "FG%",
    classNames: "min-w-12 w-12",
  },
  {
    id: "shots_made_shots_attempted",
    name: "SM/SA",
    classNames: "min-w-16 w-16",
  },
  {
    id: "three_pointers_made",
    name: "3PT",
    classNames: "min-w-12 w-12",
  },
  {
    id: "total_points",
    name: "PTS",
    classNames: "min-w-12 w-12",
  },
  {
    id: "free_throws_made_free_throws_attempted",
    name: "FTM/FTA",
    classNames: "min-w-[72px] w-[72px]",
  },
  {
    id: "rebound",
    name: "RB",
    classNames: "min-w-12 w-12",
  },
  {
    id: "assist",
    name: "AST",
    classNames: "min-w-12 w-12",
  },
  {
    id: "steal",
    name: "ST",
    classNames: "min-w-12 w-12",
  },
  {
    id: "block",
    name: "BLK",
    classNames: "min-w-12 w-12",
  },
  {
    id: "turnover",
    name: "TO",
    classNames: "min-w-12 w-12",
  },
  {
    id: "fouls",
    name: "F",
    classNames: "min-w-12 w-12",
  },
];

export const basketballSessionStatColumns: SDTableColumn[] = [
  {
    id: "games_played_won",
    name: "GW/P",
    classNames: "min-w-12 w-12",
  },
  ...basketballGameStatColumns,
  {
    id: "plus_minus",
    name: "+/-*",
    classNames: "min-w-12 w-12",
  },
];

export type StatColors = "green" | "red" | "transparent";

interface ColorConfig {
  color: StatColors;
  value: string;
  reverseColor: StatColors;
  reverseValue: string;
}
export type HeatMapColors = Record<BasketballStats, ColorConfig>;

export const heatMapColor: HeatMapColors = {
  games_played_won: {
    color: "green",
    value: "113, 188, 179",
    reverseColor: "red",
    reverseValue: "248, 113, 113",
  },
  field_goal_percentage: {
    color: "green",
    value: "113, 188, 179",
    reverseColor: "red",
    reverseValue: "248, 113, 113",
  },
  shots_made_shots_attempted: {
    color: "green",
    value: "transparent",
    reverseColor: "red",
    reverseValue: "248, 113, 113",
  },
  three_pointers_made: {
    color: "green",
    value: "113, 188, 179",
    reverseColor: "red",
    reverseValue: "248, 113, 113",
  },
  total_points: {
    color: "green",
    value: "113, 188, 179",
    reverseColor: "red",
    reverseValue: "248, 113, 113",
  },
  free_throws_made_free_throws_attempted: {
    color: "green",
    value: "113, 188, 179",
    reverseColor: "red",
    reverseValue: "248, 113, 113",
  },
  rebound: {
    color: "green",
    value: "113, 188, 179",
    reverseColor: "red",
    reverseValue: "248, 113, 113",
  },
  assist: {
    color: "green",
    value: "113, 188, 179",
    reverseColor: "red",
    reverseValue: "248, 113, 113",
  },
  steal: {
    color: "green",
    value: "113, 188, 179",
    reverseColor: "red",
    reverseValue: "248, 113, 113",
  },
  block: {
    color: "green",
    value: "113, 188, 179",
    reverseColor: "red",
    reverseValue: "248, 113, 113",
  },
  turnover: {
    color: "red",
    value: "248, 113, 113",
    reverseColor: "green",
    reverseValue: "113, 188, 179",
  },
  fouls: {
    color: "red",
    value: "248, 113, 113",
    reverseColor: "green",
    reverseValue: "113, 188, 179",
  },
  plus_minus: {
    color: "green",
    value: "113, 188, 179",
    reverseColor: "red",
    reverseValue: "248, 113, 113",
  },
};

export type BasketballStats =
  | "games_played_won"
  | "field_goal_percentage"
  | "shots_made_shots_attempted"
  | "three_pointers_made"
  | "total_points"
  | "free_throws_made_free_throws_attempted"
  | "rebound"
  | "assist"
  | "steal"
  | "block"
  | "turnover"
  | "fouls"
  | "plus_minus";

const sessionStatColumnsOrder: BasketballStats[] = [
  "games_played_won",
  "field_goal_percentage",
  "shots_made_shots_attempted",
  "three_pointers_made",
  "total_points",
  "free_throws_made_free_throws_attempted",
  "rebound",
  "assist",
  "steal",
  "block",
  "turnover",
  "fouls",
  "plus_minus",
];

const gameStatColumnsOrder: BasketballStats[] = [
  "field_goal_percentage",
  "shots_made_shots_attempted",
  "three_pointers_made",
  "total_points",
  "free_throws_made_free_throws_attempted",
  "rebound",
  "assist",
  "steal",
  "block",
  "turnover",
  "fouls",
];

type SessionStatColumn = (typeof sessionStatColumnsOrder)[number];
type SessionStatRow = Record<SessionStatColumn, { label: string; value: number | string }>;
type GameStatRow = Omit<SessionStatRow, "games_played_won" | "plus_minus">;

const statOrder = (stat: BasketballStats, statColumns: BasketballStats[]): number =>
  statColumns.findIndex(v => v === stat);

const plusMinusPrefix = (number: number) => {
  if (number > 0) {
    return "+";
  } else {
    return "";
  }
};

const calculateFGP = ({
  basketball_field_goal_attempts,
  basketball_field_goal_made,
}: StatAggregate) => {
  if (basketball_field_goal_made === 0) return 0;
  return zeroGuardDivision(basketball_field_goal_made, basketball_field_goal_attempts);
};

// const defaultSessionStatRow: SessionStatRow = {
//   games_played_won: { label: "-", value: 0 },
//   shots_made_shots_attempted: { label: "-", value: 0 },
//   field_goal_percentage: { label: "-", value: 0 },
//   three_pointers_made: { label: "-", value: 0 },
//   total_points: { label: "-", value: 0 },
//   rebound: { label: "-", value: 0 },
//   assist: { label: "-", value: 0 },
//   steal: { label: "-", value: 0 },
//   block: { label: "-", value: 0 },
//   turnover: { label: "-", value: 0 },
//   fouls: { label: "-", value: 0 },
//   plus_minus: { label: "-", value: 0 },
// };

// const defaultGameStatRow: GameStatRow = {
//   shots_made_shots_attempted: { label: "-", value: 0 },
//   field_goal_percentage: { label: "-", value: 0 },
//   three_pointers_made: { label: "-", value: 0 },
//   total_points: { label: "-", value: 0 },
//   rebound: { label: "-", value: 0 },
//   assist: { label: "-", value: 0 },
//   steal: { label: "-", value: 0 },
//   block: { label: "-", value: 0 },
//   turnover: { label: "-", value: 0 },
//   fouls: { label: "-", value: 0 },
// };

const sessionStatRowMapping = (stats: StatAggregate): SessionStatRow => {
  const statRow: SessionStatRow = {
    games_played_won: {
      label: `${stats.games_won}/${stats.games_played}`,
      value: zeroGuardDivision(stats.games_won, stats.games_played),
    },
    shots_made_shots_attempted: {
      label: `${stats.basketball_field_goal_made}/${stats.basketball_field_goal_attempts}`,
      value: zeroGuardDivision(
        stats.basketball_field_goal_made,
        stats.basketball_field_goal_attempts,
      ),
    },
    field_goal_percentage: {
      label: `${percentageNumber(calculateFGP(stats))}`,
      value: percentageNumber(calculateFGP(stats)),
    },
    three_pointers_made: {
      label: `${stats.basketball_three_pointers_made}`,
      value: stats.basketball_three_pointers_made,
    },
    free_throws_made_free_throws_attempted: {
      label: `${stats.basketball_free_throws_made}/${stats.basketball_free_throws_attempts}`,
      value: zeroGuardDivision(
        stats.basketball_free_throws_made,
        stats.basketball_free_throws_attempts,
      ),
    },
    total_points: {
      label: `${stats.basketball_points}`,
      value: stats.basketball_points,
    },
    rebound: {
      label: `${stats.basketball_rebounds}`,
      value: stats.basketball_rebounds,
    },
    assist: {
      label: `${stats.basketball_assists}`,
      value: stats.basketball_assists,
    },
    steal: {
      label: `${stats.basketball_steals}`,
      value: stats.basketball_steals,
    },
    block: {
      label: `${stats.basketball_blocks}`,
      value: stats.basketball_blocks,
    },
    turnover: {
      label: `${stats.basketball_turnovers}`,
      value: stats.basketball_turnovers,
    },
    fouls: {
      label: `${stats.basketball_fouls}`,
      value: stats.basketball_fouls,
    },
    plus_minus: {
      label: `${plusMinusPrefix(stats.plus_minus)}${stats.plus_minus}`,
      value: stats.plus_minus,
    },
  };
  return statRow;
};

const gameStatRowMapping = (stats: StatAggregate): GameStatRow => {
  const statRow: GameStatRow = {
    shots_made_shots_attempted: {
      label: `${stats.basketball_field_goal_made}/${stats.basketball_field_goal_attempts}`,
      value: zeroGuardDivision(
        stats.basketball_field_goal_made,
        stats.basketball_field_goal_attempts,
      ),
    },
    field_goal_percentage: {
      label: `${percentageNumber(calculateFGP(stats))}`,
      value: percentageNumber(calculateFGP(stats)),
    },
    three_pointers_made: {
      label: `${stats.basketball_three_pointers_made}`,
      value: stats.basketball_three_pointers_made,
    },
    free_throws_made_free_throws_attempted: {
      label: `${stats.basketball_free_throws_made}/${stats.basketball_free_throws_attempts}`,
      value: zeroGuardDivision(
        stats.basketball_free_throws_made,
        stats.basketball_free_throws_attempts,
      ),
    },
    total_points: {
      label: `${stats.basketball_points}`,
      value: stats.basketball_points,
    },
    rebound: {
      label: `${stats.basketball_rebounds}`,
      value: stats.basketball_rebounds,
    },
    assist: {
      label: `${stats.basketball_assists}`,
      value: stats.basketball_assists,
    },
    steal: {
      label: `${stats.basketball_steals}`,
      value: stats.basketball_steals,
    },
    block: {
      label: `${stats.basketball_blocks}`,
      value: stats.basketball_blocks,
    },
    turnover: {
      label: `${stats.basketball_turnovers}`,
      value: stats.basketball_turnovers,
    },
    fouls: {
      label: `${stats.basketball_fouls}`,
      value: stats.basketball_fouls,
    },
  };
  return statRow;
};

export const mapBasketballSessionStats = (stats: StatAggregate) => {
  const statRow: SessionStatRow = sessionStatRowMapping(stats);
  const statsRowData: SDData[] = Object.keys(statRow)
    .map(key => {
      return {
        name: key as BasketballStats,
        label: statRow[key as keyof SessionStatRow].label,
        value: statRow[key as keyof SessionStatRow].value,
      };
    })
    .sort(
      (a, b) =>
        statOrder(a.name, sessionStatColumnsOrder) - statOrder(b.name, sessionStatColumnsOrder),
    );

  return statsRowData;
};

export const mapBasketballGameStats = (stats: StatAggregate) => {
  const statRow: GameStatRow = gameStatRowMapping(stats);
  const statsRowData: SDData[] = Object.keys(statRow)
    .map(key => {
      return {
        name: key as BasketballStats,
        label: statRow[key as keyof GameStatRow].label,
        value: statRow[key as keyof GameStatRow].value,
      };
    })
    .sort(
      (a, b) => statOrder(a.name, gameStatColumnsOrder) - statOrder(b.name, gameStatColumnsOrder),
    );

  return statsRowData;
};
