import { GameEvent, GameEventType, PlayerWithDetails } from "../../lib/data/models";
import { StatLabels } from "../../lib/data/stats";
import { fullName } from "../../lib/utils/format";

export function getMessage(stat: GameEventType, player: PlayerWithDetails | undefined) {
  const forPlayerText = player ? `for ${fullName(player.user)}` : "";
  switch (stat) {
    case "basketball_score_three_pointer":
    case "basketball_score_field_goal":
    case "basketball_miss":
    case "basketball_rebound":
    case "basketball_assist":
    case "basketball_steal":
    case "basketball_turnover":
    case "basketball_block":
    case "basketball_free_throw_score":
    case "basketball_free_throw_miss":
      return `Logged ${StatLabels[stat]} ${forPlayerText}`.trim();
  }
}

export function getUndoMessage(stat: GameEventType, player: PlayerWithDetails | undefined) {
  const forPlayerText = player ? `for ${fullName(player.user)}` : "";
  switch (stat) {
    case "basketball_score_three_pointer":
    case "basketball_score_field_goal":
    case "basketball_miss":
    case "basketball_rebound":
    case "basketball_assist":
    case "basketball_steal":
    case "basketball_turnover":
    case "basketball_block":
    case "basketball_free_throw_score":
    case "basketball_free_throw_miss":
      return `Removed ${StatLabels[stat]} ${forPlayerText}`.trim();
  }
}

export function feedMessage(event: GameEvent) {
  switch (event.stat) {
    case "basketball_score_three_pointer":
      return `Made a 3 pointer.`;
    case "basketball_score_field_goal":
      return `Scored a field goal.`;
    case "basketball_miss":
      return `Missed a shot attempt.`;
    case "basketball_rebound":
      return `Rebounded the ball.`;
    case "basketball_assist":
      return `Got an assist.`;
    case "basketball_steal":
      return `Steals the ball!`;
    case "basketball_turnover":
      return `Boo! Turnover!`;
    case "basketball_block":
      return `Got a block!`;
    case "basketball_foul":
      return `Foul!`;
    case "basketball_free_throw_score":
      return `Scored a free throw.`;
    case "basketball_free_throw_miss":
      return `Attempted & missed a free throw.`;
    case "basketball_foul":
      return `Missed a free throw.`;
  }
}
