import { useSession } from "../../../lib/data/session";
import { classNames } from "../../../lib/utils/classNames";
import { Badge } from "../../ui/badge";

export const BasketballSettingsBadges = ({
  sessionId,
  className,
}: {
  sessionId: string;
  className?: string;
}) => {
  const { data: session } = useSession(sessionId);

  if (!session?.basketballSettings) return null;

  return (
    <span className={classNames("flex gap-2 overflow-auto px-2.5 py-1", className)}>
      <Badge color="sdGreenLight" shape="squared">
        <span className="font-medium leading-4">FGV:</span>{" "}
        {session.basketballSettings.fieldGoalValue}
      </Badge>
      <Badge color="sdGreenLight" shape="squared">
        <span className="font-medium leading-4">3PT:</span>{" "}
        {session.basketballSettings.threePointFieldGoalValue}
      </Badge>
    </span>
  );
};
