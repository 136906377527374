import type { SVGProps } from "react";
const SvgBlob1 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 75 75" {...props}>
    <path
      d="M56.88 19.44c16.46 15.63 15.45 36.22 1.13 43.91s-35.56 10.74-40.13.69 12.93-11.3 8.18-27.09S9.1 21.55 11.58 10.34s28.83-6.53 45.3 9.1"
      style={{
        strokeWidth: 0,
      }}
    />
  </svg>
);
export default SvgBlob1;
