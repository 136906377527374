import { createElement, useState } from "react";
import { IconType } from "react-icons";
import { GrSubtractCircle } from "react-icons/gr";
import { Button, IconButton } from "./Button";
import { classNames } from "../lib/utils/classNames";

export function IconWithConfirmation({
  confirmationText,
  onConfirm,
  className,
  icon = GrSubtractCircle,
  buttonColor = "danger",
}: {
  className?: string;
  confirmationText: string;
  onConfirm: () => void;
  icon?: IconType;
  buttonColor?: "danger" | "primary";
}) {
  const [clicked, setClicked] = useState<boolean>(false);
  return !clicked ? (
    <IconButton intent="iconOnly" label="remove" onClick={() => setClicked(true)}>
      {createElement(icon, {
        className: classNames("h-[24px] w-[24px] shrink-0 animate-fade cursor-pointer", className),
      })}
    </IconButton>
  ) : (
    <Button
      className={classNames("animate-fade", className)}
      onClick={onConfirm}
      size="xs"
      intent={buttonColor}
      label={confirmationText}
    />
  );
}
