import { Nameable } from "../../lib/utils/types";
import { AvatarList } from "./AvatarList";
import { classNames as cn } from "../../lib/utils/classNames";
import { AvatarStack } from "./AvatarStack";

interface Props {
  users: (Nameable & { id: string; imageUrl: string })[];
  open: boolean;
  showNumberOfUsers?: number;
  onClick?: () => void;
  className?: string;
}
export function AvatarListToStack({
  users,
  open,
  className = "",
  showNumberOfUsers = 3,
  onClick,
}: Props) {
  const content = open ? (
    <AvatarList className="animate-fade cursor-pointer" users={users} onClick={onClick} />
  ) : (
    <AvatarStack
      className={cn("animate-fade", onClick ? "cursor-pointer" : "")}
      showNumberOfUsers={showNumberOfUsers}
      users={users}
      onClick={onClick}
    />
  );
  return <div className={className}>{content}</div>;
}
