import { SVGProps } from "react";

const StretchTouchToes = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
    <path
      d="M101.12 171.11c-53.44 32.79-91.07 213.17 8.11 236.55C279.62 447.83 464.26 362.26 452 231.5c-7.29-77.74-127.68-211.06-194.29-116.48-58.02 82.38-103.15 23.3-156.59 56.09Z"
      data-name="blob 5"
      style={{
        fill: "#d1d5db",
        opacity: 0.43,
        strokeWidth: 0,
      }}
    />
    <path
      d="m108.05 342.54-14.03-20.13 126.28-14.1 99.22-14.09-6.02-30.2-29.56-16.28"
      style={{
        strokeWidth: "10.32px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        fill: "none",
        stroke: "#52525b",
      }}
    />
    <path
      d="m292.46 221.73-57.15 41.7a7.2 7.2 0 0 1-2.37 1.25l-115.87 33.78-11.02-8.31 107.81-52.43c.7-.35 1.35-.81 1.9-1.37l61.17-57.41 18.55-6.54c13.66 6.02 56.21 43.86 77.82 63.51a56.316 56.316 0 0 1 16.89 28.59l6.4 26.73c2.05 8.58.64 17.63-3.96 25.17-6.96 11.39-17.34 26.89-22.99 28.15"
      style={{
        strokeLinecap: "round",
        strokeLinejoin: "round",
        fill: "none",
        stroke: "#52525b",
        strokeWidth: "10.32px",
      }}
    />
    <ellipse
      cx={224.6}
      cy={159.74}
      rx={30.73}
      ry={30.87}
      style={{
        strokeMiterlimit: 10,
        strokeWidth: "10.29px",
        fill: "none",
        stroke: "#52525b",
      }}
    />
    <path
      d="m222.8 209.15-41.27 27.15-78.37 43.21"
      style={{
        strokeWidth: "9.04px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        fill: "none",
        stroke: "#52525b",
      }}
    />
    <path
      d="M73.47 346.96h349.78"
      style={{
        strokeWidth: "10.29px",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        fill: "none",
        stroke: "#52525b",
      }}
    />
  </svg>
);
export default StretchTouchToes;
