import { GrAddCircle } from "react-icons/gr";
import { Button } from "../../components/Button";
import { useCreateSessionLink, useLinksBySession, useSession } from "../../lib/data/session";
import _ from "lodash";
import { AddLinkForm, LinkWithActions } from "../../components/LinkItem";
import { useState } from "react";
import { Collection } from "../../lib/data/models";
import { ModalSessionSubheader } from "../../components/ModalSessionSubheader";

interface AddForm {
  id: string;
  onSubmit: (link: { url: string; title: string }) => Promise<Collection>;
  onCancel: () => void;
}

export function EditSessionLinks({ sessionId }: { sessionId: string }) {
  const [addForms, setAddForms] = useState<AddForm[]>([]);
  const { data: session } = useSession(sessionId);
  const { data: links = [] } = useLinksBySession(sessionId);
  const { mutateAsync: createSessionLink } = useCreateSessionLink(sessionId);

  if (!session) {
    return <div>Not Found</div>;
  }

  const addNewLink = () => {
    if (links.length + addForms.length < 3) {
      const uniqueId = _.uniqueId();
      const newForm = {
        id: uniqueId,
        onSubmit: createSessionLink,
        onCancel: () => setAddForms(addForms => addForms.filter(form => form.id !== uniqueId)),
      };
      setAddForms([...addForms, newForm]);
    }
  };

  const addLinkLabel = (
    <span className="inline-flex items-center gap-2">
      <GrAddCircle aria-hidden className="h-[24px] w-[24px]" />
      <span className="h-fit pt-0.5">Add a link</span>
    </span>
  );

  return (
    <div className="flex min-h-0 flex-1 flex-col">
      <ModalSessionSubheader session={session} />
      <div className="flex flex-1 flex-col gap-3 overflow-y-auto px-2.5 pb-6 sm:px-5">
        <div className="flex flex-col">
          <span className="text-md block pt-2.5 font-medium">Links</span>
          <span className="text-sm italic">Limit of 3 links per session.</span>
        </div>
        {(links.length > 0 || addForms.length > 0) && (
          <div className="flex flex-col gap-3">
            {links.map(link => {
              return <LinkWithActions key={link.id} link={link} />;
            })}
            {addForms.map(form => {
              return <AddLinkForm key={form.id} sessionId={sessionId} onCancel={form.onCancel} />;
            })}
          </div>
        )}
        {links.length + addForms.length < 3 && (
          <Button
            intent="inline"
            size="inline"
            label={addLinkLabel}
            className="flex w-fit items-center"
            onClick={addNewLink}
          ></Button>
        )}
      </div>
    </div>
  );
}
