import { useState } from "react";
import { useEditSessionHosts, useHostsBySession, useSession } from "../../lib/data/session";
import { IconButton } from "../../components/Button";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { GrFormSearch } from "react-icons/gr";
import _ from "lodash";
import { SearchPlayers } from "./SearchSessionPlayers";
import { PlayersListWithRemove } from "../../components/PlayersList";
import { ModalSessionSubheader } from "../../components/ModalSessionSubheader";

type View = "list" | "search";

export function EditSessionHosts({ sessionId }: { sessionId: string }) {
  const [view, setView] = useState<View>("list");
  const { data: session } = useSession(sessionId);
  const { data: hosts = [] } = useHostsBySession(sessionId);
  const { mutateAsync: editSessionHosts } = useEditSessionHosts(sessionId);

  if (!session) {
    return <div>Not Found</div>;
  }

  const addHost = async (userId: string) => {
    const hosts = _.uniq(session.hosts.concat([userId]));
    try {
      await editSessionHosts(hosts);
    } catch (e) {
      console.error(`failed to add hosts to session.`);
    }
  };

  const toggle = (newView: View) => {
    if (view !== newView) {
      setView(newView);
    }
  };

  const tipMessage = () => {
    switch (view) {
      case "list":
        return (
          <span className="px-2.5 py-1.5 text-sm sm:px-5">
            Below is your session's host roster. Click the search icon to add a host. Once added, a
            host cannot be removed.
          </span>
        );
      case "search":
        return (
          <span className="px-2.5 pt-1.5 text-sm sm:px-5">
            Search for an existing user by their registered email.
          </span>
        );
    }
  };

  const description = () => {
    switch (view) {
      case "list":
        return <div className="text-md font-medium">Hosts ({hosts.length})</div>;
      case "search":
        return <div className="text-md font-medium">Search Users</div>;
    }
  };

  const icons = (
    <div role="list" className="flex flex-row items-center">
      <IconButton
        data-active={view === "list"}
        role="listitem"
        label="Session player list"
        intent="iconTab"
        onClick={() => toggle("list")}
      >
        <MdOutlinePeopleAlt
          aria-hidden="true"
          className="h-[24px] w-[24px]"
          fill={view === "list" ? "#007163" : "currentColor"}
        />
      </IconButton>
      <IconButton
        data-active={view === "search"}
        role="listitem"
        label="Player search"
        intent="iconTab"
        onClick={() => toggle("search")}
      >
        <GrFormSearch
          aria-hidden="true"
          className="h-[30px] w-[30px]"
          stroke={view === "search" ? "#007163" : "currentColor"}
        />
      </IconButton>
    </div>
  );

  const body = () => {
    switch (view) {
      case "list":
        return <PlayersListWithRemove players={hosts} />;
      case "search":
        return (
          <SearchPlayers
            registeredOnly
            existingPlayers={hosts.map(p => p.id)}
            onAdd={player => addHost(player.user.id)}
          />
        );
    }
  };

  return (
    <div className="flex min-h-0 flex-1 flex-col">
      <ModalSessionSubheader session={session} />
      <div className="flex flex-1 flex-col overflow-auto">
        <div className="flex flex-row items-end justify-between px-2.5 pt-2.5 sm:px-5">
          {description()}
          {icons}
        </div>
        {tipMessage()}
        {body()}
      </div>
    </div>
  );
}
