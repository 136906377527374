import { useState } from "react";
import { PlayerWithDetails } from "../../lib/data/models";
import { GrAddCircle } from "react-icons/gr";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../../components/ui/dialog";
import { AddPlayersFromSession } from "../sessions/SearchSessionPlayers";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { Button } from "../../components/Button";
import { useCreateGame } from "../../lib/data/game";
import { useNavigate } from "react-router-dom";
import { PlayersListWithRemove } from "../../components/PlayersList";

interface CreateGameProps {
  sessionId: string;
}
export function CreateGameView({ sessionId }: CreateGameProps) {
  const navigate = useNavigate();
  const [isCreating, setCreating] = useState<boolean>(false);
  const [homeTeam, setHomeTeam] = useState<PlayerWithDetails[]>([]);
  const [awayTeam, setAwayTeam] = useState<PlayerWithDetails[]>([]);
  const { mutateAsync: createGame } = useCreateGame();

  const alreadyAddedPlayers = homeTeam.concat(awayTeam);
  const homeTeamSection = (
    <TeamSection
      sessionId={sessionId}
      onAdd={player => {
        setHomeTeam(players => {
          return [...players, player];
        });
      }}
      onRemove={player => {
        setHomeTeam(players => {
          return players.filter(p => p.id !== player.id);
        });
      }}
      teamTitle="Team 1"
      currentPlayers={homeTeam}
      alreadyAddedPlayers={alreadyAddedPlayers}
    />
  );
  const awayTeamSection = (
    <TeamSection
      sessionId={sessionId}
      onAdd={player => {
        setAwayTeam(players => {
          return [...players, player];
        });
      }}
      onRemove={player => {
        setAwayTeam(players => {
          return players.filter(p => p.id !== player.id);
        });
      }}
      teamTitle="Team 2"
      currentPlayers={awayTeam}
      alreadyAddedPlayers={alreadyAddedPlayers}
    />
  );

  const onCreate = async () => {
    setCreating(true);
    try {
      await createGame({
        sport: "basketball",
        awayTeam: awayTeam.map(p => p.id),
        homeTeam: homeTeam.map(p => p.id),
        collectionId: sessionId,
      });
      navigate(`/session/${sessionId}`);
    } catch (e) {
      console.error(e);
    } finally {
      setCreating(false);
    }
  };
  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-6">
        <div className="heading-1">Create Game</div>
        {/* TODO: Add game title */}
        {homeTeamSection}
        {awayTeamSection}
        <Button isLoading={isCreating} intent="green" label="Create the game!" onClick={onCreate} />
      </div>
    </div>
  );
}

function TeamSection({
  teamTitle,
  currentPlayers,
  alreadyAddedPlayers,
  onAdd,
  onRemove,
  sessionId,
}: {
  sessionId: string;
  teamTitle: string;
  currentPlayers: PlayerWithDetails[];
  alreadyAddedPlayers: PlayerWithDetails[];
  onAdd: (player: PlayerWithDetails) => void;
  onRemove: (player: PlayerWithDetails) => void;
}) {
  const [open, setOpen] = useState<boolean>(false);

  const modal = (
    <DialogContent className="bg-white sm:max-w-xl">
      <DialogHeader>
        <DialogTitle>
          <div>Add to {teamTitle}</div>
        </DialogTitle>
      </DialogHeader>
      <AddPlayersFromSession
        existingPlayers={alreadyAddedPlayers.map(p => p.id)}
        sessionId={sessionId}
        onAdd={onAdd}
      />
    </DialogContent>
  );
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <div className="bg-white p-2 outline">
        {modal}
        <DialogTrigger className="float-right">
          <GrAddCircle aria-hidden className="h-[24px] w-[24px]" />
          <span className="sr-only">Add Players</span>
        </DialogTrigger>
        <div className="heading-3 mb-2">{teamTitle}</div>
        <PlayersListWithRemove players={currentPlayers} onRemove={player => onRemove(player)} />
        {currentPlayers.length === 0 && (
          <span className="text-sm italic text-grey-dark">No players currently. Add some!</span>
        )}
      </div>
    </Dialog>
  );
}
