import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from "../../components/ui/select";
import { Button } from "../../components/Button";
import { format, formatISO, roundToNearestMinutes } from "date-fns";
import { useState } from "react";
import { useCreateSession } from "../../lib/data/session";
import { useNavigate } from "react-router-dom";
import { TextArea } from "../../components/ui/textarea";

type Preset = "all_ones" | "streetball" | "official" | "custom";
export function CreateSessionForm({ closeModal }: { closeModal?: () => void }) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [preset, setPreset] = useState<Preset>("all_ones");
  const navigate = useNavigate();

  const formSchema = z.object({
    sport: z.enum(["basketball"]),
    title: z.string().min(3, "Please enter a session name"),
    fieldGoalValue: z.coerce.number().min(1, "Please enter a valid value (min. 1)"),
    threePointFieldGoalValue: z.coerce.number().min(1, "Please enter a valid value (min. 1)"),
    description: z.string().max(500, `Please enter less than 500 characters`).optional(),
    location: z.string().optional(),
    startDate: z.string().min(1, "Please enter a date/time"),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      sport: "basketball",
      title: "",
      fieldGoalValue: 1,
      threePointFieldGoalValue: 1,
      description: "",
      startDate: format(
        roundToNearestMinutes(new Date(), { nearestTo: 30, roundingMethod: "floor" }),
        "yyyy-MM-dd HH:mm",
      ),
    },
  });

  const mutation = useCreateSession({
    onSuccess: data => {
      closeModal?.();
      navigate(`/session/${data.id}`);
    },
    onError: console.log,
  });

  const onSubmit = async () => {
    setLoading(true);
    try {
      const values = formSchema.parse(form.getValues());
      const formattedStartDate = formatISO(new Date(values.startDate));
      await mutation
        .mutateAsync({ ...values, startDate: formattedStartDate })
        .finally(() => setLoading(false));
    } catch (e) {
      console.error(`form error: ${JSON.stringify(e)}`);
    } finally {
      setLoading(false);
    }
  };

  const dayOfWeek = format(new Date(), "EEEE");

  const onClickPreset = (preset: Preset) => {
    const setValues = (fieldGoalValue: number, threePointFieldGoalValue: number) => {
      form.setValue("fieldGoalValue", fieldGoalValue, { shouldValidate: true });
      form.setValue("threePointFieldGoalValue", threePointFieldGoalValue, { shouldValidate: true });
    };
    setPreset(preset);
    switch (preset) {
      case "all_ones":
        setValues(1, 1);
        return;
      case "streetball":
        setValues(1, 2);
        return;
      case "official":
        setValues(2, 3);
        return;
      case "custom":
        return;
    }
  };

  // Currently going to restrict headers to 53px... TODO: make dynamic
  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex h-[calc(100%-53px)] flex-col justify-between overflow-auto"
      >
        <div className="overflow-auto px-2.5 py-8 sm:px-5">
          <FormField
            control={form.control}
            name="sport"
            render={({ field }) => (
              <FormItem className="pb-3">
                <FormLabel>Sport Type</FormLabel>
                <FormControl>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select Sport..." />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent className="bg-white-custom">
                      <SelectItem value="basketball">Basketball</SelectItem>
                      <SelectItem value="basketball2" disabled={true}>
                        More Sports Underway!
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem className="pb-3">
                <FormLabel>Session Name</FormLabel>
                <FormControl>
                  <Input placeholder={`${dayOfWeek} Basketball Run!`} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="pb-3">
            <div className="flex gap-2">
              <FormField
                control={form.control}
                name="fieldGoalValue"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>Field Goal Value</FormLabel>
                    <FormControl>
                      <Input
                        readOnly={preset !== "custom"}
                        placeholder="1"
                        type="number"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="threePointFieldGoalValue"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel>3PT Value</FormLabel>
                    <FormControl>
                      <Input
                        readOnly={preset !== "custom"}
                        placeholder="1"
                        type="number"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="mt-2 flex flex-row items-center justify-center gap-1">
              <Button
                className="rounded-xl"
                size="xs"
                type="button"
                intent={preset === "all_ones" ? "green" : "light"}
                onClick={() => onClickPreset("all_ones")}
                label="All 1s"
              />
              <Button
                className="rounded-xl"
                size="xs"
                type="button"
                intent={preset === "streetball" ? "green" : "light"}
                onClick={() => onClickPreset("streetball")}
                label="Streetball"
              />
              <Button
                className="rounded-xl"
                size="xs"
                type="button"
                intent={preset === "official" ? "green" : "light"}
                onClick={() => onClickPreset("official")}
                label="Official"
              />
              <Button
                className="rounded-xl"
                size="xs"
                type="button"
                intent={preset === "custom" ? "green" : "light"}
                onClick={() => setPreset("custom")}
                label="Custom"
              />
            </div>
            <small>
              <span className="font-medium">Important</span>: field goal values cannot be changed
              once set.
            </small>
          </div>
          <FormField
            control={form.control}
            name="startDate"
            render={({ field }) => (
              <FormItem className="pb-3">
                <FormLabel>Date & Time</FormLabel>
                <FormControl>
                  <Input
                    type="datetime-local"
                    placeholder="Time"
                    {...field}
                    onChange={e => {
                      try {
                        const formatted = format(new Date(e.target.value), "yyyy-MM-dd HH:mm");
                        field.onChange(formatted);
                      } catch (e) {
                        field.onChange(() => "");
                      }
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem className="pb-3">
                <FormLabel>Notes</FormLabel>
                <FormControl>
                  <TextArea placeholder="500 character limit..." {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="w-full border-t px-2.5 py-5 shadow-above sm:px-5">
          <Button
            className="w-full md:max-w-full"
            intent="green"
            disabled={isLoading}
            type="submit"
            onClick={onSubmit}
            label="add"
          />
        </div>
      </form>
    </Form>
  );
}
