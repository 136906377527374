import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../api";
import { Game, PlayerWithDetails } from "./models";
import { queryKeys } from "./queryKeys";
import { MutationResult, MutationConfig, QueryConfig } from "../utils/types";

export function useGame(gameId: string, config?: QueryConfig<Game>) {
  const { run } = useApi();
  const fn = async () => await run<Game>({ method: "GET", path: `/games/${gameId}` });
  const query = {
    queryKey: queryKeys.games.detail(gameId),
    queryFn: fn,
    staleTime: 60000,
    ...config,
  };
  return useQuery(query);
}

export function useGamesForUser(userId: string) {
  const { run } = useApi();
  const fn = async () => await run<Game[]>({ method: "GET", path: `/users/${userId}/games` });
  const query = {
    queryKey: queryKeys.games.forUser(userId),
    queryFn: fn,
    staleTime: 60000,
  };
  return useQuery(query);
}

export function useSuggestedPlayers(sessionId: string) {
  const { run } = useApi();
  const fn = async () =>
    await run<PlayerWithDetails[]>({
      method: "GET",
      path: `/collections/${sessionId}/suggested-players`,
    });
  return useQuery({
    queryKey: ["suggested-players", sessionId],
    queryFn: fn,
    staleTime: 20000,
  });
}

export function useGamesBySession(sessionId: string) {
  const { run } = useApi();

  async function getGamesBySession() {
    return await run<Game[]>({
      method: "GET",
      path: `/collections/${sessionId}/games`,
    });
  }
  const queryGames = {
    queryKey: queryKeys.session.games(sessionId),
    queryFn: getGamesBySession,
    staleTime: 10000,
  };

  return useQuery(queryGames);
}

export function useGenerateGame(
  sessionId: string,
  config?: MutationConfig<Game, typeof generateGame>,
): MutationResult<Game, typeof generateGame> {
  const queryClient = useQueryClient();
  const { run } = useApi();
  async function generateGame(payload: {
    sport: "basketball";
    homeTeamPlayersCount: number;
    awayTeamPlayersCount: number;
  }): Promise<Game> {
    return await run<Game>({
      method: "POST",
      path: `/collections/${sessionId}/games`,
      body: payload,
    });
  }
  const mutation = useMutation({
    ...config,
    mutationFn: generateGame,
    onSettled: async () =>
      await queryClient.invalidateQueries({
        queryKey: queryKeys.session.games(sessionId),
      }),
  });
  return mutation;
}

export function useCreateGame(
  config?: MutationConfig<Game, typeof createGame>,
): MutationResult<Game, typeof createGame> {
  const queryClient = useQueryClient();
  const { run } = useApi();
  async function createGame(payload: {
    sport: "basketball";
    homeTeam: string[];
    awayTeam: string[];
    collectionId?: string;
  }): Promise<Game> {
    return await run<Game>({
      method: "POST",
      path: `/games`,
      body: { ...payload, dateTime: new Date().toISOString() }, // TODO: fix time
    });
  }
  const mutation = useMutation({
    ...config,
    mutationFn: createGame,
    onSettled: async (_, __, variables) => {
      if (variables.collectionId) {
        await queryClient.invalidateQueries({
          queryKey: queryKeys.session.games(variables.collectionId),
        });
      }
    },
  });
  return mutation;
}

export function useActivateGame(config?: MutationConfig<Game, typeof activateGame>) {
  const queryClient = useQueryClient();
  const { run } = useApi();
  async function activateGame(gameId: string): Promise<Game> {
    return await run<Game>({
      path: `/games/${gameId}`,
      method: "PATCH",
      body: {
        gameState: "active",
      },
    });
  }
  const mutation = useMutation({
    ...config,
    mutationFn: activateGame,
    onSettled: game => {
      if (game) {
        queryClient.invalidateQueries({ queryKey: queryKeys.games.detail(game.id) });
      }
    },
  });
  return mutation;
}

export function useCompleteGame(config?: MutationConfig<Game, typeof completeGame>) {
  const queryClient = useQueryClient();
  const { run } = useApi();
  async function completeGame(gameId: string): Promise<Game> {
    return await run<Game>({
      path: `/games/${gameId}`,
      method: "PATCH",
      body: {
        gameState: "complete",
      },
    });
  }
  const mutation = useMutation({
    ...config,
    mutationFn: completeGame,
    onSettled: game => {
      if (game) {
        queryClient.invalidateQueries({ queryKey: queryKeys.games.detail(game.id) });
      }
    },
  });
  return mutation;
}

export function useDeleteGame(config?: MutationConfig<Game, typeof deleteGame>) {
  const queryClient = useQueryClient();
  const { run } = useApi();
  async function deleteGame(gameId: string): Promise<Game> {
    return await run<Game>({
      path: `/games/${gameId}`,
      method: "DELETE",
    });
  }
  const mutation = useMutation({
    ...config,
    mutationFn: deleteGame,
    onSettled: game => {
      if (!game) return;
      if (game.collectionId) {
        queryClient.invalidateQueries({
          queryKey: queryKeys.session.games(game.collectionId),
        });
      }
      queryClient.invalidateQueries({ queryKey: queryKeys.games.detail(game.id) });
    },
  });
  return mutation;
}
