import type { SVGProps } from "react";
const SvgAst = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="ast_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 50 50"
    {...props}
  >
    <defs>
      <style>{".ast_svg__cls-1{stroke-width:0}"}</style>
    </defs>
    <g id="ast_svg__Dime">
      <path
        d="m13.92 25.27-.54-2.11 3.68-.94 2.48 9.7-2.36.6-1.94-7.59zM19.79 22.71c.37-1.03 1.2-1.71 2.51-2.04s2.37-.14 3.19.59 1.41 1.85 1.8 3.38c.39 1.54.41 2.83.04 3.85-.37 1.03-1.2 1.71-2.51 2.04s-2.37.14-3.18-.59-1.42-1.86-1.81-3.4c-.39-1.52-.4-2.8-.04-3.83m4.45.5c-.32-.43-.78-.57-1.39-.42-.61.16-.95.5-1.02 1.03s0 1.25.23 2.14c.15.6.32 1.09.49 1.47s.4.66.68.85.63.23 1.06.12.71-.31.87-.61.22-.66.19-1.07-.12-.92-.27-1.52c-.23-.89-.5-1.56-.82-1.99ZM33.61 24.23a1 1 0 0 0 .28-.67l2.09-.53c.12.75-.02 1.42-.45 2.02-.42.59-1.03 1.03-1.8 1.3l.28 1.08-.85.22-.28-1.08c-1.01.16-1.88 0-2.59-.46q-1.065-.705-1.44-2.19c-.25-.98-.18-1.85.22-2.61s1.08-1.31 2.05-1.65l-.27-1.08.85-.22.27 1.08c.82-.13 1.55-.04 2.21.28s1.11.84 1.36 1.56l-2.09.53c-.3-.46-.76-.61-1.4-.45-.45.12-.77.35-.95.7s-.21.79-.08 1.3.36.88.69 1.1.72.28 1.17.16c.31-.08.55-.21.73-.4Z"
        className="ast_svg__cls-1"
      />
      <path
        d="M25 3.31C13.04 3.31 3.31 13.04 3.31 25S13.04 46.69 25 46.69 46.69 36.96 46.69 25 36.96 3.31 25 3.31m18.87 23.91-2-.23c-.08.66-.2 1.31-.35 1.94l1.97.46c-.17.71-.38 1.43-.63 2.12l-1.91-.69c-.23.62-.49 1.23-.78 1.81l1.81.91c-.33.66-.7 1.3-1.1 1.91l-1.69-1.12c-.36.55-.76 1.08-1.18 1.58l1.55 1.3c-.47.56-.98 1.1-1.51 1.61l-1.4-1.48c-.48.45-.98.87-1.51 1.27l1.22 1.64c-.59.44-1.21.85-1.84 1.22L33.5 39.7c-.57.33-1.15.63-1.76.9l.81 1.87c-.67.29-1.37.55-2.07.76l-.58-1.95c-.62.19-1.27.33-1.92.45l.35 2.01c-.72.13-1.46.21-2.19.26l-.12-2.05c-.33.02-.66.05-1 .05s-.65-.03-.97-.05L23.93 44c-.73-.04-1.47-.13-2.19-.25l.35-2.02c-.65-.11-1.29-.26-1.92-.44l-.58 1.95c-.7-.21-1.4-.46-2.08-.75l.8-1.87c-.61-.26-1.19-.57-1.76-.89l-1.02 1.77c-.63-.37-1.25-.77-1.85-1.21l1.22-1.64c-.53-.39-1.03-.81-1.51-1.26l-1.4 1.49c-.53-.5-1.04-1.04-1.52-1.6l1.55-1.31c-.42-.5-.82-1.03-1.19-1.58l-1.69 1.12c-.4-.61-.78-1.25-1.11-1.91l1.81-.92c-.3-.58-.56-1.19-.79-1.81l-1.9.7c-.25-.69-.47-1.4-.64-2.12l1.97-.47c-.15-.64-.28-1.28-.35-1.94l-2 .24c-.09-.72-.13-1.46-.13-2.2h2v-.05c0-.65.05-1.29.12-1.93l-1.99-.23c.08-.73.21-1.47.38-2.18l1.96.46q.225-.975.57-1.89l-1.9-.69c.25-.68.54-1.37.87-2.03l1.81.9c.3-.59.62-1.17.98-1.71l-1.68-1.1c.41-.62.85-1.21 1.32-1.78l1.54 1.29c.42-.51.88-.98 1.36-1.43l-1.39-1.46c.53-.51 1.1-.99 1.69-1.43l1.21 1.62c.53-.4 1.07-.76 1.64-1.09l-1.02-1.74c.64-.37 1.3-.71 1.97-1l.8 1.84c.6-.26 1.22-.49 1.85-.68l-.58-1.92c.7-.21 1.42-.38 2.15-.51l.35 1.98c.64-.11 1.3-.19 1.96-.23l-.12-2.02c.73-.04 1.48-.05 2.21 0l-.11 2.02c.66.04 1.32.11 1.96.22l.34-1.99c.73.13 1.45.29 2.15.5l-.57 1.93c.64.19 1.26.41 1.86.67l.79-1.84c.67.29 1.34.62 1.98.99l-1 1.75c.57.33 1.12.69 1.65 1.09l1.2-1.63c.59.44 1.16.91 1.7 1.42l-1.38 1.47c.48.45.94.92 1.37 1.43l1.53-1.3c.48.56.92 1.16 1.33 1.77l-1.67 1.11q.54.825.99 1.71l1.8-.91c.33.65.63 1.34.88 2.03l-1.89.7q.345.915.57 1.89l1.96-.47c.17.71.3 1.44.39 2.17l-2 .24c.08.67.13 1.34.13 2.03v.01l2-.07v.07c0 .73-.04 1.48-.13 2.2Z"
        style={{
          strokeWidth: 0,
          fill: "#212121",
        }}
      />
    </g>
  </svg>
);
export default SvgAst;
