import { ToastAction } from "@radix-ui/react-toast";
import { toast } from "../../components/ui/use-toast";
import { GameEventType, PlayerWithDetails } from "../../lib/data/models";
import { useCreateGameEvents } from "../../lib/data/stats";
import { StatButton } from "./StatButton";

interface StatButtonListProps {
  gameId: string;
  disabled: boolean;
  stats: GameEventType[];
  selectedPlayerId: string;
  player?: PlayerWithDetails;
  refreshFeed: () => void;
}

export function StatButtonList({
  stats,
  disabled,
  gameId,
  selectedPlayerId,
  refreshFeed,
}: StatButtonListProps) {
  const { mutateAsync: createGameEvent } = useCreateGameEvents(gameId);

  const onMouseDown = async (stat: GameEventType) => {
    try {
      await createGameEvent({ playerId: selectedPlayerId, stat });
      await refreshFeed();
    } catch (e) {
      // TODO: create a FE placeholder into the STAT FEED to redo
      console.log("err", e);
      toast({
        variant: "destructive",
        title: `Error`,
        description: `Unable to log stat ${stat} for ${selectedPlayerId}`,
        action: (
          <ToastAction onClick={() => console.log(`TODO: try again`)} altText="Try again!">
            Try Again
          </ToastAction>
        ),
      });
    }
  };

  const makeButton = (stat: GameEventType) => (
    <StatButton key={stat} disabled={disabled} stat={stat} onMouseDown={() => onMouseDown(stat)} />
  );

  return (
    <div className="max-h-sm grid min-w-0 max-w-sm flex-1 grid-cols-2 gap-1.5 px-1">
      {stats.map(makeButton)}
    </div>
  );
}
