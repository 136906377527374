import { UserAvatar } from "../../components/avatar/UserAvatar";
import { PlayerWithDetails } from "../../lib/data/models";
import { classNames } from "../../lib/utils/classNames";
import { sortPlayers } from "../../lib/utils/sort";

export function PlayerList({
  players,
  selectedPlayerId,
  onSelect,
  align = "left",
}: {
  players: PlayerWithDetails[];
  selectedPlayerId: string;
  onSelect: (playerId: string) => void;
  align?: "left" | "right";
  clickableAvatar?: boolean;
}) {
  return (
    <div
      dir={align === "left" ? "ltr" : "rtl"}
      className={classNames("flex min-w-0 flex-1 flex-col items-stretch gap-4")}
    >
      {sortPlayers(players).map(player => (
        <button
          key={player.id}
          className={classNames("flex min-w-0 py-1")}
          onMouseDown={() => onSelect(player.id)}
        >
          <UserAvatar
            user={player.user}
            highlight={selectedPlayerId === player.id}
            align={align}
            showFullName
            stackName
          />
        </button>
      ))}
    </div>
  );
}
