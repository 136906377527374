import { GameList } from "./GameList";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { GameList as GameListV2 } from "./GameListV2";
import { Game } from "../../lib/data/models";
import { ContentContainer } from "../../components/ContentContainer";
import { useState } from "react";
import { Badge } from "../../components/ui/badge";
import { IconButton } from "../../components/Button";
import { classNames as cn } from "../../lib/utils/classNames";
import { TbLayoutNavbarExpandFilled } from "react-icons/tb";
import { EmptyDisplay } from "../../components/EmptyDisplay";

const gameFilterTypes = ["all", "live", "pending", "complete"] as const;
export type FilterTypes = (typeof gameFilterTypes)[number];

export const generateCountMap = (games: Game[]): { [key in FilterTypes]: number } => {
  const gameCountMap: { [key in FilterTypes]: number } = {
    all: games.length,
    live: 0,
    pending: 0,
    complete: 0,
  };

  for (const game of games) {
    switch (game.gameState) {
      case "active":
        gameCountMap.live++;
        break;
      case "pending":
        gameCountMap.pending++;
        break;
      case "complete":
        gameCountMap.complete++;
        break;
    }
  }
  return gameCountMap;
};

export function SessionGamesSection({ games = [] }: { games: Game[] }) {
  const gameListV2Flag = useFeatureFlagEnabled("game-list-v2");
  const countMap = generateCountMap(games);
  const [cardsExpanded, setCardsExpanded] = useState(
    localStorage.getItem("gameCardsExpandState") === "true",
  );
  const [filterState, setFilterState] = useState<FilterTypes>(
    (localStorage.getItem("gameCardsFilterState") as FilterTypes | undefined) ?? "all",
  );

  const filteredGames = games.filter(game => {
    switch (filterState) {
      case "all":
        return true;
      case "live":
        return game.gameState === "active";
      case "pending":
        return game.gameState === "pending";
      case "complete":
        return game.gameState === "complete";
      default:
        return true;
    }
  });

  const toggleExpand = () => {
    localStorage.setItem("gameCardsExpandState", `${!cardsExpanded}`);
    setCardsExpanded(!cardsExpanded);
  };

  const updateFilterState = (filterType: FilterTypes) => {
    localStorage.setItem("gameCardsFilterState", `${filterType}`);
    setFilterState(filterType);
  };

  const theGameList = gameListV2Flag ? (
    <GameListV2 expanded={cardsExpanded} className="mt-4" games={filteredGames} />
  ) : (
    <GameList expanded={cardsExpanded} className="mt-4" games={filteredGames} />
  );

  return (
    <ContentContainer>
      <div className="sticky top-[calc(var(--header-height-default))] z-40 flex h-[var(--game-list-config-height-default)] items-center gap-2 overflow-auto bg-white py-2 md:top-[calc(var(--header-height-md))]">
        <IconButton
          intent="iconOnly"
          label={`${!cardsExpanded ? "expand" : "collapse"} game cards`}
          onClick={() => toggleExpand()}
        >
          <TbLayoutNavbarExpandFilled
            aria-hidden="true"
            className={cn(
              "h-[32px] w-[32px] transition",
              cardsExpanded ? "rotate-[-180deg] text-green" : "text-zinc-600",
            )}
          />
        </IconButton>
        {gameFilterTypes.map(filterType => {
          const selected = filterType === filterState;
          return (
            <Badge
              key={filterType}
              state={selected ? "selected" : "unselected"}
              color={selected ? filterType : "white"}
              onClick={() => updateFilterState(filterType)}
            >
              {`${filterType}: ${countMap[filterType]}`}
            </Badge>
          );
        })}
      </div>
      {filteredGames.length ? (
        theGameList
      ) : (
        <EmptyDisplay text="No games, yet." subtext="Add a game or ask your host." />
      )}
    </ContentContainer>
  );
}
