import { useParams } from "react-router-dom";
import { ContentContainer } from "../../components/ContentContainer";
import { PageContainer } from "../../components/PageContainer";
import { SessionGamesSection } from "../games/SessionGamesSection";
import { SessionPageHeader } from "../sessions/SessionPageHeader";
import { useGamesBySession } from "../../lib/data/game";
import { NotFoundPage } from "./NotFound";
import { useSession } from "../../lib/data/session";
import { ErrorContent } from "./ErrorPage";
import { LoadingSpinner } from "../../components/Spinner";
import { FolderTabs, TabsConfig } from "../../components/ui/folder-tabs";
import { SessionStatsTable } from "../stats/SessionStatsTable";

// wrapper to ensure id is present
export function SessionPage() {
  const { id } = useParams<{ id: string }>();
  if (!id) {
    return <NotFoundPage returnRoute="/dashboard" />;
  }
  return (
    <PageContainer>
      <SessionPageContent sessionId={id} />
    </PageContainer>
  );
}

export function SessionPageContent({ sessionId }: { sessionId: string }) {
  const session = useSession(sessionId);
  const { data: games = [] } = useGamesBySession(sessionId);

  const tabsConfig: TabsConfig = {
    ariaLabel: "session tab list",
    defaultValue: "GAMES",
    tabs: [
      {
        value: "GAMES",
        content: <SessionGamesSection games={games} />,
      },
      {
        value: "STATS",
        content: <SessionStatsTable sessionId={sessionId} />,
      },
    ],
    bgColor: "white",
  };

  switch (session.status) {
    case "pending":
      return <LoadingSpinner />;
    case "error":
      return <ErrorContent message="Unable to find session. Please try again later." />;
    case "success":
      return (
        <>
          <ContentContainer className="max-w-full p-0">
            <SessionPageHeader sessionId={sessionId} />
          </ContentContainer>
          <FolderTabs tabsConfig={tabsConfig} />
        </>
      );
  }
}
