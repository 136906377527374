import { ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ClerkProvider } from "@clerk/clerk-react";
import { RouterProvider } from "react-router-dom";
import { router } from "./lib/routes";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { HelmetProvider } from "react-helmet-async";
import { env } from "./lib/env";
import { PostHogProvider } from "posthog-js/react";
import { PostHogConfig } from "posthog-js";

function App() {
  return (
    <AppWrapper>
      <RouterProvider router={router} />
    </AppWrapper>
  );
}

function AppWrapper({ children }: { children: ReactNode }) {
  const clerkKey = env.REACT_APP_PUBLISHABLE_KEY;
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
      },
    },
  });
  const postHogOptions: Partial<PostHogConfig> = {
    api_host: env.REACT_APP_PUBLIC_POSTHOG_HOST,
    autocapture: {
      dom_event_allowlist: ["click", "change"], // DOM events from this list ['click', 'change', 'submit']
      element_allowlist: ["button", "a"], // DOM elements from this list ['a', 'button', 'form', 'input', 'select', 'textarea', 'label']
    },
  };
  return (
    <PostHogProvider options={postHogOptions} apiKey={env.REACT_APP_PUBLIC_POSTHOG_KEY}>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <ClerkProvider publishableKey={clerkKey}>{children}</ClerkProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </HelmetProvider>
    </PostHogProvider>
  );
}

export default App;
