import { LoadingSpinner } from "../../components/Spinner";
import { TitledSection } from "../../components/TitledSection";
import { SessionsList } from "./SessionsList";
import { Button } from "../../components/Button";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { CreateSessionForm } from "./CreateSessionForm";
import * as dt from "date-fns";
import { useSessions } from "../../lib/data/session";
import { FolderTabs, TabsConfig } from "../../components/ui/folder-tabs";
import { ContentContainer } from "../../components/ContentContainer";

export function SessionsSection() {
  const [open, setOpen] = useState<boolean>(false);
  const {
    isPending,
    refetch,
    error,
    data: sessions,
  } = useSessions({
    startDate: dt.sub(new Date(), { days: 120 }).toISOString(),
  });

  if (isPending) return <LoadingSpinner />;
  if (error) return <div>An error occurred</div>;

  const createButton = (
    <DialogTrigger asChild>
      <Button className="w-full md:max-w-xs" intent="yellow" label="add a session +" />
    </DialogTrigger>
  );

  const onCloseModal = () => {
    setOpen(false);
    refetch();
  };

  const modal = (
    <DialogContent className="w-full bg-white-custom sm:max-w-md">
      <DialogHeader>
        <DialogTitle>Add Session</DialogTitle>
      </DialogHeader>
      <CreateSessionForm closeModal={onCloseModal} />
    </DialogContent>
  );

  const ongoingSessions = sessions
    .filter(session => dt.isToday(session.startDate) || dt.isFuture(session.startDate))
    .sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
  const pastSessions = sessions
    .filter(session =>
      // is before the start of today, which means yesterday and older.
      dt.isBefore(session.startDate, dt.startOfDay(new Date())),
    )
    .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());

  const tabsConfig: TabsConfig = {
    ariaLabel: "Sessions tab list",
    defaultValue: "ONGOING",
    tabs: [
      {
        value: "ONGOING",
        content: <SessionsList sessions={ongoingSessions} />,
      },
      {
        value: "PAST",
        content: <SessionsList sessions={pastSessions} />,
      },
    ],
    bgColor: "white",
  };

  return (
    <>
      <ContentContainer>
        <Dialog open={open} onOpenChange={setOpen}>
          <TitledSection title="Sessions">
            {modal}
            {createButton}
          </TitledSection>
        </Dialog>
      </ContentContainer>
      <FolderTabs tabsConfig={tabsConfig} />
    </>
  );
}
