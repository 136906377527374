import type { SVGProps } from "react";
const SvgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="icon_svg__Layer_2"
    data-name="Layer 2"
    viewBox="0 0 130.53 103.21"
    {...props}
  >
    <defs>
      <style>{".icon_svg__cls-1{stroke-width:0}"}</style>
    </defs>
    <g id="icon_svg__Base">
      <g id="icon_svg__somedubs_Logo" data-name="somedubs Logo">
        <g id="icon_svg__SomeDubs_Logo" data-name="SomeDubs Logo">
          <g id="icon_svg__Core_Lines" data-name="Core Lines">
            <path
              d="M42.7 71.82c-4.83-.68-9.29-1.8-13.21-3.31l-1.76-27.3h13zM44.73 103.21h-13l-1.36-21.07c4.11 1.2 8.52 2.06 13.17 2.57zM53.5 85.24c2.73 0 5.52-.11 8.35-.35l-1.12 16.12h-13l1.1-15.89c1.54.08 3.09.12 4.67.12M64.73 43.28l-2 28.81c-4.47.46-8.84.57-13.02.38l2.02-29.19zM70.58 70.96c-.94.18-1.87.34-2.8.48l-1.95-28.16h13l1.75 25.23c-3.21.98-6.55 1.8-10 2.45M82.83 101.01h-13L68.66 84.1c1.41-.2 2.82-.44 4.25-.71 2.93-.55 5.79-1.2 8.56-1.96zM87.35 79.64c4.76-1.59 9.24-3.49 13.36-5.63l-1.88 29.2h-13zM102.83 41.21l-1.13 17.48c-3.95 2.66-8.48 5.07-13.46 7.1l1.59-24.58z"
              className="icon_svg__cls-1"
            />
          </g>
          <path
            d="M130.05 30.47C125.77 7.61 93.95-4.97 57.62 1.85 21.29 8.66-3.8 31.91.48 54.77 2.92 67.8 14.3 77.49 30.37 82.14l-.88-13.63c-9.06-3.49-15.27-9.06-16.58-16.07-2.88-15.36 18.66-32.84 47.04-38.16a92 92 0 0 1 16.87-1.59c10.63 0 20.26 1.97 27.52 5.47 7.25 3.49 12.13 8.52 13.28 14.64 1.66 8.84-4.77 18.38-15.92 25.89l-.99 15.32c18.28-9.49 29.82-23.77 29.82-38.42 0-1.7-.16-3.41-.48-5.12"
            className="icon_svg__cls-1"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgIcon;
