import { VariantProps, cva } from "class-variance-authority";
import { GameScore as Model } from "../../lib/data/models";
import { FaCaretRight, FaCaretLeft } from "react-icons/fa";
import { classNames } from "../../lib/utils/classNames";

export const gameScoreVariants = cva("flex items-center font-sans font-bold leading-tight", {
  variants: {
    size: {
      xs: ["text-[20px]"],
      sm: ["text-[24px]"],
      md: ["text-[32px]"],
      lg: ["text-[60px]"],
      xl: ["text-[75px]"],
    },
    bg: {
      black: ["bg-black", "text-white", "fill-white"],
      white: ["bg-white", "text-black", "fill-black"],
      green: ["bg-green", "text-white", "fill-white"],
    },
    defaultVariants: {
      size: "md",
      bg: "white",
    },
  },
});

export interface GameScoreProps extends VariantProps<typeof gameScoreVariants> {
  final: boolean;
  score: Model;
}

export function GameScore({ score, size, bg, final }: GameScoreProps) {
  return (
    <div
      className={classNames(
        gameScoreVariants({ bg }),
        "flex flex-row items-center justify-between gap-1 only:justify-center only:gap-2",
      )}
    >
      <span className={gameScoreVariants({ size })}>
        {score.homeTeam}
        {final && score.awayTeam < score.homeTeam && (
          <FaCaretLeft className={gameScoreVariants({ bg })} size="12" />
        )}
      </span>
      <span className="leading-none">{final ? "FIN" : "|"}</span>
      <span className={gameScoreVariants({ size })}>
        {final && score.awayTeam > score.homeTeam && (
          <FaCaretRight className={gameScoreVariants({ bg })} size="12" />
        )}
        {score.awayTeam}
      </span>
    </div>
  );
}
