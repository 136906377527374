import { ContentContainer } from "../../components/ContentContainer";
import { EmptyDisplay } from "../../components/EmptyDisplay";
import { Collection } from "../../lib/data/models";
import { SessionCard } from "./SessionCard";

export function SessionsList({ sessions }: { sessions: Collection[] }) {
  return (
    <ContentContainer>
      {sessions.length === 0 ? (
        <EmptyDisplay
          image="StretchLegPull"
          text="No sessions, yet."
          subtext="Add a session with the button above."
        />
      ) : (
        <div className="flex w-full flex-col gap-4 md:grid md:grid-cols-2 [&:not(:last-child)]:mt-3">
          {sessions.map(session => (
            <SessionCard session={session} key={session.id} />
          ))}
        </div>
      )}
    </ContentContainer>
  );
}
