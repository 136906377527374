import { Badge } from "./ui/badge";
import {
  useSessionPermissions,
  useGamePermissions,
  useIsPlayerInSession,
  useIsPlayerInGame,
} from "../lib/permissions/index";
import { useMe } from "../lib/data/user";
import { classNames } from "../lib/utils/classNames";

export const GameBadges = ({ gameId, className }: { gameId: string; className?: string }) => {
  const { isCurrentUserGameHost } = useGamePermissions(gameId);
  const { data: me } = useMe();
  const showPlayer = useIsPlayerInGame(me?.id, gameId);

  if (!isCurrentUserGameHost && !showPlayer) return null;

  return (
    <span className={classNames("flex gap-1.5 overflow-auto px-2.5 py-2", className)}>
      {isCurrentUserGameHost && <Badge color="red">host</Badge>}
      {showPlayer && <Badge color="gray">player</Badge>}
    </span>
  );
};

export const SessionBadges = ({
  sessionId,
  className,
}: {
  sessionId: string;
  className?: string;
}) => {
  const { isCurrentUserSessionHost } = useSessionPermissions(sessionId);
  const { data: me } = useMe();
  const showPlayer = useIsPlayerInSession(me?.id, sessionId);

  return (
    <span className={classNames("flex gap-1.5 overflow-auto px-2.5 py-2", className)}>
      {isCurrentUserSessionHost && <Badge color="red">host</Badge>}
      {showPlayer && <Badge color="gray">player</Badge>}
    </span>
  );
};
