import { Link } from "react-router-dom";
import { Collection } from "../../lib/data/models";
import { useGamesBySession } from "../../lib/data/game";
import { FaChevronRight } from "react-icons/fa";
import { classNames } from "../../lib/utils/classNames";
import { SessionBadges } from "../../components/Badges";
import { SessionInformation } from "./SessionInfo";

export function SessionCard({ session }: { session: Collection }) {
  const { isPending: isGamesPending, data: games = [] } = useGamesBySession(session.id);
  const numberOfCompletedGames = games.filter(g => g.gameState === "complete").length;
  const totalGames = games.filter(g =>
    ["pending", "active", "complete"].includes(g.gameState),
  ).length;
  const bottomText =
    totalGames === 0 ? `0 games` : `${numberOfCompletedGames} of ${totalGames} games completed`;
  const isPending = numberOfCompletedGames < totalGames;

  return (
    <div className="relative mb-auto w-full md:max-w-full">
      <div className="border-grey-300 border-x border-t">
        <SessionInformation sessionId={session.id} />
        <SessionBadges sessionId={session.id} />
      </div>
      <Link
        className="group before:absolute before:inset-0 before:content-['']"
        to={`/session/${session.id}`}
        key={session.id}
      >
        <div
          className={classNames(
            "px-2 py-1 text-right text-white",
            isPending ? "bg-green-emerald" : "bg-grey-dark",
          )}
        >
          {!isGamesPending && (
            <span className="flex flex-row items-center justify-end gap-2 text-sm group-hover:underline">
              {bottomText} <FaChevronRight className="float-right" size="15" />
            </span>
          )}
        </div>
      </Link>
    </div>
  );
}
