import { Link } from "react-router-dom";
import { Button } from "../../components/Button";
import { ContentContainer } from "../../components/ContentContainer";
import { PageContainer } from "../../components/PageContainer";

interface NotFoundPageProps {
  returnRoute?: string;
}
export function NotFoundPage({ returnRoute }: NotFoundPageProps) {
  return (
    <PageContainer>
      <ContentContainer className="flex flex-col items-center gap-1">
        <div className="heading-1">Oops! Page Not Found.</div>
        <div>Looks like the page you're looking for doesn't exist.</div>
        <Link className="mt-3" to={returnRoute ?? "/"}>
          <Button intent="green" label="take me home" />
        </Link>
      </ContentContainer>
    </PageContainer>
  );
}
