import { Navigate, createBrowserRouter } from "react-router-dom";
import Dashboard from "../../views/pages/Dashboard";
import { AuthenticateWithRedirectCallback, SignedIn, SignedOut } from "@clerk/clerk-react";
import SignedInPage from "../../views/pages/SignedInPage";
import { LandingPage } from "../../views/pages/LandingPage";
import { GoogleSignIn } from "../../views/auth/SignIn";
import { SessionPage } from "../../views/pages/SessionPage";
import { GamePage } from "../../views/pages/GamePage";
import ScrollToTop from "../../components/ScrollToTop";
import { NotFoundPage } from "../../views/pages/NotFound";
import { ErrorPage } from "../../views/pages/ErrorPage";
import { AddMetaTags } from "../metatags";
import { CreateGamePage } from "../../views/pages/CreateGamePage";
import { UserPage } from "../../views/pages/UserPage";
import { PlayerPage } from "../../views/pages/PlayerPage";
import { GameStatKeeperPage } from "../../views/pages/GameStatKeeperPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <AddMetaTags />
        <ScrollToTop />
        <LandingPage />
      </>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <AddMetaTags title="Session Dashboard: Recreational Sports Stat Tracking Made Simple | Somedubs" />
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "/session/:id",
    element: (
      <ProtectedRoute>
        {/* TODO: we can add the session title to the meta tag level */}
        <AddMetaTags />
        <SessionPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/session/:id/create",
    element: (
      <ProtectedRoute>
        <AddMetaTags />
        <CreateGamePage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/game/:id",
    element: (
      <ProtectedRoute>
        <AddMetaTags />
        <GamePage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/game/:id/stats",
    element: (
      <ProtectedRoute>
        <AddMetaTags />
        <GameStatKeeperPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/u/:id",
    element: (
      <ProtectedRoute>
        <AddMetaTags />
        <UserPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/p/:id",
    element: (
      <ProtectedRoute>
        <AddMetaTags />
        <PlayerPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/sign-in",
    element: <GoogleSignIn />,
  },
  { path: "/signed-in", element: <SignedInPage /> },
  {
    path: "/sso-callback",
    element: <AuthenticateWithRedirectCallback redirectUrl="/signed-in" />,
  },
  {
    path: "/404",
    element: <NotFoundPage />,
  },
  {
    path: "/500",
    element: <ErrorPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

function ProtectedRoute({
  children,
  fallback = "/sign-in",
}: {
  children: JSX.Element | JSX.Element[];
  fallback?: string;
}) {
  return (
    <>
      <ScrollToTop />
      <SignedIn>{children}</SignedIn>
      <SignedOut>
        <Navigate to={fallback} />
      </SignedOut>
    </>
  );
}
