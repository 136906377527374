import { Link } from "react-router-dom";
import { Button } from "../../components/Button";
import { PageContainer } from "../../components/PageContainer";
import { BasketballDunkYellow, Laurels, RunningStickYellow } from "../../components/svgr";
import { SportCard } from "../../components/SportCard";
import { Blob2 } from "../../components/svgr/blobs";

export function LandingPage() {
  return (
    <PageContainer>
      <Hero />
      <RealtimeUpdates />
      <LargeBanner />
      <SupportedSports />
    </PageContainer>
  );
}

function Hero() {
  return (
    <div className="m-auto flex max-w-screen-sm flex-col items-center justify-start px-2.5 py-10">
      <h1 className="mb-2.5 self-start font-Altivo text-4.5xl font-black uppercase sm:text-5xl">
        <span>Score Every Moment,</span>
        <br />
        <span className="text-green">Seamlessly</span>
      </h1>
      <p className="mb-9">
        Capture and immortalize each play, turning routine actions into historic moments. Add
        significance to your athletic journey.
      </p>
      <div className="flex gap-6">
        <RunningStickYellow aria-label="running figure" width={100} />
        <Link to="/dashboard">
          <Button className="mt-6" intent={"yellow"} label="let's start" />
        </Link>
      </div>
    </div>
  );
}

function RealtimeUpdates() {
  return (
    <div className="flex flex-col items-center justify-center bg-green py-10 pb-0 text-white">
      <div className="h-[550px] max-w-screen-sm items-center px-2.5 sm:h-[600px]">
        <div className="flex flex-col items-center justify-start">
          <h2 className="mb-2.5 self-start font-Altivo text-4xl font-black uppercase">
            Real-Time Updates
          </h2>
          <p className="mb-5">
            Stay Updated Instantly: Our stat-keeping web app provides real-time updates! Share links
            with friends to keep them in the loop as your game unfolds. Plus, our mobile-friendly UI
            lets you effortlessly track your friends' stats on the go!
          </p>
        </div>
        <div className="relative h-full w-full overflow-hidden">
          <div className="mt-2 inline-block h-full w-[250px] min-w-0 bg-iphone14PlayByPlay bg-contain bg-no-repeat sm:min-h-[600px] sm:w-[300px]"></div>
          <div className="absolute left-[150px] top-[45px] inline-block h-full w-[200px] min-w-0 bg-iphone14AggregateStats bg-contain bg-no-repeat sm:left-[200px] sm:top-[75px] sm:min-h-[600px] sm:w-[300px]"></div>
        </div>
      </div>
    </div>
  );
}

function LargeBanner() {
  return (
    <div className="flex h-1/2 max-h-[550px] min-h-[350px] w-full bg-wilt bg-cover bg-center bg-no-repeat backdrop-brightness-50">
      <div className="drop-shadow-3xl relative m-auto flex w-fit flex-col text-center font-Altivo text-4xl font-black uppercase">
        <Laurels
          width={256}
          className="absolute left-1/2 top-1/2 z-0 -translate-x-1/2 -translate-y-1/2 transform fill-green"
        />
        <span className="z-10 text-white-custom">Game,</span>
        <span className="z-10 text-yellow">Stats,</span>
        <span className="z-10 text-white-custom">Victory.</span>
      </div>
    </div>
  );
}

function SupportedSports() {
  return (
    <div className="m-auto flex max-w-screen-sm flex-col items-center justify-start px-2.5 py-10">
      <h2 className="mb-2.5 self-start font-Altivo text-4xl font-black uppercase">
        <span>
          Supported
          <br />
          Sports
        </span>
      </h2>
      <p className="mb-9">
        We’re dedicated to expanding our support to include even more sports. Stay tuned as we
        evolve to embrace the full spectrum of sporting excellence.
      </p>
      <div className="flex w-full gap-6">
        <SportCard label="basketball">
          <BasketballDunkYellow aria-label="basketball dunk icon" width={100} height={100} />
        </SportCard>
        <SportCard className="relative" label="more soon...">
          <Blob2 width={100} height={100} className="fill-green"></Blob2>
          <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform font-Altivo text-2xl font-black text-white">
            WIP
          </span>
        </SportCard>
      </div>
    </div>
  );
}
