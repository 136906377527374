import { VariantProps, cva } from "class-variance-authority";
import { ComponentProps } from "react";
import { classNames } from "../../lib/utils/classNames";

const badgeVariants = cva("max-h-6 w-fit text-nowrap rounded-xl px-2 py-1 text-sm", {
  variants: {
    state: {
      selected: "text-medium",
      unselected: "opacity-75",
    },
    shape: {
      rounded: "rounded-xl leading-none",
      squared: "rounded-sm border-none py-px leading-tight",
    },
    color: {
      red: "border border-red-800 bg-red-300 text-red-800",
      amber: "border border-amber-800 bg-amber-300 text-amber-800",
      yellow: "border border-yellow-800 bg-yellow-300 text-yellow-800",
      green: "border border-green-800 bg-green-300 text-green-800",
      gray: "border border-gray-800 bg-gray-300 text-gray-800",
      white: "border border-black bg-white text-black",
      sdGreen: "border border-green-dark bg-green text-white",
      sdGreenLight: "border border-green-dark bg-green-lighter text-gray-800",
      all: "border border-green-dark bg-green text-white",
      live: "border border-black bg-emerald-600 text-white",
      pending: "border border-black bg-yellow-700 text-white",
      complete: "border border-black bg-grey-dark text-white",
      cancelled: "bg-red-600, border border-black text-white",
    },
  },
});

export type BadgeProps = VariantProps<typeof badgeVariants> &
  ComponentProps<"span"> &
  ComponentProps<"button">;

export const Badge = ({ className, state, color, shape = "rounded", ...props }: BadgeProps) => {
  return props.onClick ? (
    <button
      className={classNames(badgeVariants({ state, color, shape }), className)}
      {...props}
    ></button>
  ) : (
    <span
      className={classNames(badgeVariants({ state, color, shape }), className)}
      {...props}
    ></span>
  );
};
