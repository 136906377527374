import { VscGlobe } from "react-icons/vsc";
import { Link as LinkModel } from "../lib/data/models";
import { MdDeleteOutline } from "react-icons/md";
import { Button, IconButton } from "./Button";
import { FaPencil } from "react-icons/fa6";
import { classNames } from "../lib/utils/classNames";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { useState } from "react";
import {
  useCreateSessionLink,
  useDeleteSessionLink,
  useEditSessionLink,
} from "../lib/data/session";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "./ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { Input } from "./ui/input";

export function LinkItem({
  link,
  suffix,
  className,
}: {
  link: LinkModel;
  suffix?: string | JSX.Element;
  className?: string;
}) {
  return (
    <a
      href={link.url}
      className={classNames(
        "group flex w-full max-w-full items-center justify-between gap-1 rounded-full border border-black px-2 py-3 hover:bg-green hover:text-white active:bg-green active:text-white md:max-w-xs",
        className,
      )}
    >
      <VscGlobe className="h-[24px] min-w-[36px]" />
      <span className="truncate text-green group-hover:text-white">{link.title}</span>
      <span className="w-9">{suffix}</span>
    </a>
  );
}

export function LinkWithActions({
  link,
  suffix,
}: {
  link: LinkModel;
  suffix?: string | JSX.Element;
}) {
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const { mutateAsync: deleteSessionLink } = useDeleteSessionLink(link.collectionId);

  const onConfirmDelete = async () => {
    try {
      await deleteSessionLink(link.id);
      setConfirmDelete(false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="flex w-full gap-2">
      <ConfirmationDialog
        type="error"
        open={confirmDelete}
        onOpenChange={setConfirmDelete}
        action={onConfirmDelete}
        cancelText="No. Keep the link."
        confirmText="Yes. Delete link."
      />
      {!editMode ? (
        <>
          <LinkItem className="min-w-0 flex-1" link={link} suffix={suffix} />
          <IconButton intent="iconOnly" label="edit link" onClick={() => setEditMode(true)}>
            <FaPencil className="h-[18px] w-[24px]" />
          </IconButton>
          <IconButton intent="iconOnly" label="delete link" onClick={() => setConfirmDelete(true)}>
            <MdDeleteOutline className="h-[24px] w-[24px]" />
          </IconButton>
        </>
      ) : (
        <EditLinkForm link={link} onCancel={() => setEditMode(false)} />
      )}
    </div>
  );
}

export function EditLinkForm({ link, onCancel }: { link: LinkModel; onCancel: () => void }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutateAsync: editSessionLink } = useEditSessionLink(link.collectionId);

  const formSchema = z.object({
    url: z.string().url("Please enter a valid url"),
    title: z.string().min(1, "Please enter a valid value (min. 1)"),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      url: link.url,
      title: link.title,
    },
  });

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const values = formSchema.parse(form.getValues());
      await editSessionLink({ link: values, linkId: link.id });
    } catch (e) {
      console.error(`error occured editing link`, e);
    } finally {
      setIsLoading(false);
      onCancel();
    }
  };

  return (
    <Form {...form}>
      <form className="w-full" onSubmit={form.handleSubmit(handleSubmit)}>
        <FormField
          control={form.control}
          name="url"
          render={({ field }) => (
            <FormItem className="pb-3">
              <div className="flex items-center gap-3">
                <FormLabel>URL</FormLabel>
                <FormControl>
                  <Input className="!mt-0 w-full" {...field} />
                </FormControl>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem className="pb-3">
              <div className="flex items-center gap-3">
                <FormLabel>Title</FormLabel>
                <FormControl>
                  <Input className="!mt-0 w-full" {...field} />
                </FormControl>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex justify-end gap-2">
          <Button
            className="w-[80px] px-0"
            intent="outline"
            type="button"
            disabled={isLoading}
            onClick={onCancel}
            label="cancel"
          />
          <Button
            className="w-[80px] px-0"
            intent="green"
            type="submit"
            disabled={isLoading}
            onClick={handleSubmit}
            onSubmit={handleSubmit}
            label="save"
          />
        </div>
      </form>
    </Form>
  );
}

export function AddLinkForm({ sessionId, onCancel }: { sessionId: string; onCancel: () => void }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutateAsync: createSessionLink } = useCreateSessionLink(sessionId);

  const formSchema = z.object({
    url: z.string().url("Please enter a valid url"),
    title: z.string().min(1, "Please enter a valid value (min. 1)"),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      url: "",
      title: "",
    },
  });

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const values = formSchema.parse(form.getValues());
      await createSessionLink(values);
      onCancel();
    } catch (e) {
      console.error(`error occured editing link`, e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form {...form}>
      <form className="w-full" onSubmit={form.handleSubmit(handleSubmit)}>
        <FormField
          control={form.control}
          name="url"
          render={({ field }) => (
            <FormItem className="pb-3">
              <div className="flex items-center gap-3">
                <FormLabel>URL</FormLabel>
                <FormControl>
                  <Input className="!mt-0 w-full" {...field} />
                </FormControl>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem className="pb-3">
              <div className="flex items-center gap-3">
                <FormLabel>Title</FormLabel>
                <FormControl>
                  <Input className="!mt-0 w-full" {...field} />
                </FormControl>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex justify-end gap-2">
          <Button
            className="w-[80px] px-0"
            intent="outline"
            type="button"
            disabled={isLoading}
            onClick={onCancel}
            label="cancel"
          />
          <Button
            className="w-[80px] px-0"
            intent="green"
            type="submit"
            disabled={isLoading}
            onClick={handleSubmit}
            onSubmit={handleSubmit}
            label="save"
          />
        </div>
      </form>
    </Form>
  );
}
