import * as z from "zod";

export enum Environment {
  development = "development",
  testing = "testing",
  production = "production",
}
const requiredString = z.string().min(1);
const envSchema = z.object({
  REACT_APP_BASE_URL: requiredString,
  REACT_APP_PUBLISHABLE_KEY: requiredString,
  REACT_APP_PUBLIC_POSTHOG_KEY: z.string().optional(),
  REACT_APP_PUBLIC_POSTHOG_HOST: z.string().url().optional(),
  REACT_APP_ENVIRONMENT: z.nativeEnum(Environment).default(Environment.development),
});

export const env = envSchema.parse(process.env);
