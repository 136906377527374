import StretchTouchToes from "./svgr/StretchTouchToes";
import StretchLegPull from "./svgr/StretchLegPull";

export function EmptyDisplay({
  text,
  subtext,
  image = "StretchTouchToes",
}: {
  text: string;
  subtext?: string;
  image?: "StretchLegPull" | "StretchTouchToes";
}) {
  let svgImage;
  switch (image) {
    case "StretchLegPull":
      svgImage = <StretchLegPull width={150} />;
      break;
    default:
      svgImage = <StretchTouchToes width={150} />;
  }
  return (
    <div className="mt-2 flex w-full flex-col items-center justify-center">
      {svgImage}
      <div className="pt-1 font-medium">{text}</div>
      {subtext && <div className="text-sm">{subtext}</div>}
    </div>
  );
}
