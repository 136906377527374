import type { SVGProps } from "react";
const SvgBasketballDunkStick = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="basketball-dunk-stick_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 237.79 237.79"
    {...props}
  >
    <defs>
      <style>
        {
          ".basketball-dunk-stick_svg__cls-5,.basketball-dunk-stick_svg__cls-6{fill:none;stroke:#000;stroke-linejoin:round;stroke-width:4.5px;stroke-linecap:round}.basketball-dunk-stick_svg__cls-6{stroke-width:4.36px}"
        }
      </style>
    </defs>
    <circle
      cx={126.67}
      cy={62.91}
      r={12.5}
      style={{
        fill: "none",
        stroke: "#000",
        strokeWidth: "4.5px",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="m142.67 76.91 18.5-2.5 8-20 5-4-8 29-21 11 1.5 27.5 2 15-9.5 37.5 44 27 1 8-64.5-26.5 1.5-51.5.5-7.5"
      className="basketball-dunk-stick_svg__cls-5"
    />
    <circle
      cx={165.67}
      cy={44.91}
      r={9.5}
      style={{
        strokeWidth: "4.34px",
        fill: "none",
        stroke: "#000",
        strokeLinejoin: "round",
      }}
    />
    <path
      d="m126.67 97.91-15 26-36 14 2-8 25-13 9-25 8-9"
      className="basketball-dunk-stick_svg__cls-5"
    />
    <path
      d="m146.67 140.91 12 18 41 7 5 9-52-4-11-10"
      style={{
        fill: "none",
        stroke: "#000",
        strokeLinejoin: "round",
        strokeWidth: "4.5px",
      }}
    />
    <ellipse
      cx={63.17}
      cy={40.41}
      rx={21.5}
      ry={6.5}
      style={{
        fill: "none",
        stroke: "#000",
        strokeLinejoin: "round",
        strokeWidth: "4.36px",
      }}
    />
    <path
      d="m50.67 68.91-9-28.5M74.67 68.91l10-28.5"
      className="basketball-dunk-stick_svg__cls-6"
    />
  </svg>
);
export default SvgBasketballDunkStick;
