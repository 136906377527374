import { useState } from "react";

export function useMenuOverrides(initial = false) {
  const isTouchDevice = "ontouchstart" in window;
  const [menuOpen, setMenuOpen] = useState(initial);
  const triggerOverride: {
    onPointerDown?: (e: React.PointerEvent<HTMLButtonElement>) => void;
    onClick?: () => void;
  } = isTouchDevice
    ? {
        onPointerDown: e => e.preventDefault(),
        onClick: () => setMenuOpen(!menuOpen),
      }
    : {};

  const menuOverride: { open: boolean; onOpenChange: (open: boolean) => void } = {
    open: menuOpen,
    onOpenChange: (menuOpen: boolean) => setMenuOpen(menuOpen),
  };

  return { menuOverride, triggerOverride };
}
