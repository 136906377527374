import { useState } from "react";
import { IconButton, IconButtonCva } from "../../components/Button";
import { GameEventType } from "../../lib/data/models";
import { StatLabels } from "../../lib/data/stats";
import { Three, FieldGoal, Miss, Rb, Ast, St, To, Blk, Foul } from "../../components/svgr/stats";
import { LoadingSpinner } from "../../components/Spinner";

interface StatButtonProps {
  disabled: boolean;
  stat: GameEventType;
  onMouseDown: () => void;
}

const getStatDetails = (
  stat: GameEventType,
): { icon?: JSX.Element; bgColor: IconButtonCva["bgColor"] } => {
  switch (stat) {
    case "basketball_score_three_pointer":
      return {
        icon: <Three aria-label="three pointer" width={24} height={24} />,
        bgColor: "green",
      };
    case "basketball_score_field_goal":
      return {
        icon: <FieldGoal aria-label="score" width={24} height={24} />,
        bgColor: "green",
      };
    case "basketball_miss":
      return {
        icon: <Miss aria-label="miss" width={24} height={24} />,
        bgColor: "red",
      };
    case "basketball_rebound":
      return {
        icon: <Rb aria-label="rebound" width={24} height={24} />,
        bgColor: "green",
      };
    case "basketball_assist":
      return {
        icon: <Ast aria-label="assist" width={24} height={24} />,
        bgColor: "green",
      };
    case "basketball_steal":
      return {
        icon: <St aria-label="steal" width={24} height={24} />,
        bgColor: "green",
      };
    case "basketball_turnover":
      return {
        icon: <To aria-label="turnover" width={24} height={24} />,
        bgColor: "red",
      };
    case "basketball_block":
      return {
        icon: <Blk aria-label="block" width={24} height={24} />,
        bgColor: "green",
      };
    case "basketball_foul":
      return {
        icon: <Foul aria-label="foul" width={24} height={24} />,
        bgColor: "red",
      };
    case "basketball_free_throw_score":
      return {
        bgColor: "green",
      };
    case "basketball_free_throw_miss":
      return {
        bgColor: "red",
      };
    default:
      return { icon: null, bgColor: null } as never;
  }
};

export function StatButton({ disabled, stat, onMouseDown }: StatButtonProps) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const on = async () => {
    setLoading(true);
    try {
      await onMouseDown();
    } finally {
      setLoading(false);
    }
  };

  const iconButtonDetails = getStatDetails(stat);

  return (
    <IconButton
      disabled={disabled || isLoading}
      intent="stats"
      bgColor={iconButtonDetails.bgColor}
      label={StatLabels[stat]}
      onMouseDown={on}
      className={"min-h-9 min-w-9"}
    >
      {iconButtonDetails.icon}
      {isLoading && <LoadingSpinner className="absolute h-[24px] w-[24px]" />}
    </IconButton>
  );
}
