import _ from "lodash";
import { GameEvent, PlayerWithDetails } from "../data/models";
import { fullName } from "./format";

export const sortPlayers = (players: PlayerWithDetails[]): PlayerWithDetails[] =>
  _.sortBy(players, p => fullName(p.user).toLowerCase());

export type sortOrder = "asc" | "desc";

export const sortEventByCreatedAt = (order: sortOrder) => {
  if (order === "asc") {
    return (a: GameEvent, b: GameEvent) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    };
  } else {
    return (a: GameEvent, b: GameEvent) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    };
  }
};
