import { GameState } from "../../lib/data/models";
import { Link } from "react-router-dom";
import { classNames } from "../../lib/utils/classNames";
import { useGame } from "../../lib/data/game";
import { LoadingSpinner } from "../../components/Spinner";
import { GameEllipsis } from "../pages/GamePage";
import { FaCaretLeft, FaChevronRight } from "react-icons/fa";
import { GameBadges } from "../../components/Badges";
import { usePlayersByGame } from "../../lib/data/players";
import { useGameScore } from "../../lib/data/stats";
import { AvatarListToStack } from "../../components/avatar/AvatarListToStack";

export const gameStateMap: {
  [key in GameState]: { textColor: string; bgColor: string; label: string };
} = {
  pending: {
    textColor: "text-yellow-700",
    bgColor: "bg-yellow-700",
    label: "pending",
  },
  active: {
    textColor: "text-emerald-600",
    bgColor: "bg-emerald-600",
    label: "live",
  },
  complete: {
    textColor: "text-grey-dark",
    bgColor: "bg-grey-dark",
    label: "final",
  },
  cancelled: {
    textColor: "text-red-600",
    bgColor: "bg-red-600",
    label: "cancelled",
  },
};

export function GameCard({ gameId, expanded = false }: { gameId: string; expanded?: boolean }) {
  const { data: game } = useGame(gameId);
  const { data: playersData } = usePlayersByGame(gameId);
  const { data: score = { homeTeam: 0, awayTeam: 0 } } = useGameScore(gameId);
  if (!game || !playersData) return <LoadingSpinner />;

  return (
    <div className="relative h-fit border-x border-b border-gray-300">
      <div
        className={classNames(
          "sticky top-[calc(var(--header-height-default)+var(--game-list-config-height-default))] z-30 flex w-full justify-between border-y border-gray-300 bg-white px-2.5 py-1 md:top-[calc(var(--header-height-md)+var(--game-list-config-height-default))]",
          gameStateMap[game.gameState].textColor,
        )}
      >
        <span className="flex items-center gap-2">
          <span className="font-bold">{game.title}</span>
          {game.gameState === "active" && (
            <div className="h-2 w-2 animate-pulse rounded-full bg-emerald-500"></div>
          )}
        </span>
        <GameEllipsis gameId={gameId} hideOptions={["showStats"]} className="fill-black" />
      </div>
      <div className="flex">
        <div className="flex flex-1 flex-col gap-2.5 border-r border-gray-300 pb-4 pt-2.5">
          <div
            className={classNames(
              "flex items-center justify-between px-2.5 py-1",
              expanded ? "items-start" : "",
            )}
          >
            <div>
              <div className="text-left font-sans font-medium">Team 1</div>
              <AvatarListToStack
                open={expanded}
                showNumberOfUsers={5}
                users={playersData.homeTeam.map(player => player.user)}
              />
            </div>
            <div className={classNames("relative mt-2 text-4xl font-bold", expanded ? "mt-2" : "")}>
              {game.gameState === "pending" ? "-" : score.homeTeam}
              {game.gameState === "complete" && score.awayTeam < score.homeTeam && (
                <FaCaretLeft className="absolute bottom-0 right-[-15px] top-0 m-auto" size="14" />
              )}
            </div>
          </div>

          <div
            className={classNames(
              "flex items-center justify-between px-2.5 py-1",
              expanded ? "items-start" : "",
            )}
          >
            <div>
              <div className="text-left font-sans font-medium">Team 2</div>
              <AvatarListToStack
                open={expanded}
                showNumberOfUsers={5}
                users={playersData.awayTeam.map(player => player.user)}
              />
            </div>
            <div className={classNames("relative mt-2 text-4xl font-bold", expanded ? "mt-2" : "")}>
              {game.gameState === "pending" ? "-" : score.awayTeam}
              {game.gameState === "complete" && score.awayTeam > score.homeTeam && (
                <FaCaretLeft className="absolute bottom-0 right-[-15px] top-0 m-auto" size="14" />
              )}
            </div>
          </div>
        </div>
        <Link
          className="group flex flex-col justify-center p-2.5 align-middle before:absolute before:inset-0 before:z-10 before:content-['']"
          to={`/game/${game.id}`}
        >
          <span
            className={classNames(
              "font-medium group-hover:underline",
              gameStateMap[game.gameState].textColor,
            )}
          >
            {gameStateMap[game.gameState].label}
          </span>
          <span className="flex flex-row items-center justify-start gap-2 text-sm group-hover:underline">
            View
            <FaChevronRight className="float-right" size="15" />
          </span>
        </Link>
      </div>

      <div className="flex justify-end border-gray-300 [&:has(span)]:border-t">
        <GameBadges gameId={gameId} />
      </div>
    </div>
  );
}
