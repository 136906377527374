import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * @description This function removes duplicates and merges class values
 * @returns {string}
 */
export function classNames(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs));
}
