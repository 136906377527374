import { usePostHog } from "posthog-js/react";
import { useMe } from "./data/user";
import { useEffect } from "react";
import { fullName } from "./utils/format";
import { AppUser } from "./data/models";

export function useAnalytics() {
  const { data: me } = useMe();
  const posthog = usePostHog();

  const logPageView = () => {
    posthog.capture("$pageview");
  };
  const reset = () => {
    posthog.reset();
  };

  const identify = (me: AppUser) =>
    posthog.identify(me.id, {
      email: me.email,
      name: fullName(me),
    });

  useEffect(() => {
    if (me) {
      // TODO: reuse the fn above, and fix dependency warnings
      posthog.identify(me.id, {
        email: me.email,
        name: fullName(me),
      });
    }
  }, [me, posthog]);

  return { logPageView, reset, identify };
}
