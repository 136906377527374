import { classNames } from "../../lib/utils/classNames";
import { Nameable } from "../../lib/utils/types";
import { UserAvatar } from "./UserAvatar";

interface AvatarListProps {
  users: (Nameable & { id: string; imageUrl: string })[];
  className?: string;
  onClick?: () => void;
  truncate?: boolean;
}
export function AvatarList({ className = "", users, onClick, truncate = false }: AvatarListProps) {
  if (users.length === 0) {
    return <span>0 players...</span>;
  }
  return (
    <div className={classNames("flex flex-col items-start gap-2", className)}>
      {users.map(user => (
        <UserAvatar truncate={truncate} key={user.id} user={user} onClick={onClick} showFullName />
      ))}
    </div>
  );
}
