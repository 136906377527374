import { Link } from "react-router-dom";
import { Button } from "../../components/Button";
import { ContentContainer } from "../../components/ContentContainer";
import { PageContainer } from "../../components/PageContainer";

interface ErrorProps {
  message?: string;
  returnRoute?: string;
}
export function ErrorPage(props: ErrorProps) {
  return (
    <PageContainer>
      <ContentContainer>
        <ErrorContent {...props} />
      </ContentContainer>
    </PageContainer>
  );
}

export function ErrorContent({ message, returnRoute }: ErrorProps) {
  return (
    <div className="flex flex-col items-center gap-1 p-4">
      <div className="heading-1 text-center">Oops! Something went wrong.</div>
      {message ? <div>{message}</div> : <div>Please try again later.</div>}
      <Link className="mt-3" to={returnRoute ?? "/"}>
        <Button intent="green" label="take me home" />
      </Link>
    </div>
  );
}
