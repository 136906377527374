import { useToast } from "../../components/ui/use-toast";

export function usePaste() {
  const { toast } = useToast();
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast({ title: "Success!", description: "Copied to clipboard." });
  };
  return copyToClipboard;
}
