import { classNames } from "../lib/utils/classNames";

export const LoadingSpinner = ({ className = "" }: { className?: string }) => (
  <div
    className={classNames(
      "m-auto h-20 w-20 animate-spin rounded-full border-8 border-grey border-t-green",
      className,
    )}
  />
);
