import { classNames } from "../../lib/utils/classNames";
import { fullName } from "../../lib/utils/format";
import { Nameable } from "../../lib/utils/types";
import { UserAvatar } from "./UserAvatar";

interface AvatarStackProps {
  users: (Nameable & { id: string; imageUrl: string })[];
  showNumberOfUsers?: number;
  className?: string;
  onClick?: () => void;
}

const noAddedPlayers = <span className="text-sm">0 players...</span>;

export function AvatarStack({
  users,
  showNumberOfUsers = 5,
  className = "",
  onClick,
}: AvatarStackProps) {
  const usersToShow = users.slice(0, showNumberOfUsers);
  const countOfHiddenUsers = users.length - usersToShow.length;
  return (
    <div className={classNames("flex flex-row items-center", className)}>
      {users.length === 0
        ? noAddedPlayers
        : usersToShow.map((user, index) => (
            <UserAvatar
              user={user}
              className={`h-[30px] w-[30px] shadow-avatar md:h-10 md:w-10`}
              key={user.id}
              style={{
                zIndex: usersToShow.length - index,
                marginRight: "-12px",
              }}
              title={fullName(user)}
              onClick={onClick}
            />
          ))}
      {countOfHiddenUsers > 0 && (
        <span className="text-grey-darker relative ms-4 text-sm">+{countOfHiddenUsers}</span>
      )}
    </div>
  );
}
