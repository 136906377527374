import { GrAddCircle, GrFormSearch } from "react-icons/gr";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { useState } from "react";
import { CreateSessionPlayerForm } from "./CreateSessionPlayerForm";
import { SearchPlayers } from "./SearchSessionPlayers";
import { UserAvatar } from "../../components/avatar/UserAvatar";
import { Button, IconButton } from "../../components/Button";
import {
  useAddPlayerToSession,
  useRemovePlayerFromSession,
  useSession,
} from "../../lib/data/session";
import { usePlayersBySession } from "../../lib/data/players";
import { sortPlayers } from "../../lib/utils/sort";
import { IconWithConfirmation } from "../../components/IconWithConfirmation";
import { ModalSessionSubheader } from "../../components/ModalSessionSubheader";
import { useSuggestedPlayers } from "../../lib/data/game";

type View = "list" | "new" | "search";

export function EditSessionPlayers({ sessionId }: { sessionId: string }) {
  const [view, setView] = useState<View>("list");
  const { data: players = [] } = usePlayersBySession(sessionId);
  const { data: session } = useSession(sessionId);
  const { data: suggestedPlayers = [] } = useSuggestedPlayers(sessionId);

  const { mutateAsync: removePlayer } = useRemovePlayerFromSession(sessionId);
  const { mutateAsync: addPlayerToSession } = useAddPlayerToSession(sessionId);

  if (!session) {
    return <div>Not Found</div>;
  }

  const sortedPlayers = sortPlayers(players);

  const tipMessage = () => {
    switch (view) {
      case "list":
        return (
          <span className="px-2.5 py-1.5 text-sm sm:px-5">
            Below is your session's player roster. Click to add or remove.
          </span>
        );
      case "search":
        return (
          <span className="px-2.5 pt-1.5 text-sm sm:px-5">
            Search for an existing player by their registered email.
          </span>
        );
      case "new":
        return (
          <span className="px-2.5 py-1.5 text-sm sm:px-5">
            If the player isn't found, you can temporarily include a new player.
          </span>
        );
    }
  };

  const description = () => {
    switch (view) {
      case "list":
        return <div className="text-md font-medium leading-none">Players ({players.length})</div>;
      case "search":
        return <div className="text-md font-medium leading-none">Search Players</div>;
      case "new":
        return <div className="text-md font-medium leading-none">Add New Player</div>;
    }
  };

  const body = () => {
    switch (view) {
      case "list":
        return (
          <div className="min-h-0 flex-1 overflow-y-scroll px-6 pb-3 hover:shadow-inner">
            <div className="mt-3 flex flex-col items-start gap-3">
              {sortedPlayers.length > 0 ? (
                sortedPlayers.map(player => (
                  <div
                    key={player.id}
                    className="flex w-full flex-row items-center justify-between py-0.5 hover:bg-grey-light"
                  >
                    <UserAvatar key={player.id} user={player.user} showFullName />
                    <div className="flex flex-row gap-2">
                      <IconWithConfirmation
                        className=""
                        confirmationText="remove"
                        onConfirm={async () => {
                          try {
                            await removePlayer(player.id);
                          } catch {}
                        }}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <span>
                  Session Empty! Let's Fill it Up with Players.{" "}
                  <Button intent="inline" size="inline" label="Add" onClick={() => toggle("new")} />{" "}
                  or{" "}
                  <Button
                    intent="inline"
                    size="inline"
                    label="Search"
                    onClick={() => toggle("search")}
                  />{" "}
                  for Existing Ones Now!
                </span>
              )}
            </div>
          </div>
        );
      case "search":
        return (
          <SearchPlayers
            suggestedPlayers={suggestedPlayers}
            existingPlayers={players.map(p => p.id)}
            onAdd={player => addPlayerToSession(player.id)}
          />
        );
      case "new":
        return (
          <div className="flex flex-1 animate-fade flex-col">
            <CreateSessionPlayerForm
              sessionId={sessionId}
              onComplete={() => setView("list")}
              onCancel={() => setView("list")}
            />
          </div>
        );
    }
  };

  const icons = (
    <div role="list" className="flex flex-row items-center">
      <IconButton
        data-active={view === "list"}
        role="listitem"
        label="Session player list"
        intent="iconTab"
        onClick={() => toggle("list")}
      >
        <MdOutlinePeopleAlt
          aria-hidden="true"
          className="h-[24px] w-[24px]"
          fill={view === "list" ? "#007163" : "currentColor"}
        />
      </IconButton>
      <IconButton
        data-active={view === "search"}
        role="listitem"
        label="Player search"
        intent="iconTab"
        onClick={() => toggle("search")}
      >
        <GrFormSearch
          aria-hidden="true"
          className="h-[30px] w-[30px]"
          stroke={view === "search" ? "#007163" : "currentColor"}
        />
      </IconButton>
      <IconButton
        data-active={view === "new"}
        role="listitem"
        label="Add New Player"
        intent="iconTab"
        onClick={() => toggle("new")}
      >
        <GrAddCircle
          aria-hidden="true"
          className="h-[24px] w-[24px]"
          stroke={view === "new" ? "#007163" : "currentColor"}
        />
      </IconButton>
    </div>
  );

  const toggle = (newView: View) => {
    if (view !== newView) {
      setView(newView);
    }
  };

  return (
    <div className="flex min-h-0 flex-1 flex-col">
      <ModalSessionSubheader session={session} />
      <div className="flex flex-1 flex-col overflow-auto">
        <div className="flex flex-row items-end justify-between px-2.5 pt-2.5 sm:px-5">
          {description()}
          {icons}
        </div>
        {tipMessage()}
        {body()}
      </div>
    </div>
  );
}
