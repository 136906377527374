import type { SVGProps } from "react";
const SvgMiss = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="miss_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 50 50"
    {...props}
  >
    <defs>
      <style>
        {".miss_svg__cls-1{fill:none;stroke:#212121;stroke-linecap:round;stroke-linejoin:round}"}
      </style>
    </defs>
    <g id="miss_svg__Brick">
      <path
        d="m47.65 27.57-.69-.38c.35-.23.7-.47.7-.47.25-.14.24-.46.26-.71 0 0 .34-4.99.4-5.94v-.31c-.02-.72-.61-1.31-1.3-1.37L30.5 13.23c-.28-.09-.57-.09-.85-.01L3.43 20.56s-.02 0-.03.01c-.04.01-.07.03-.1.04-.08.03-.15.06-.22.1-.02 0-.03.01-.05.02s-.03.03-.05.04c-.07.05-.13.1-.19.16-.02.02-.05.04-.07.07-.08.08-.14.17-.2.27 0 .01-.01.03-.02.04-.04.09-.08.18-.11.27 0 .03-.02.07-.02.1-.02.08-.03.16-.03.25v10.55c0 .55.3 1.06.79 1.32l.91.49c.4.21.87.24 1.29.06l.08-.03.61.85c.13.19.31.34.51.45l9.76 5.26c.39.44 1.06.64 1.65.36 10.07-3.23 20.16-6.6 30.12-9.98.09-1.16.03-2.28-.15-3.35a.46.46 0 0 0-.25-.35ZM30.03 16.23l11.57 3.61c-7.49 2.14-21.17 6.06-24 6.86l-9.43-4.35 21.87-6.12ZM7.15 31.62c-.41-.57-1.15-.79-1.8-.52h-.02v-6.76l10.63 4.91v8.07l-7.72-4.16-1.1-1.54Zm11.82 6.17v-8.36l26.5-7.57c.12 1.76.26 3.88.27 4.04-.32.63-.69 1.31-.43 1.75l.02.03.36.76c-.11.41-.14.73-.07 1.07-8.96 2.7-17.8 5.44-26.65 8.28"
        style={{
          fill: "#212121",
          strokeWidth: 0,
        }}
      />
      <ellipse cx={17.92} cy={22.44} className="miss_svg__cls-1" rx={2.08} ry={0.89} />
      <ellipse cx={24.4} cy={20.66} className="miss_svg__cls-1" rx={2.08} ry={0.89} />
      <ellipse cx={30.88} cy={18.89} className="miss_svg__cls-1" rx={2.08} ry={0.89} />
    </g>
  </svg>
);
export default SvgMiss;
