import { useEditSession, useSession } from "../../lib/data/session";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { format, formatISO } from "date-fns";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/Button";
import { TextArea } from "../../components/ui/textarea";

export function EditSession({
  sessionId,
  onCancel,
  onComplete,
}: {
  sessionId: string;
  onCancel: () => void;
  onComplete: () => void;
}) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const { data: session } = useSession(sessionId);
  const { mutateAsync: editSession } = useEditSession(sessionId);

  const formSchema = z.object({
    title: z.string().min(3, "Please enter a session name"),
    description: z.string().max(500, `Please enter less than 500 characters`).optional(),
    startDate: z.string().min(1, "Please enter a date/time"),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      title: session?.title,
      description: session?.description,
      startDate: format(session?.startDate ?? new Date(), "yyyy-MM-dd HH:mm"),
    },
  });

  if (!session) {
    return <div>Not Found</div>;
  }

  const onSubmit = async () => {
    setLoading(true);
    try {
      const values = formSchema.parse(form.getValues());
      const formattedStartDate = formatISO(new Date(values.startDate));
      await editSession({ ...values, startDate: formattedStartDate });
      onComplete();
    } catch (e) {
      console.error(`form error: ${JSON.stringify(e)}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex h-[calc(100%-53px)] flex-col justify-between"
      >
        <div className="overflow-auto px-2.5 py-8 sm:px-5">
          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem className="pb-3">
                <FormLabel>Session Name</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="startDate"
            render={({ field }) => (
              <FormItem className="pb-3">
                <FormLabel>Date & Time</FormLabel>
                <FormControl>
                  <Input
                    type="datetime-local"
                    placeholder="Time"
                    {...field}
                    onChange={e => {
                      try {
                        const formatted = format(new Date(e.target.value), "yyyy-MM-dd HH:mm");
                        field.onChange(formatted);
                      } catch (e) {
                        field.onChange(() => "");
                      }
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem className="pb-3">
                <FormLabel>Notes</FormLabel>
                <FormControl>
                  <TextArea placeholder="500 character limit..." {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex w-full gap-4 border-t px-2.5 py-5 shadow-above sm:px-5">
          <Button
            className="w-full md:max-w-full"
            intent="outline"
            type="button"
            disabled={isLoading}
            onClick={onCancel}
            label="cancel"
          />
          <Button
            className="w-full md:max-w-full"
            intent="green"
            disabled={isLoading}
            type="submit"
            onClick={onSubmit}
            label="save"
          />
        </div>
      </form>
    </Form>
  );
}
