import { useAuth, useUser } from "@clerk/clerk-react";
import { initials } from "../lib/utils/format";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import { GoogleSignInButton } from "../views/auth/SignIn";
import { Avatar, AvatarImage } from "./ui/avatar";
import { AvatarFallback } from "@radix-ui/react-avatar";
import { Link, useLocation } from "react-router-dom";
import { LogoStacked } from "./svgr";
import { useAnalytics } from "../lib/analytics";
import { classNames } from "../lib/utils/classNames";
import { useMe } from "../lib/data/user";

export function Header({ stickyHeader = true }: { stickyHeader?: boolean }) {
  return (
    <div
      className={classNames(
        "top-0 z-50 flex min-w-fit flex-row items-center justify-between border-b border-[#f1f5f9] bg-white p-[var(--header-padding-default)] sm:w-full md:px-5",
        stickyHeader ? "sticky" : "",
      )}
    >
      <div className="items-center">
        <Link to="/dashboard">
          <LogoStacked
            className="h-[var(--logo-height-default)] fill-green md:h-[var(--logo-height-md)]"
            aria-label="logo"
          />
        </Link>
      </div>
      <div className="align-center flex flex-row gap-4">
        <UserIcon />
      </div>
    </div>
  );
}

function UserIcon() {
  const { reset } = useAnalytics();
  const { user } = useUser();
  const { data: me } = useMe();
  const { pathname } = useLocation();
  const userInitials = initials(user?.fullName ?? "");

  const auth = useAuth();
  const onSignOut = () => {
    reset();
    auth.signOut();
  };
  if (!me || !user) {
    return <GoogleSignInButton previousUrl={pathname} />;
  }
  const avatarDropdown = (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Avatar>
          <AvatarImage src={user.imageUrl} />
          <AvatarFallback>{userInitials}</AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-white-custom">
        <DropdownMenuLabel>{user.fullName}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {me.player && (
          <Link to={`/p/${me.player.id}`}>
            <DropdownMenuItem>my sessions</DropdownMenuItem>
          </Link>
        )}
        <DropdownMenuItem onClick={onSignOut}>sign out</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
  return <div className="z-10 flex flex-row gap-2">{avatarDropdown}</div>;
}
