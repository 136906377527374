import { UseQueryOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../api";
import { PlayerWithDetails, TeamPlayer, TeamsOfPlayers } from "./models";
import { queryKeys } from "./queryKeys";
import { MutationConfig, MutationResult, QueryConfig } from "../utils/types";

export function usePlayer(playerId: string) {
  const { run } = useApi();
  async function getPlayer() {
    return await run<PlayerWithDetails>({
      method: "GET",
      path: `/players/${playerId}`,
    });
  }
  const query = {
    queryKey: queryKeys.players.detail(playerId),
    queryFn: getPlayer,
    staleTime: 60000,
  };
  return useQuery(query);
}

export function usePlayersBySession(sessionId: string, config?: QueryConfig<PlayerWithDetails[]>) {
  const { run } = useApi();
  async function getPlayersBySession() {
    return await run<PlayerWithDetails[]>({
      method: "GET",
      path: `/collections/${sessionId}/players`,
    });
  }
  const query: UseQueryOptions<PlayerWithDetails[]> = {
    queryKey: queryKeys.session.players(sessionId),
    queryFn: getPlayersBySession,
    staleTime: 10000,
    ...config,
  };
  return useQuery(query);
}

export function usePlayersByGame(gameId: string) {
  const { run } = useApi();
  async function getPlayersByGame(): Promise<TeamsOfPlayers> {
    return await run<TeamsOfPlayers>({
      method: "GET",
      path: `/games/${gameId}/players`,
    });
  }
  const query = {
    queryKey: queryKeys.games.players(gameId),
    queryFn: getPlayersByGame,
    staleTime: 60000,
  };
  return useQuery(query);
}

type SearchPlayersQuery = UseQueryOptions<PlayerWithDetails[]>;
export function useSearchPlayers(
  { search, limit = 25 }: { search: string; limit?: number },
  config?: Omit<SearchPlayersQuery, "queryFn" | "queryKey">,
) {
  const { run } = useApi();
  async function getPlayersBySearch(): Promise<PlayerWithDetails[]> {
    return await run<PlayerWithDetails[]>({
      method: "GET",
      path: `/players`,
      params: {
        search,
        limit,
      },
    });
  }
  const query: SearchPlayersQuery = {
    ...config,
    queryKey: queryKeys.players.search(search, limit),
    queryFn: getPlayersBySearch,
  };
  return useQuery(query);
}

export function useCreatePlayer(
  config?: MutationConfig<PlayerWithDetails, typeof createPlayer>,
): MutationResult<PlayerWithDetails, typeof createPlayer> {
  const queryClient = useQueryClient();
  const { run } = useApi();
  async function createPlayer(payload: {
    firstName: string;
    lastName: string;
    collectionId?: string;
    email?: string;
    nickname?: string;
  }) {
    return await run<PlayerWithDetails>({
      method: "POST",
      path: `/players`,
      body: payload,
    });
  }
  return useMutation({
    ...config,
    mutationFn: createPlayer,
    onSettled: async (_, __, { collectionId }) => {
      if (collectionId) {
        // if the player was created within a session
        await queryClient.invalidateQueries({
          queryKey: queryKeys.session.players(collectionId),
        });
      }
    },
  });
}

export function useAddTeamPlayer(
  gameId: string,
  config?: MutationConfig<TeamPlayer, typeof addTeamPlayer>,
) {
  const queryClient = useQueryClient();
  const { run } = useApi();
  async function addTeamPlayer({ playerId, teamId }: { teamId: string; playerId: string }) {
    return run<TeamPlayer>({
      method: "POST",
      path: `/teams/${teamId}/players`,
      body: { playerId, teamId, gameId },
    });
  }
  return useMutation({
    ...config,
    mutationFn: addTeamPlayer,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.games.players(gameId) });
      queryClient.invalidateQueries({ queryKey: queryKeys.games.stats(gameId) });
    },
  });
}

export function useRemoveTeamPlayer(
  gameId: string,
  config?: MutationConfig<TeamPlayer, typeof removeTeamPlayer>,
) {
  const queryClient = useQueryClient();
  const { run } = useApi();
  async function removeTeamPlayer({ playerId, teamId }: { teamId: string; playerId: string }) {
    return run<TeamPlayer>({
      method: "DELETE",
      path: `/teams/${teamId}/players/${playerId}`,
    });
  }
  return useMutation({
    ...config,
    mutationFn: removeTeamPlayer,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.games.players(gameId) });
      queryClient.invalidateQueries({ queryKey: queryKeys.games.stats(gameId) });
    },
  });
}
