import type { SVGProps } from "react";
const SvgRunningStickYellow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="running-stick-yellow_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 75 75"
    {...props}
  >
    <defs>
      <style>
        {
          ".running-stick-yellow_svg__cls-1{stroke-linecap:round;stroke-linejoin:round;fill:none;stroke:#000;stroke-width:1.32px}"
        }
      </style>
    </defs>
    <path
      d="M56.88 19.44c16.46 15.63 15.45 36.22 1.13 43.91s-35.56 10.74-40.13.69 12.93-11.3 8.18-27.09S9.1 21.55 11.58 10.34s28.83-6.53 45.3 9.1"
      style={{
        fill: "#ffb700",
        strokeWidth: 0,
      }}
    />
    <g id="running-stick-yellow_svg__Layer_1-2" data-name="Layer 1-2">
      <g id="running-stick-yellow_svg__Running_Figure" data-name="Running Figure">
        <circle
          cx={48.28}
          cy={21.22}
          r={3.3}
          style={{
            fill: "none",
            stroke: "#000",
            strokeWidth: "1.32px",
            strokeMiterlimit: 10,
          }}
        />
        <path
          d="m43.55 24.98-3.05-.17-10.2 4.88 5.93 8.45 1.57-1.39-3.14-6.02 3.23-1.13M46.34 26.55l-1.22 6.36-3.22 5.93 7.75 8.28 2.71 12.29M34.49 39.8c0 .74 1.39 2.61 1.39 2.61l9.59 5.93 4.18 11.16"
          className="running-stick-yellow_svg__cls-1"
        />
        <path
          d="m46.78 34.39 6.88.35-.78 2.01-7.67-.09M33.79 42.85l-2.7 2.61-10.29-.96-2.26 1.66 14.55 3.75 5.93-5.23M13.74 26.64h10.29M17.14 32.3h6.63M29.26 59.58H60.2"
          className="running-stick-yellow_svg__cls-1"
        />
      </g>
    </g>
  </svg>
);
export default SvgRunningStickYellow;
