import { Helmet } from "react-helmet-async";

const defaultTitle = "SomeDubs | Game, Stats, Victory: Recreational Sports Stat Tracking Made Easy";
const defaultDescription =
  "Empower your game with our intuitive sports stat tracking platform. From basketball to soccer, monitor player performance, analyze trends, and elevate your team to victory. Get started today!";

// Note: Looks like these don't get picked up by web crawlers since we are doing Client Side Rendering.
// Will need to use a prerender service or hopefully Netlify provides a free pre rendering service.
export const AddMetaTags = ({ title = defaultTitle, description = defaultDescription }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="keywords" content="Stat Tracking, basketball, recreational sports" />
      <meta name="description" content={description} />
      <meta property="og:title" content="SomeDubs | Game, Stats, Victory!" />
      <meta
        property="og:description"
        content="Empower your game with our intuitive sports stat tracking platform. From basketball to soccer, monitor player performance, analyze trends, and elevate your team to victory. Get started today!"
      />
      <meta property="og:image" content="/og-image.png" />
      <meta property="og:image:url" content="/og-image.png" />
      <meta property="og:type" content="website" />
      {/* <meta property="og:url" content="ourwebsitelink.." /> */}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
    </Helmet>
  );
};
