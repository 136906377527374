import { useParams } from "react-router-dom";
import { NotFoundPage } from "./NotFound";
import { PageContainer } from "../../components/PageContainer";
import { CreateGameView } from "../games/CreateGameView";
import { ContentContainer } from "../../components/ContentContainer";
import { SessionInformation } from "../sessions/SessionInfo";

export function CreateGamePage() {
  const { id } = useParams<{ id: string }>();
  if (!id) return <NotFoundPage returnRoute="/dashboard" />;
  return (
    <PageContainer>
      <CreateGamePageContent sessionId={id} />
    </PageContainer>
  );
}

function CreateGamePageContent({ sessionId }: { sessionId: string }) {
  return (
    <ContentContainer className="pt-3">
      <div className="min-w-sm max-w-xl">
        <SessionInformation sessionId={sessionId} />
      </div>
      <CreateGameView sessionId={sessionId} />
    </ContentContainer>
  );
}
