import { PlayerWithDetails } from "../lib/data/models";
import { fullName } from "../lib/utils/format";
import { sortPlayers } from "../lib/utils/sort";
import { EmptyDisplay } from "./EmptyDisplay";
import { IconWithConfirmation } from "./IconWithConfirmation";
import { UserAvatar } from "./avatar/UserAvatar";

export function PlayersListWithRemove({
  players,
  onRemove,
}: {
  players: PlayerWithDetails[];
  onRemove?: (player: PlayerWithDetails) => void;
}) {
  const sortedPlayers = sortPlayers(players);
  return (
    <div className="min-h-0 flex-1 overflow-y-auto px-6 pb-3 hover:shadow-inner">
      <div className="mt-3 flex flex-col items-start gap-3">
        {sortedPlayers.length === 0 && (
          <EmptyDisplay
            image="StretchLegPull"
            text="No users yet."
            subtext="Dream team on its way!"
          />
        )}
        {sortedPlayers.map(player => (
          <div
            key={player.id}
            className="flex w-full flex-row items-center justify-between py-0.5 pr-1 hover:bg-grey-light"
          >
            <div className="flex min-w-0 flex-row items-center gap-3">
              <UserAvatar key={player.id} user={player.user} />
              <div className="flex flex-col truncate">
                <div className="truncate">{fullName(player.user)}</div>
                <div className="truncate">{player.user.email}</div>
              </div>
            </div>
            {onRemove && (
              <IconWithConfirmation confirmationText="remove" onConfirm={() => onRemove(player)} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
