import { useParams } from "react-router-dom";
import { NotFoundPage } from "./NotFound";
import { PageContainer } from "../../components/PageContainer";
import { ContentContainer } from "../../components/ContentContainer";
import { useSessionsForPlayer } from "../../lib/data/session";
import { SessionsList } from "../sessions/SessionsList";
import { FolderTabs, TabsConfig } from "../../components/ui/folder-tabs";
import { usePlayer } from "../../lib/data/players";
import { fullName } from "../../lib/utils/format";
import { LoadingSpinner } from "../../components/Spinner";
import { UserAvatar } from "../../components/avatar/UserAvatar";

export function PlayerPage() {
  const { id } = useParams();
  if (!id) return <NotFoundPage />;
  return (
    <PageContainer>
      <ContentContainer>
        <PlayerPageContent playerId={id} />
      </ContentContainer>
    </PageContainer>
  );
}

export function PlayerPageContent({ playerId }: { playerId: string }) {
  const { data: sessions = [] } = useSessionsForPlayer(playerId);
  const { data: player } = usePlayer(playerId);
  const tabsConfig: TabsConfig = {
    ariaLabel: "session tab list",
    defaultValue: "SESSIONS",
    tabs: [
      {
        value: "SESSIONS",
        content: <SessionsList sessions={sessions} />,
      },
    ],
    bgColor: "white",
  };
  if (!player) return <LoadingSpinner />;
  return (
    <div>
      <div className="flex flex-row items-center gap-4">
        <UserAvatar user={player.user} />
        <div className="heading-1 my-4">{fullName(player.user)}</div>
      </div>
      <FolderTabs tabsConfig={tabsConfig} />
    </div>
  );
}
