import { useState, useEffect, useMemo, useCallback } from "react";
import { debounce } from "lodash";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/Button";
import { fullName } from "../../lib/utils/format";
import { UserAvatar } from "../../components/avatar/UserAvatar";
import { usePlayersBySession, useSearchPlayers } from "../../lib/data/players";
import { PlayerWithDetails } from "../../lib/data/models";
import { sortPlayers } from "../../lib/utils/sort";
import { classNames } from "../../lib/utils/classNames";
import { LoadingSpinner } from "../../components/Spinner";

interface SearchPlayersProps {
  existingPlayers: string[];
  suggestedPlayers?: PlayerWithDetails[];
  onAdd: (player: PlayerWithDetails) => void;
  addedText?: string;
  registeredOnly?: boolean;
  className?: string;
}

export function SearchPlayers({
  onAdd,
  existingPlayers,
  suggestedPlayers = [],
  addedText = "added",
  registeredOnly = false,
  className,
}: SearchPlayersProps) {
  const [search, setSearch] = useState<string>("");
  const [isTyping, setIsTyping] = useState(false);
  const {
    data: players = [],
    refetch,
    isLoading,
  } = useSearchPlayers({ search }, { enabled: false });

  const searchPlayers = useCallback((): void => {
    refetch();
    setIsTyping(false);
  }, [refetch]);

  const debouncedSearch = useMemo(() => debounce(searchPlayers, 300), [searchPlayers]);

  useEffect(() => {
    if (search.length === 0) {
      setIsTyping(false);
    } else {
      debouncedSearch();
    }

    return () => {
      debouncedSearch.cancel();
    };
  }, [search, debouncedSearch]);

  const searchOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsTyping(true);
    setSearch(e.target.value);
  };

  const alreadyAdded = (playerId: string) => existingPlayers.includes(playerId);

  const showSuggestedPlayers = search === "";
  const orderedSuggestedPlayers = sortPlayers(suggestedPlayers);
  const sortedPlayers = sortPlayers(players);
  const filteredPlayers = registeredOnly
    ? sortedPlayers.filter(player => player.user.email)
    : sortedPlayers;
  const playersList =
    filteredPlayers.length > 0 || showSuggestedPlayers ? (
      <PlayersListWithAction
        players={showSuggestedPlayers ? orderedSuggestedPlayers : filteredPlayers}
        onAdd={onAdd}
        isAlreadyAdded={alreadyAdded}
        addedText={addedText}
      />
    ) : (
      <span className="p-6 text-sm">No players match those search terms.</span>
    );

  return (
    <div className={classNames("flex min-h-0 flex-1 flex-col pt-2.5", className)}>
      <form className="flex flex-row gap-1 px-2.5 pb-2.5" onSubmit={e => e.preventDefault()}>
        <Input
          autoFocus
          className="border-0 bg-grey-light"
          placeholder="example@email.com"
          onChange={searchOnChange}
        />
        <Button
          // disabled={search.length < 3} // TODO: lock this down better
          intent={"green"}
          size="sm"
          label="search"
          onClick={debouncedSearch}
        />
      </form>
      {orderedSuggestedPlayers.length > 0 && showSuggestedPlayers && (
        <span className="text-md border-gray-dark mx-6 border-b pb-1 pt-2 font-medium">
          Suggested
        </span>
      )}
      {isLoading || isTyping ? <LoadingSpinner /> : playersList}
    </div>
  );
}

interface AddPlayersFromSessionProps {
  sessionId: string;
  existingPlayers: string[];
  onAdd: (playerId: PlayerWithDetails) => void;
  addedText?: string;
}

export function AddPlayersFromSession({
  existingPlayers,
  sessionId,
  onAdd,
  addedText,
}: AddPlayersFromSessionProps) {
  const { data: sessionPlayers = [] } = usePlayersBySession(sessionId);
  const alreadyAdded = (playerId: string) => existingPlayers.includes(playerId);
  const players = sortPlayers(sessionPlayers);
  return (
    <PlayersListWithAction
      players={players}
      onAdd={onAdd}
      addedText={addedText ?? "added"}
      isAlreadyAdded={alreadyAdded}
    />
  );
}

const PlayersListWithAction = ({
  players,
  addedText,
  isAlreadyAdded,
  onAdd,
}: {
  players: PlayerWithDetails[];
  addedText: string;
  isAlreadyAdded: (playerId: string) => boolean;
  onAdd: (player: PlayerWithDetails) => void;
}) => (
  <div className="flex flex-col gap-3 overflow-y-auto py-3">
    {players.map(player => (
      <div key={player.id} className="flex flex-row items-center justify-between gap-2 px-6">
        <div className="flex min-w-0 flex-row items-center gap-3">
          <UserAvatar user={player.user} />
          <div className="flex flex-col truncate">
            <div className="truncate">{fullName(player.user)}</div>
            <div className="truncate">{player.user.email}</div>
          </div>
        </div>
        <Button
          size="xs"
          intent="green"
          className="h-fit"
          label={isAlreadyAdded(player.id) ? addedText : `add`}
          disabled={isAlreadyAdded(player.id)}
          onClick={async () => {
            try {
              await onAdd(player);
            } catch {}
          }}
        />
      </div>
    ))}
  </div>
);
