import type { SVGProps } from "react";
const SvgBlk = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="blk_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 50 50"
    {...props}
  >
    <defs>
      <style>{".blk_svg__cls-1{stroke-width:0}"}</style>
    </defs>
    <path
      d="m24.4 21.29 5.54-9.49c.72-1.06.98-2.18.76-3.22-.15-.69-.59-1.67-1.82-2.41-.99-.6-2.16-.77-3.28-.48s-2.06.99-2.65 1.99l-8.56 14.56c-.12.21-.18.44-.2.67-1.93.73-4.78 2.28-5.85 4.42-1.07 2.13 1.33 7.45 3.69 11.77l-1.74 4.34a1.5 1.5 0 0 0 .84 1.95c.18.07.37.11.56.11.59 0 1.16-.36 1.39-.94l2-5a1.5 1.5 0 0 0-.08-1.29c-2.31-4.15-4.15-8.68-3.97-9.6.67-1.35 3.47-2.83 5.02-3.22 3.23-.81 5.09-.93 5.53-.95.13.07.38.3.45.58.05.21-.05.46-.28.73l-6.81 3.89a1.498 1.498 0 0 0 1.09 2.76c1.02-.24 2.25-.36 2.71-.27.22.31.61 1.22.63 2.4.01.58-.07 1.67-.75 2.35a1.49 1.49 0 0 0 0 2.12c.59.59 1.54.59 2.12 0 1.09-1.09 1.66-2.69 1.63-4.53-.04-1.88-.71-3.74-1.67-4.64 0 0-.02-.02-.03-.02l2.75-1.57c.12-.07.22-.15.32-.24 1.07-1.07 1.49-2.35 1.21-3.61 1.5.42 3.47 1.56 5.51 4.42.66.92 1.26 1.95.82 3.58l-3.66 7.32-2.41.8c-.45.15-.8.5-.95.95l-1 3a1.5 1.5 0 0 0 .95 1.9c.16.05.32.08.47.08.63 0 1.21-.4 1.42-1.03l.76-2.29 2.29-.76c.38-.13.69-.4.87-.75l4-8c.04-.08.07-.16.1-.24.86-2.88-.2-4.88-1.22-6.3-3.07-4.29-6.22-5.57-8.49-5.83ZM25.52 9.2c.18-.3.46-.52.8-.6.34-.09.7-.04 1 .15.17.1.39.27.43.47.05.22-.07.57-.33.93-.02.03-.04.06-.06.1l-6.6 11.31c-.61.05-1.52.16-2.75.39l7.5-12.74ZM8.67 24.75a.76.76 0 0 0 .7-1.02l-3-8a.76.76 0 0 0-.97-.44.76.76 0 0 0-.44.97l3 8c.11.3.4.49.7.49ZM5.38 12.76c-.67-2.02-1.28-5.37-.46-6.15.31-.29.97-.12 1.47.08.38.15.82-.03.97-.42a.745.745 0 0 0-.42-.97c-1.31-.52-2.34-.45-3.05.22-1.81 1.71-.26 6.73.06 7.71.11.31.4.51.71.51a.745.745 0 0 0 .71-.99Z"
      className="blk_svg__cls-1"
    />
    <path
      d="m10.38 17.15 1.58 4.12a.753.753 0 0 0 .97.43c.39-.15.58-.58.43-.97l-1.58-4.12a.746.746 0 0 0-.97-.43c-.39.15-.58.58-.43.97M7.29 9.02l2.08 5.39a.753.753 0 0 0 .97.43c.39-.15.58-.58.43-.97L8.69 8.48a.746.746 0 0 0-.97-.43c-.39.15-.58.58-.43.97M11.89 6.45l.33-.17c2.2-1.1 4.78-1.21 7.06-.29l1.19.48c.06.02.12.04.19.04a.498.498 0 0 0 .18-.96l-1.19-.48a9.63 9.63 0 0 0-7.88.33l-.33.17c-.25.12-.35.42-.22.67.12.25.42.35.67.22Z"
      className="blk_svg__cls-1"
    />
    <path
      d="M17.76 7.01a5.07 5.07 0 0 0-4.14.17l-.17.08c-.25.12-.35.42-.22.67a.5.5 0 0 0 .67.23l.17-.08c1.04-.52 2.25-.57 3.33-.14l.6.24c.06.02.12.04.19.04a.498.498 0 0 0 .18-.96l-.6-.24ZM41.59 24.51c-.27.04-.46.3-.42.57.02.13 1.87 12.8-4.83 18.54a.494.494 0 0 0 .33.87q.18 0 .33-.12c7.13-6.11 5.25-18.91 5.17-19.46a.5.5 0 0 0-.57-.42ZM37.2 22.96c.36-.09.57-.39.5-.69-.01-.05-.09-.18-.1-.22-.09-.35-.18-.69-.26-1.09-.05-.23-.12-.52-.27-1.09-.19-.74-.36-1.44-.53-2.21-.03-.1-.05-.18-.06-.27a60 60 0 0 0 2.44 2.37c.61.57 1.14 1.06 1.67 1.77.21.29.42.38.75.29s.45-.29.37-.59c-.05-.18-.25-.98-.39-1.57-.13-.56-.31-1.31-.39-1.66l-.35-1.73c-.1-.56-.2-1.07-.26-1.33-.08-.36-.41-.52-.78-.42-.29.07-.42.31-.37.59.07.36.12.63.2.98.1.46.18.91.28 1.38.11.51.21 1.01.31 1.41l.13.5s-.02 0-.02-.02c-.38-.33-.72-.67-1.07-1l-1.1-1.04c-.31-.3-1.25-1.25-1.41-1.42-.14-.15-.3-.24-.48-.24s-.39.02-.56.07c-.32.08-.45.32-.38.62.04.17.14.38.18.59.05.32.14.65.21.95l.42 1.92c.23 1.04.54 2.55.61 2.82.08.32.33.41.68.32ZM46.96 16c-.07-.26-.15-.52-.24-.79-.45-1.3-1.55-2.1-2.73-1.79-.33.09-.6.27-.68.51-1.07.55-1.52 2.58-1.01 4.58.2.79.55 1.49.93 1.85.52.52 1.48.64 2.17.46 1.18-.31 1.75-1.74 1.77-3.17 0-.53-.09-1.17-.21-1.64Zm-2.08 3.87c-.39.1-.69-.06-1.01-.55-.21-.32-.37-.73-.5-1.25-.39-1.49-.07-3.23.75-3.44.13-.03.24-.11.29-.23.36 0 .58.08.79.28.19.21.48.84.59 1.24.14.54.22 1.01.24 1.53.04.95-.37 2.22-1.15 2.42"
      className="blk_svg__cls-1"
    />
  </svg>
);
export default SvgBlk;
