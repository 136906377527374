import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import { UpsertUserPayload, upsertUser } from "../../lib/data/user";
import { LoadingSpinner } from "../../components/Spinner";
import { ErrorPage } from "./ErrorPage";

/**
 * This page serves two functions:
 * 1. upserts the user into the db
 * 2. redirects the user to the previous page that they were at, prior to signing in.
 */
export default function SignedInPage() {
  const [searchParams] = useSearchParams();
  const redirectPath = searchParams.get("redirect"); // use this redirect to send them AFTER user is upserted
  const { isLoaded, isSignedIn, user } = useUser();

  if (!isLoaded) {
    return (
      <div className="flex min-h-screen w-full items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!isSignedIn || !user.primaryEmailAddress) {
    return <ErrorPage message="We were unable to log you in. Please try again later." />;
  }
  const userPayload: UpsertUserPayload = {
    authenticatorId: user.id,
    firstName: user.firstName ?? "User",
    lastName: user.lastName ?? "User",
    email: user.primaryEmailAddress.emailAddress,
    imageUrl: user.imageUrl,
  };

  function UpsertUserHandler({ user }: { user: UpsertUserPayload }) {
    const navigate = useNavigate();
    const query = {
      queryKey: [`users`, user.authenticatorId],
      queryFn: async () => await upsertUser(user),
    };
    const { status, data: upsertedUser } = useQuery(query);

    useEffect(() => {
      if (status === "success") {
        navigate(redirectPath ?? "/dashboard");
      }
      if (status === "error") {
        navigate("/500");
      }
    }, [status, navigate, upsertedUser]);
    return <></>;
  }

  return (
    <div className="text-md">
      <UpsertUserHandler user={userPayload} />
    </div>
  );
}
