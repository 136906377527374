import { GameStatsByPlayer, PlayerWithDetails } from "../../lib/data/models";
import { Sport } from "../../lib/data/stats";
import { SDPerformer, SDTableColumn, SDTableData, SDTableHeaderData } from "./SDTable";
import {
  basketballGameStatColumns,
  basketballSessionStatColumns,
  mapBasketballGameStats,
  mapBasketballSessionStats,
} from "./sport-configs/basketball";

export const configureSessionTableProps = (
  team: PlayerWithDetails[] = [],
  gameStats: GameStatsByPlayer,
  sport: Sport = "basketball",
): [SDTableHeaderData, SDTableData] => {
  let columns: SDTableColumn[];
  let rows: SDPerformer[];

  switch (sport) {
    case "basketball":
      columns = basketballSessionStatColumns;
      rows = team.map(player => {
        const statsForPlayer = gameStats[player.id];
        const stats = statsForPlayer ? mapBasketballSessionStats(statsForPlayer) : [];
        return {
          id: player.id,
          user: player.user,
          stats,
        };
      });
  }

  const headers: SDTableHeaderData = {
    groupId: "stats",
    columns,
  };

  const data: SDTableData = {
    rows,
  };

  return [headers, data];
};

export const configureGameTableProps = (
  team: PlayerWithDetails[] = [],
  gameStats: GameStatsByPlayer,
  sport: Sport = "basketball",
): [SDTableHeaderData, SDTableData] => {
  let columns: SDTableColumn[];
  let rows: SDPerformer[];

  switch (sport) {
    case "basketball":
      columns = basketballGameStatColumns;
      rows = team.map(player => {
        const statsForPlayer = gameStats[player.id];
        const stats = statsForPlayer ? mapBasketballGameStats(statsForPlayer) : [];
        return {
          id: player.id,
          user: player.user,
          stats,
        };
      });
  }

  const headers: SDTableHeaderData = {
    groupId: "stats",
    columns,
  };

  const data: SDTableData = {
    rows,
  };

  return [headers, data];
};
