import { useSignIn } from "@clerk/clerk-react";
import { Button } from "../../components/Button";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function GoogleSignInButton({ previousUrl }: { previousUrl: string }) {
  const { signIn, isLoaded } = useSignIn();
  if (!isLoaded) {
    return null;
  }
  const signInWithGoogle = () =>
    signIn.authenticateWithRedirect({
      strategy: "oauth_google",
      redirectUrl: "/sso-callback",
      redirectUrlComplete: `/signed-in?redirect=${encodeURI(previousUrl)}`,
    });
  return <Button intent="outline" onClick={signInWithGoogle} label="sign in" />;
}

export function GoogleSignIn() {
  const { signIn } = useSignIn();
  const navigate = useNavigate();
  const signInWithGoogle = useCallback(async () => {
    try {
      await signIn?.authenticateWithRedirect({
        strategy: "oauth_google",
        redirectUrl: "/sso-callback",
        redirectUrlComplete: `/signed-in?redirect=${encodeURI("/dashboard")}`,
      });
    } catch (e) {
      // if there's an error, direct them to home page.
      console.error(`error: ${JSON.stringify(e, null, 2)}`);
      navigate("/");
    }
  }, [signIn, navigate]);
  useEffect(() => {
    signInWithGoogle();
  }, [signInWithGoogle]);
  return <></>;
}
