import { useState } from "react";
import { PlayerWithDetails } from "../../lib/data/models";
import { AddPlayersFromSession, SearchPlayers } from "./SearchSessionPlayers";
import { GrAddCircle } from "react-icons/gr";
import { IconButton } from "../../components/Button";
import { Divider } from "../../components/Divider";
import { useGame } from "../../lib/data/game";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { PlayersListWithRemove } from "../../components/PlayersList";

type View = "list" | "search";
export function EditTeamPlayers({
  gameId,
  title,
  currentPlayers,
  addPlayer,
  removePlayer,
}: {
  gameId: string;
  title: string;
  currentPlayers: PlayerWithDetails[];
  addPlayer: (playerId: string) => void;
  removePlayer: (playerId: string) => void;
}) {
  const { data: game } = useGame(gameId);
  const [view, setView] = useState<View>("list");
  const description = () => {
    switch (view) {
      case "list":
        return <div className="text-md font-medium">Players ({currentPlayers.length})</div>;
      case "search":
        return <div className="text-md font-medium">Search Players</div>;
    }
  };

  const tipMessage = () => {
    switch (view) {
      case "list":
        return (
          <span className="px-2.5 pt-1 text-sm italic sm:px-5">
            Below is {title}'s player roster. Click the minus button to remove a player.
          </span>
        );
      case "search":
        return (
          <span className="px-2.5 pt-1 text-sm italic sm:px-5">
            Below is a list of players in this session.
          </span>
        );
    }
  };

  const body = () => {
    switch (view) {
      case "list":
        return (
          <PlayersListWithRemove
            players={currentPlayers}
            onRemove={player => removePlayer(player.id)}
          />
        );
      case "search":
        if (game?.collectionId) {
          return (
            <AddPlayersFromSession
              sessionId={game.collectionId}
              existingPlayers={currentPlayers.map(p => p.id)}
              onAdd={player => addPlayer(player.id)}
            />
          );
        } else {
          return (
            <SearchPlayers
              existingPlayers={currentPlayers.map(p => p.id)}
              onAdd={player => addPlayer(player.id)}
            />
          );
        }
    }
  };

  const icons = (
    <div role="list" className="flex flex-row items-center gap-2">
      <IconButton
        data-active={view === "list"}
        role="listitem"
        intent="iconTab"
        label="Session player list"
        onClick={() => toggle("list")}
      >
        <MdOutlinePeopleAlt
          aria-hidden="true"
          className="h-[24px] w-[24px]"
          fill={view === "list" ? "#007163" : "currentColor"}
        />
      </IconButton>
      <IconButton
        data-active={view === "search"}
        role="listitem"
        intent="iconTab"
        label="Add Session Player"
        onClick={() => toggle("search")}
      >
        <GrAddCircle
          aria-hidden="true"
          className="h-[24px] w-[24px]"
          stroke={view === "search" ? "#007163" : "currentColor"}
        />
      </IconButton>
    </div>
  );

  const toggle = (newView: View) => {
    if (view === newView) {
      setView("list");
    } else {
      setView(newView);
    }
  };

  return (
    <div className="flex min-h-0 flex-1 flex-col">
      <div className="px-2.5 pt-4 sm:px-5">
        <div className="heading-3">{title}</div>
        <Divider />
      </div>
      <div className="flex flex-1 flex-col overflow-auto">
        <div className="flex flex-row items-center justify-between px-2.5 pt-2.5 sm:px-5">
          {description()}
          {icons}
        </div>
        {tipMessage()}
        {body()}
      </div>
    </div>
  );
}
