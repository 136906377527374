import { PageContainer } from "../../components/PageContainer";
import { SessionsSection } from "../sessions/SessionsSection";
import { WelcomeHero } from "../../components/WelcomeHero";

export default function Dashboard() {
  return (
    <PageContainer>
      <WelcomeHero />
      <SessionsSection />
    </PageContainer>
  );
}
