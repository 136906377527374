import type { SVGProps } from "react";
const SvgFoul = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 50 50"
    {...props}
  >
    <defs>
      <style>{".cls-1{stroke-width:0}"}</style>
    </defs>
    <path
      d="m43.49 7.98-.07-.16a4.66 4.66 0 0 0-4.28-2.83h-8.63c-.99 0-1.97.32-2.76.9-11.5 8.46-17.41 16.56-17.58 24.07-.14 6.44 4.09 10.56 5.91 12.02.82.66 1.42 1.02 2.91 1.02h4.37c.52 1.45 1.89 2.5 3.52 2.5s3-1.05 3.52-2.5h2.54c.91 0 1.8-.27 2.58-.78 5.76-3.73 6.85-10.75 3.06-19.75a2.13 2.13 0 0 1-.05-1.55l4.95-9.29s.04-.09.06-.14c.44-1.14.42-2.39-.07-3.51Zm-14.27-.06c.37-.27.82-.42 1.28-.42h7.13c-2.81 2.19-5.24 4.36-7.29 6.5h-8.47c2.1-1.99 4.54-4.02 7.35-6.08Zm-8.87 7.58h8.61c-.74.84-1.41 1.67-2.03 2.5h-8.85c.69-.83 1.45-1.66 2.27-2.5Zm-1.36 25c-.5 0-.96-.16-1.34-.46-1.91-1.54-5.09-4.88-4.98-10.02.07-3.33 1.49-6.84 4.23-10.51h8.97c-2.38 3.59-3.62 7.08-3.7 10.46-.11 4.92 2.33 8.48 4.3 10.54h-7.48Zm7.89 3.5c-.78 0-1.46-.4-1.87-1h3.74c-.4.6-1.09 1-1.87 1Zm14.36-33.47-4.95 9.29s-.04.09-.06.14c-.43 1.13-.41 2.37.06 3.48 2.31 5.49 3.82 12.84-2.12 16.69-.38.25-.8.38-1.23.38h-1.96c-.5 0-.96-.16-1.34-.46-1.91-1.54-5.09-4.88-4.98-10.02.14-6.6 5.57-13.92 16.1-21.76.14.16.26.35.35.56l.07.16c.21.5.23 1.05.05 1.56Z"
      className="cls-1"
    />
    <path
      d="M31.82 24.53c-2.84 0-5.16 3.02-5.16 6.72s2.31 6.72 5.16 6.72 5.16-3.02 5.16-6.72-2.31-6.72-5.16-6.72Zm0 11c-1.58 0-2.87-1.92-2.87-4.28s1.29-4.28 2.87-4.28 2.87 1.92 2.87 4.28-1.29 4.28-2.87 4.28ZM13.13 15.25c0-.41-.34-.75-.75-.75h-5c-.41 0-.75.34-.75.75s.34.75.75.75h5c.41 0 .75-.34.75-.75ZM12.12 11.55c.15.15.34.22.53.22s.38-.07.53-.22c.29-.29.29-.77 0-1.06L9.64 6.95c-.29-.29-.77-.29-1.06 0s-.29.77 0 1.06l3.54 3.54ZM16.38 11c.41 0 .75-.34.75-.75v-5c0-.41-.34-.75-.75-.75s-.75.34-.75.75v5c0 .41.34.75.75.75Z"
      className="cls-1"
    />
  </svg>
);
export default SvgFoul;
