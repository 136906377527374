import { ComponentProps } from "react";
import { UserAvatar } from "./UserAvatar";
import { Link } from "react-router-dom";

export function PlayerAvatar({
  playerId,
  isLink = true,
  ...props
}: ComponentProps<typeof UserAvatar> & {
  playerId: string;
  isLink?: boolean;
}) {
  return isLink ? (
    <Link to={`/p/${playerId}`}>
      <UserAvatar {...props} />
    </Link>
  ) : (
    <UserAvatar {...props} />
  );
}
