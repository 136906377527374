import type { SVGProps } from "react";
const SvgBasketballDunkYellow = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 75 75" {...props}>
    <path
      fill="#FFB700"
      d="M23.358 55.149C6.895 39.516 7.911 18.93 22.226 11.24 36.54 3.55 57.786.5 62.36 10.547S49.433 21.85 54.18 37.64c4.746 15.79 16.956 15.404 14.476 26.614s-28.835 6.528-45.298-9.105"
    />
    <path
      stroke="#212121"
      strokeMiterlimit={10}
      strokeWidth={1.654}
      d="M39.952 23.785a3.943 3.943 0 1 0 0-7.886 3.943 3.943 0 0 0 0 7.886Z"
    />
    <path
      stroke="#212121"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.654}
      d="m44.999 24.258 5.835-.789 2.523-6.308 1.577-1.262-2.523 9.147-6.624 3.47.474 8.673.63 4.731-2.996 11.828 13.878 8.516.315 2.523-20.343-8.358.473-16.243.157-2.366"
    />
    <path
      stroke="#212121"
      strokeLinejoin="round"
      strokeWidth={1.595}
      d="M52.253 17.161a2.996 2.996 0 1 0 0-5.993 2.996 2.996 0 0 0 0 5.993Z"
    />
    <path
      stroke="#212121"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.654}
      d="m39.952 30.881-4.73 8.2-11.355 4.416.63-2.523 7.886-4.1 2.838-7.885 2.524-2.839"
    />
    <path
      stroke="#212121"
      strokeLinejoin="round"
      strokeWidth={1.654}
      d="m46.26 44.444 3.785 5.677 12.932 2.208 1.577 2.838-16.401-1.261-3.47-3.154"
    />
    <path
      stroke="#212121"
      strokeLinejoin="round"
      strokeWidth={1.602}
      d="M19.924 14.796c3.745 0 6.782-.918 6.782-2.05 0-1.133-3.037-2.05-6.782-2.05s-6.78.917-6.78 2.05 3.035 2.05 6.78 2.05Z"
    />
    <path
      stroke="#212121"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.602}
      d="m15.982 21.735-2.839-8.99M23.551 21.735l3.154-8.99"
    />
  </svg>
);
export default SvgBasketballDunkYellow;
